import { useDimensions } from '@infominds/react-native-components'
import React from 'react'

import useKeyPress from '../../hooks/useKeyPress'
import HeaderIcon from './HeaderIcon'
import useScreen from './hooks/useScreen'
import { DetailHeaderType } from './types'

export type SearchIconProps = {
  disabled?: boolean
  highlight?: boolean
}

export default function HeaderFilterIcon({ disabled, highlight }: SearchIconProps) {
  const { isSmallDevice } = useDimensions()
  const { detail, setDetailOpen } = useScreen()

  useKeyPress(
    {
      key: ['F2'],
      cb: () => {
        isSmallDevice && setDetailOpen(DetailHeaderType.SEARCH)
      },
    },
    [isSmallDevice, setDetailOpen]
  )

  return (
    <HeaderIcon
      size={17}
      icon={['fal', 'sliders']}
      type={DetailHeaderType.FILTER}
      highlight={detail.type === DetailHeaderType.FILTER && detail.open ? false : highlight}
      disabled={disabled}
    />
  )
}
