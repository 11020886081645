import { useLanguage } from '@infominds/react-native-components'
import React from 'react'
import { useRecoilValue } from 'recoil'

import OnlineView from '../../components/offline/OnlineView'
import { SearchProvider } from '../../components/screen/contexts/SearchContext'
import FilterDetailHeaderContainer from '../../components/screen/headers/containers/FilterDetailHeaderContainer'
import SearchDetailHeaderContainer from '../../components/screen/headers/containers/SearchDetailHeaderContainer'
import FilterDetailHeader from '../../components/screen/headers/FilterDetailHeader'
import HeaderWithIconsSync from '../../components/screen/headers/HeaderWithIconsSync'
import { Screen } from '../../components/screen/Screen'
import FilterContext from '../../contexts/FilterContext'
import { HistoryStackScreenProps } from '../../navigation/navigators/bottomTabs/HistoryBottomTabNavigator'
import { activityHistoryFilterEnableAtom } from '../../utils/stateManager'
import HistoryView from '../../views/history/HistoryView'

export default function HistoryScreen({ navigation }: HistoryStackScreenProps<'History'>) {
  const { i18n } = useLanguage()
  const enabled = useRecoilValue(activityHistoryFilterEnableAtom)

  const handleSDNavigation = () => {
    navigation.navigate('HistoryModalCommonStack', { screen: 'Filter' })
  }

  return (
    <SearchProvider>
      <FilterContext.Consumer>
        {context => {
          let activeFilters = false
          context?.filters.forEach(filter =>
            filter.elements.forEach(el => {
              if (el.active) activeFilters = true
            })
          )
          const activeGroups = context?.groups.filter(el => el.active)
          const activeOrder = context?.orders.filter(el => el.active)

          return (
            <Screen>
              <Screen.Header>
                <HeaderWithIconsSync
                  title={i18n.t('TAB_HISTORY')}
                  tabletSearchComponent={<SearchDetailHeaderContainer enableQR iconPosition="right" />}
                  tabletFilterComponent={
                    <FilterDetailHeaderContainer dividers={['right']}>
                      <FilterDetailHeader enabled={enabled} onSDNavigation={handleSDNavigation} />
                    </FilterDetailHeaderContainer>
                  }
                  highlightFilter={activeFilters || activeGroups?.length !== 0 || activeOrder?.length !== 0}
                />
              </Screen.Header>
              <Screen.DetailHeader>
                <SearchDetailHeaderContainer enableQR />
                <FilterDetailHeaderContainer>
                  <FilterDetailHeader enabled={enabled} onSDNavigation={handleSDNavigation} />
                </FilterDetailHeaderContainer>
              </Screen.DetailHeader>
              <Screen.Content>
                <OnlineView showOfflineIndicator>
                  <HistoryView />
                </OnlineView>
              </Screen.Content>
            </Screen>
          )
        }}
      </FilterContext.Consumer>
    </SearchProvider>
  )
}
