import { CompositeScreenProps } from '@react-navigation/native'
import { createNativeStackNavigator, NativeStackScreenProps } from '@react-navigation/native-stack'
import React from 'react'

import SettingsScreen from '../../../screens/settings/SettingsScreen'
import { screenOptions } from '../../Navigator'
import { AppBottomTabScreenProps, BottomTabParamList } from '../../types'

export type SettingsStackParamList = {
  Settings: undefined
}

export const SettingsStackNames: {
  path?: string
  screens: Record<keyof SettingsStackParamList, string>
} = {
  screens: {
    Settings: 'settings',
  },
}

export type SettingsStackScreenProps<T extends keyof SettingsStackParamList> = CompositeScreenProps<
  NativeStackScreenProps<SettingsStackParamList, T>,
  AppBottomTabScreenProps<keyof BottomTabParamList>
>

const SettingsStack = createNativeStackNavigator<SettingsStackParamList>()
export function SettingsStackNavigator() {
  return (
    <SettingsStack.Navigator screenOptions={screenOptions}>
      <SettingsStack.Screen name="Settings" component={SettingsScreen} />
    </SettingsStack.Navigator>
  )
}
