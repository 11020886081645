import { IM, IMLayout, IMStyle, SpacingProps, useDimensions, useLanguage } from '@infominds/react-native-components'
import React, { memo, useEffect, useRef, useState } from 'react'
import { Pressable, StyleProp, StyleSheet, ViewStyle } from 'react-native'

import type { Activity } from '../../apis/types/apiResponseTypes'
import CardLeftTitle from '../../components/card/left/CardLeftTitle'
import Checkbox from '../../components/checkbox/Checkbox'
import DynamicView from '../../components/Infominds/DynamicView'
import Text from '../../components/Text'
import DetailInfo from '../common/DetailInfo'
import ActivityListCardTitle from './components/ActivityListCardTitle'

export type ActivityListCardProps = {
  type?: 'planning' | 'other'
  activity: Activity
  selectable?: boolean
  spacing?: SpacingProps
  borderless?: boolean
  forceLayout?: false | 'small' | 'medium' | 'large'
  style?: StyleProp<ViewStyle>
  onPress?: () => void
  onSelected?: (id: string) => void
}

const ActivityListCard = memo(function ActivityListCard({
  type = 'other',
  activity,
  selectable,
  spacing,
  onPress,
  onSelected,
  borderless,
  forceLayout,
  style,
}: ActivityListCardProps) {
  const { i18n } = useLanguage()
  const lastElem = useRef(activity)
  const { isSmallDevice } = useDimensions()

  const [checked, setChecked] = useState(false)

  useEffect(() => {
    !selectable && setChecked(false)
  }, [selectable])

  useEffect(() => {
    if (activity.id !== lastElem.current.id) {
      lastElem.current = activity
      resetState()
    }
  }, [activity])

  const resetState = () => {
    setChecked(false)
  }

  return (
    <Pressable
      disabled={!selectable}
      onPress={() => {
        onSelected?.(activity.id)
        setChecked(q => !q)
      }}>
      <IM.Card
        head={<CardLeftTitle color={type === 'planning' ? activity.priorityColorHex : undefined} />}
        onPress={selectable ? undefined : onPress}
        borderless={borderless}
        spacing={spacing}
        style={[{ borderRadius: IMLayout.borderRadius }, style]}>
        <DynamicView flexLimitSmallDevice={1} separatorVertical separatorHorizontal forceLayout={forceLayout}>
          <ActivityListCardTitle
            type={type}
            selectable={selectable}
            activity={activity}
            style={IMLayout.flex.f1}
            forceLayout={forceLayout}
            checked={checked}
          />
          <DetailInfo object={activity} style={IMLayout.flex.f2} />
          <IM.View style={IMLayout.flex.f2}>
            <IM.View style={[IMLayout.flex.row, IMLayout.alignItems.start]}>
              <IM.View style={IMLayout.flex.f1}>
                <Text numberOfLines={isSmallDevice ? 3 : 4}>{activity.ticketDescription}</Text>
                {!!activity.technicalDescription && (
                  <IM.View style={styles.customerDescription}>
                    <Text style={{ fontWeight: IMStyle.typography.fontWeightBold }}>{i18n.t('TECHNICAL_DESCRIPTION')}</Text>
                    <IM.Text numberOfLines={3}>{activity.technicalDescription}</IM.Text>
                  </IM.View>
                )}
                {!!activity.customerDescription && (
                  <IM.View style={styles.customerDescription}>
                    <Text style={{ fontWeight: IMStyle.typography.fontWeightBold }}>{i18n.t('CUSTOMER_DESCRIPTION')}</Text>
                    <IM.Text numberOfLines={3}>{activity.customerDescription}</IM.Text>
                  </IM.View>
                )}
              </IM.View>
              {selectable && !isSmallDevice && <Checkbox width={20} height={20} checked={checked} />}
            </IM.View>
          </IM.View>
        </DynamicView>
      </IM.Card>
    </Pressable>
  )
})

export default ActivityListCard

const styles = StyleSheet.create({
  customerDescription: {
    paddingTop: 6,
  },
})
