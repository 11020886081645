import { useLanguage, useTheme } from '@infominds/react-native-components'
import React, { ForwardedRef, forwardRef, useEffect, useImperativeHandle, useState } from 'react'

import { getApi } from '../../apis/apiCalls'
import FormButton from '../../components/FormButton'
import useControlledLoader from '../../components/Infominds/hooks/useControlledLoader'
import BooleanInput from '../../components/input/BooleanInput'
import NumberInput from '../../components/input/NumberInput'
import TextInput, { TextInputProps } from '../../components/input/TextInput'
import ScrollViewForm from '../../components/ScrollViewForm'
import ArticleSelector from '../../components/selectors/ArticleSelector'
import { REQUEST_SERIAL_NUMBER_NUMBER } from '../../constants/Keys'
import { useDataProvider } from '../../dataProvider/hooks/useDataProvider'
import useCreateSerialNumber from '../../hooks/useCreateSerialNumber'
import { EditOrCreateViewProps, EditOrCreateViewRef, ThemeColorExpanded, UploadStatus } from '../../types'

type Props = EditOrCreateViewProps & {
  uploadStatus: UploadStatus
  customerId: string
  ticketId: string
  customerShippingAddressId?: string
  onDone: (times: number) => void
}

export type TicketSerialNumberCreateState = {
  number: string | undefined
  articleId: string | undefined
  numberManufactor: string | undefined
  note: string | undefined
  quantity: number | undefined
}

const TicketSerialNumberCreateView = (
  { uploadStatus, customerId, ticketId, customerShippingAddressId, disabled, onDone, onUploadStatus }: Props,
  ref: ForwardedRef<EditOrCreateViewRef>
) => {
  useImperativeHandle(ref, () => ({
    handleUpload: upload,
  }))

  const { i18n } = useLanguage()
  const { client } = useDataProvider()
  const { theme } = useTheme<ThemeColorExpanded>()

  const [multiple, setMultiple] = useState(false)
  const [state, setState] = useState<TicketSerialNumberCreateState>({
    articleId: undefined,
    number: undefined,
    numberManufactor: undefined,
    note: undefined,
    quantity: undefined,
  })

  const { upload } = useCreateSerialNumber({
    multiple,
    customerId,
    ticketId,
    state,
    customerShippingAddressId,
    uploadStatus,
    onDone: onDone,
    onUploadStatusChange: onUploadStatus,
  })
  const { loadItem: getSNNumber, loading } = useControlledLoader(getApi(client).getSerialNumberNumber, {
    id: REQUEST_SERIAL_NUMBER_NUMBER,
    onResult: sn => setState(prev => ({ ...prev, number: sn })),
  })

  useEffect(() => {
    let status: UploadStatus = 'done'

    if (multiple) {
      if (state.articleId === undefined) return onUploadStatus(status)

      if (state.articleId !== undefined) {
        status = 'waiting'
      } else {
        status = 'mandatoryMissing'
      }
    } else {
      if (state.articleId === undefined && state.number === undefined) return onUploadStatus(status)

      if (state.articleId !== undefined && state.number !== undefined) {
        status = 'waiting'
      } else {
        status = 'mandatoryMissing'
      }
    }

    onUploadStatus(status)
  }, [state])

  const handleChangeText = (newVal: Partial<TicketSerialNumberCreateState>) => {
    if (uploadStatus === 'uploading') return

    setState(prev => {
      return {
        ...prev,
        ...newVal,
      }
    })
  }

  const handleSNGeneration = () => state?.articleId && getSNNumber({ articleId: state?.articleId })

  const commonProps: Pick<TextInputProps, 'spacing' | 'editable'> = {
    spacing: 'vertical',
    editable: !disabled,
  }

  return (
    <ScrollViewForm>
      <ArticleSelector
        isMandatory
        onlySerialnumberArticle
        devicesOnly
        disableBackdropOpacity
        onChange={val => handleChangeText({ articleId: val === undefined ? undefined : val.id })}
        {...commonProps}
      />
      <BooleanInput
        title={i18n.t('CREATE_MULTIPLE_SN')}
        value={multiple}
        onValueChange={value => {
          if (value) {
            setState(prev => ({ ...prev, note: undefined, number: undefined, numberManufactor: undefined, quantity: 1 }))
          } else {
            setState(prev => ({ ...prev, undefined, note: undefined, number: undefined, numberManufactor: undefined, quantity: undefined }))
          }

          setMultiple(value)
        }}
        {...commonProps}
      />
      {multiple ? (
        <NumberInput
          title={i18n.t('QUANTITY') + ' *'}
          value={state?.quantity?.toString()}
          min={1}
          onChangeText={val => handleChangeText({ quantity: val === '' ? undefined : parseInt(val, 10) })}
          {...commonProps}
        />
      ) : (
        <>
          <TextInput
            title={i18n.t('SERIAL_NUMBER') + ' *'}
            value={state?.number}
            onChangeText={val => handleChangeText({ number: val === '' ? undefined : val })}
            {...commonProps}
          />
          <FormButton
            title={i18n.t('GENERATE_SERIAL_NUMBER')}
            onPress={handleSNGeneration}
            color={theme.general.info}
            spacing="vertical"
            disabled={loading === 'reloading' || disabled || state?.articleId === undefined}
          />
          <TextInput
            title={i18n.t('SERIAL_NUMBER_MANUFACTURER')}
            value={state?.numberManufactor}
            onChangeText={val => handleChangeText({ numberManufactor: val === '' ? undefined : val })}
            {...commonProps}
          />
          <TextInput
            title={i18n.t('NOTE')}
            value={state?.note}
            multiline
            fixMultilineHeight
            onChangeText={text => handleChangeText({ note: text === '' ? undefined : text })}
            {...commonProps}
          />
        </>
      )}
    </ScrollViewForm>
  )
}

export default forwardRef(TicketSerialNumberCreateView)
