import { IM, IMLayout, useLanguage } from '@infominds/react-native-components'
import React, { ForwardedRef, forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import { Platform, SectionListRenderItemInfo, StyleSheet } from 'react-native'

import api from '../../apis/apiCalls'
import { Report } from '../../apis/types/apiResponseTypes'
import useControlledLoader from '../../components/Infominds/hooks/useControlledLoader'
import useRequest from '../../components/Infominds/hooks/useRequest'
import Pressable from '../../components/Infominds/Pressable'
import RadioButton from '../../components/RadioButton'
import SectionList from '../../components/SectionList'
import SkeletonText from '../../components/skeleton/SkeletonText'
import Text from '../../components/Text'
import { REQUEST_POST_REPORT, REQUEST_REPORTS } from '../../constants/Keys'
import { ListSection, TicketReportViewRef, UploadStatus } from '../../types'

interface Props {
  ticketId: string
  status: UploadStatus
  onUploadStatus: (status: UploadStatus) => void
  onDone: VoidFunction
}

function TicketReportView({ ticketId, status, onUploadStatus, onDone }: Props, ref: ForwardedRef<TicketReportViewRef>) {
  useImperativeHandle(ref, () => ({
    sendReport: send,
  }))

  const { i18n } = useLanguage()

  const { loadItem: loadReports, item: reports, loading } = useControlledLoader(api.getReports, { id: REQUEST_REPORTS })
  const { request, loading: loadingSend } = useRequest(api.sendTicketReport, { id: REQUEST_POST_REPORT })

  const [selectedId, setSelectedId] = useState<undefined | string>()

  useEffect(() => {
    refresh()
  }, [])

  useEffect(() => {
    if (selectedId === undefined) return

    onUploadStatus('waiting')
  }, [selectedId])

  useEffect(() => {
    if (status !== 'uploading') return

    if (loadingSend === 'catched') return onUploadStatus('waiting')

    if (loadingSend === false) {
      onDone()
    }
  }, [status, loadingSend])

  const refresh = () => loadReports({})

  const send = () => {
    if (!selectedId) return

    onUploadStatus('uploading')
    request({ reportId: selectedId, ticketId })
  }

  const renderItem = ({ item, index }: SectionListRenderItemInfo<Report, ListSection<Report>>) => (
    // eslint-disable-next-line react-native/no-inline-styles
    <Pressable onPress={() => setSelectedId(item.id)} style={{ marginTop: index === 0 ? IMLayout.verticalMargin / 2 : 0 }}>
      <IM.View style={[IMLayout.flex.row, styles.pressable, { paddingVertical: IMLayout.verticalMargin / 2 }]}>
        <RadioButton selected={item.id === selectedId} />
        <Text>{item.description}</Text>
      </IM.View>
    </Pressable>
  )

  return (
    <SectionList
      loading={loading}
      skeletonElements={15}
      noDataMessage={i18n.t('NO_REPORTS_FOUND')}
      sections={[{ data: reports ?? [] }]}
      renderItem={renderItem}
      onRefresh={refresh}
      skeletonComponent={<SkeletonText width="100%" spacing="top" />}
      contentContainerStyle={styles.listContainer}
    />
  )
}

export default forwardRef(TicketReportView)

const styles = StyleSheet.create({
  listContainer: {
    marginHorizontal: IMLayout.horizontalMargin,
    marginVertical: IMLayout.horizontalMargin,
    ...Platform.select({
      native: {
        height: '100%',
      },
    }),
  },
  pressable: {
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
})
