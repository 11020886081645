import { IM, IMStyle, ModalController, useTheme } from '@infominds/react-native-components'
import React, { PropsWithChildren, ReactNode } from 'react'
import { Modal, ModalProps, Platform, StyleProp, StyleSheet, TextStyle, useWindowDimensions } from 'react-native'

import { ThemeColorExpanded } from '../types'
import appUtils from '../utils/appUtils'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Props<T = any> = Pick<ModalProps, 'onRequestClose'> & {
  controller: ModalController<T>
  width?: number
  title: ReactNode
  titleStyle?: StyleProp<TextStyle>
  titleViewStyle?: StyleProp<TextStyle>
  description?: string
}

export default function AvoidingViewModal({
  controller,
  children,
  width = 450,
  title,
  titleStyle,
  titleViewStyle,
  description,
  onRequestClose,
}: PropsWithChildren<Props>) {
  const { height, width: wdWidth } = useWindowDimensions()
  const { theme, colorScheme } = useTheme<ThemeColorExpanded>()

  return (
    <Modal
      statusBarTranslucent
      visible={controller.isShown}
      onRequestClose={ev => (onRequestClose ? onRequestClose(ev) : controller.close())}
      hardwareAccelerated={Platform.OS !== 'web'}
      transparent>
      <IM.View style={[styles.centeredView, { backgroundColor: `#000000${appUtils.getModalOpacity(colorScheme) * 100 * 1.2}` }]}>
        <IM.View style={[styles.scrollView, styles.centeredView, { height, width: Math.min(wdWidth, width) }]}>
          <IM.View style={[styles.modalView, { backgroundColor: appUtils.getModalBackground(colorScheme, theme), borderColor: theme.error }]}>
            <IM.View spacing="bottom" style={titleViewStyle}>
              {typeof title === 'string' ? <IM.Text style={[styles.title, titleStyle]}>{title}</IM.Text> : <>{title}</>}
            </IM.View>
            {description && (
              <IM.View>
                <IM.Text>{description}</IM.Text>
              </IM.View>
            )}
            {children}
          </IM.View>
        </IM.View>
      </IM.View>
    </Modal>
  )
}

const styles = StyleSheet.create({
  scrollView: { alignItems: 'center', maxWidth: '90%' },
  centeredView: {
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  modalView: {
    borderRadius: 20,
    paddingLeft: 30,
    paddingRight: 30,
    paddingTop: 22,
    paddingBottom: 12,
    width: '100%',
    maxHeight: '90%',
  },
  title: {
    fontSize: IMStyle.typography.fontSizeMedium + 2,
    fontWeight: IMStyle.typography.fontWeightMedium,
  },
})
