import { CompositeScreenProps, NavigatorScreenParams } from '@react-navigation/native'
import { createNativeStackNavigator, NativeStackScreenProps } from '@react-navigation/native-stack'
import React from 'react'
import { useSetRecoilState } from 'recoil'

import { SparePartsFilterProvider } from '../../../contexts/SparePartsFilterContext'
import useIsOnline from '../../../dataProvider/hooks/useIsOnline'
import ActivityAllocationScreen from '../../../screens/activity/ActivityAllocationScreen'
import ActivityArticleCounterScreen from '../../../screens/activity/ActivityArticleCounterScreen'
import ActivityCloseScreen from '../../../screens/activity/ActivityCloseScreen'
import ActivityPlanningTimesEditOrCreateScreen from '../../../screens/activity/ActivityPlanningTimesEditOrCreateScreen'
import ActivityPurchaseOrderScreen from '../../../screens/activity/ActivityPurchaseOrderScreen'
import ActivityScreen from '../../../screens/activity/ActivityScreen'
import ActivitySendReportScreen from '../../../screens/activity/ActivitySendReportScreen'
import ActivitySparePartsScreen from '../../../screens/activity/ActivitySparePartsScreen'
import ActivitySparePartsShoppingCartScreen from '../../../screens/activity/ActivitySparePartsShoppingCartScreen'
import ActivityTimesEditOrCreateScreen from '../../../screens/activity/ActivityTimesEditOrCreateScreen'
import ActivityUnitUpScreen from '../../../screens/activity/ActivityUnitUpScreen'
import {
  ActivityAllocationParams,
  ActivityArticleCounterParams,
  ActivityEditOrCreatePlanningTimesParams,
  ActivityEditOrCreateTimesParams,
  ActivitySendReportParams,
  ActivityUnitUpParams,
  SparePartDirection,
} from '../../../types'
import { sparePartsFilterEnableAtom } from '../../../utils/stateManager'
import { screenOptions } from '../../Navigator'
import { HistoryStackScreenProps } from '../bottomTabs/HistoryBottomTabNavigator'
import { PlanningStackScreenProps } from '../bottomTabs/PlanningBottomTabNavigator'
import { InfoboxCommonStackNames, InfoboxCommonStackNavigator, InfoboxCommonStackParamList } from './InfoboxCommonNavigators'
import { ModalCommonStackNames, ModalCommonStackNavigator, ModalCommonStackParamList } from './ModalCommonNavigator'
import { TicketDetailCommonStackScreenProps } from './TicketDetailCommonNavigator'

export type ActivityCommonStackParamList = {
  Activity: {
    activityId: string
    ticketId: string
    documentId: string
    activityCode: string
    start: boolean
    ticketCode: string
    // To show go to connected ticket button
    link: boolean
  }
  ActivityPast: ActivityCommonStackParamList['Activity']
  ActivityHistory: ActivityCommonStackParamList['Activity']
  ActivityPlanning: ActivityCommonStackParamList['Activity']
  ActivityEditOrCreateTimes: ActivityEditOrCreateTimesParams
  ActivityEditOrCreatePlanningTimes: ActivityEditOrCreatePlanningTimesParams
  ActivityClose: { shippingAddressId?: string; customerId: string; activityId: string; email?: string; contact?: string; addressId: string }
  ActivityUnitUp: ActivityUnitUpParams
  ActivityAllocation: ActivityAllocationParams
  ActivitySendReport: ActivitySendReportParams
  ActivityInfoboxCommonStack: NavigatorScreenParams<InfoboxCommonStackParamList>
  ActivitySpareParts: { direction: SparePartDirection; ticketId: string; activityId: string; activityCode: string }
  ActivityModalCommonStack: NavigatorScreenParams<ModalCommonStackParamList>
  ActivitySparePartsShoppingCart: {
    activityId: string
    activityCode: string
    ticketId: string
    articleCode?: string
    serialNumber?: string
    direction: SparePartDirection
  }
  ActivitySparePartsRemovalShoppingCart: { activityId: string; activityCode: string; ticketId: string; articleCode?: string; serialNumber?: string }
  ActivityPurchaseOrder: { ticketId: string }
  ActivityArticleCounter: ActivityArticleCounterParams
}

export const ActivityCommonStackNames: {
  path: string
  screens: Record<keyof ActivityCommonStackParamList, string | typeof ModalCommonStackNames | typeof InfoboxCommonStackNames>
} = {
  path: 'activity',
  screens: {
    Activity: 'detail',
    ActivityPast: 'past-activity',
    ActivityHistory: 'history-activity',
    ActivityPlanning: 'planning-activity',
    ActivityEditOrCreateTimes: 'edit-or-create-times',
    ActivityEditOrCreatePlanningTimes: 'edit-or-create-planning-times',
    ActivityClose: 'close',
    ActivityUnitUp: 'unit-up',
    ActivityAllocation: 'allocation',
    ActivitySendReport: 'send-report',
    ActivityInfoboxCommonStack: InfoboxCommonStackNames,
    ActivitySpareParts: 'parts',
    ActivityModalCommonStack: ModalCommonStackNames,
    ActivitySparePartsShoppingCart: 'cart',
    ActivitySparePartsRemovalShoppingCart: 'removal-cart',
    ActivityPurchaseOrder: 'order',
    ActivityArticleCounter: 'article-counter',
  },
}

export type ActivityStackScreenProps<T extends keyof ActivityCommonStackParamList> = CompositeScreenProps<
  NativeStackScreenProps<ActivityCommonStackParamList, T>,
  | TicketDetailCommonStackScreenProps<'TicketActivityCommonStack'>
  | HistoryStackScreenProps<'HistoryActivityCommonStack'>
  | PlanningStackScreenProps<'PlanningActivityCommonStack'>
>

const ActivityCommonStack = createNativeStackNavigator<ActivityCommonStackParamList>()
export function ActivityCommonStackNavigator() {
  const enableFilter = useSetRecoilState(sparePartsFilterEnableAtom)
  const isOnline = useIsOnline()

  return (
    <SparePartsFilterProvider
      storageKeyUniqueId="ActivityCommonStack"
      onEnableFilter={enableFilter}
      disableCompatibilityFilter={!isOnline}
      disableDepositFilter={!isOnline}>
      <ActivityCommonStack.Navigator screenOptions={screenOptions}>
        <ActivityCommonStack.Screen name="Activity" component={ActivityScreen} />
        <ActivityCommonStack.Screen name="ActivityPast" component={ActivityScreen} />
        <ActivityCommonStack.Screen name="ActivityHistory" component={ActivityScreen} />
        <ActivityCommonStack.Screen name="ActivityPlanning" component={ActivityScreen} />
        <ActivityCommonStack.Screen name="ActivityUnitUp" component={ActivityUnitUpScreen} />
        <ActivityCommonStack.Screen name="ActivityAllocation" component={ActivityAllocationScreen} />
        <ActivityCommonStack.Screen name="ActivityEditOrCreateTimes" component={ActivityTimesEditOrCreateScreen} />
        <ActivityCommonStack.Screen name="ActivityEditOrCreatePlanningTimes" component={ActivityPlanningTimesEditOrCreateScreen} />
        <ActivityCommonStack.Screen name="ActivityClose" component={ActivityCloseScreen} />
        <ActivityCommonStack.Screen name="ActivitySendReport" component={ActivitySendReportScreen} />
        <ActivityCommonStack.Screen name="ActivityModalCommonStack" component={ModalCommonStackNavigator} />
        <ActivityCommonStack.Screen name="ActivitySpareParts" component={ActivitySparePartsScreen} />
        <ActivityCommonStack.Screen name="ActivitySparePartsShoppingCart" component={ActivitySparePartsShoppingCartScreen} />
        <ActivityCommonStack.Screen name="ActivityPurchaseOrder" component={ActivityPurchaseOrderScreen} />
        <ActivityCommonStack.Screen name="ActivityArticleCounter" component={ActivityArticleCounterScreen} />
        <ActivityCommonStack.Screen name="ActivityInfoboxCommonStack" component={InfoboxCommonStackNavigator} />
      </ActivityCommonStack.Navigator>
    </SparePartsFilterProvider>
  )
}
