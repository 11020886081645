import { useLanguage } from '@infominds/react-native-components'
import React, { createRef, ForwardedRef, forwardRef, useImperativeHandle } from 'react'
import { Keyboard } from 'react-native'

import { Screen } from '../../components/screen/Screen'
import { FormProvider } from '../../contexts/FormContext'
import useEditOrCreateScreenBackManager from '../../hooks/useEditOrCreateScreenBackManager'
import { ActivityStackScreenProps } from '../../navigation/navigators/common/ActivityCommonNavigator'
import { ActivityUnitUpParams, EditOrCreateViewRef, ModalScreenProps, ModalScreenRef } from '../../types'
import ActivityUnitUpView from '../../views/activity/ActivityUnitUpView'

const ActivityUnitUpScreen = (
  { route, controller }: Pick<ActivityStackScreenProps<'ActivityUnitUp'>, 'route'> & ModalScreenProps<ActivityUnitUpParams>,
  ref: ForwardedRef<ModalScreenRef>
) => {
  useImperativeHandle(ref, () => ({
    handleGoBack: handleGoBack,
  }))

  const editOrCreateViewRef = createRef<EditOrCreateViewRef>()

  const { i18n } = useLanguage()
  const { status, setStatus, handleGoBack } = useEditOrCreateScreenBackManager({
    title: i18n.t('UNSAVED_CHANGES_TITLE'),
    description: i18n.t('DISCARD_UNSAVED_CHANGES'),
    controller,
  })

  const common = {
    ref: editOrCreateViewRef,
    onUploadStatus: setStatus,
  }

  const isModal = controller !== undefined

  return (
    <Screen transparent={isModal}>
      <Screen.Header
        goBack={handleGoBack}
        backHandlerCallback={handleGoBack}
        isModal={isModal}
        onRightIconPress={() => {
          Keyboard.dismiss()
          editOrCreateViewRef.current?.handleUpload()
        }}
        rightIcon={['fal', 'check']}
        disable={status === 'done' || status === 'mandatoryMissing'}
        loading={status === 'uploading'}
        title={i18n.t('UNIT_UP')}
      />
      <Screen.Content>
        <FormProvider>
          <ActivityUnitUpView activityId={route.params.activityId} initialValue={route.params.activityUnitUp} {...common} />
        </FormProvider>
      </Screen.Content>
    </Screen>
  )
}

export default forwardRef(ActivityUnitUpScreen)
