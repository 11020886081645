import { IM, useDimensions, useLanguage, useTheme } from '@infominds/react-native-components'
import React, { useEffect } from 'react'
import { StyleSheet } from 'react-native'
import { useRecoilState, useRecoilValue } from 'recoil'

import PressableIcon from '../../components/Infominds/PressableIcon'
import { MasterDetailProvider } from '../../components/MasterDetail/contexts/MasterDetailContext'
import MasterDetail from '../../components/MasterDetail/MasterDetails'
import { SearchProvider } from '../../components/screen/contexts/SearchContext'
import FilterDetailHeaderContainer from '../../components/screen/headers/containers/FilterDetailHeaderContainer'
import SearchDetailHeaderContainer from '../../components/screen/headers/containers/SearchDetailHeaderContainer'
import HeaderWithIcons from '../../components/screen/headers/HeaderWithIcons'
import SparePartsFilterDetailHeader from '../../components/screen/headers/SparePartsFilterDetailHeader'
import SyncIcon from '../../components/screen/headers/SyncIcon'
import { Screen } from '../../components/screen/Screen'
import SparePartsFilterContext from '../../contexts/SparePartsFilterContext'
import useEditOrCreateScreenBackManager from '../../hooks/useEditOrCreateScreenBackManager'
import usePopulateShoppingCart from '../../hooks/usePopulateShoppingCart'
import { ActivityStackScreenProps } from '../../navigation/navigators/common/ActivityCommonNavigator'
import { ThemeColorExpanded } from '../../types'
import { shoppingCartAtom, shoppingCartRemovalAtom, sparePartsFilterEnableAtom } from '../../utils/stateManager'
import ticketUtils from '../../utils/TicketUtils'
import ActivitySparePartsShoppingCartView from '../../views/activity/ActivitySparePartsShoppingCartView'
import ActivitySparePartsView from '../../views/activity/ActivitySparePartsView'

export default function ActivitySparePartsScreen({ route, navigation }: ActivityStackScreenProps<'ActivitySpareParts'>) {
  const { i18n } = useLanguage()
  const { isSmallDevice } = useDimensions()
  const { theme } = useTheme<ThemeColorExpanded>()
  const enabledFilters = useRecoilValue(sparePartsFilterEnableAtom)
  const [parts, setParts] = useRecoilState(shoppingCartAtom)
  const [removalParts, setRemovalParts] = useRecoilState(shoppingCartRemovalAtom)

  usePopulateShoppingCart({ activityId: route.params.activityId, ticketId: route.params.ticketId })

  const { setStatus, handleGoBack } = useEditOrCreateScreenBackManager({
    title: i18n.t('UNSAVED_CHANGES_TITLE'),
    description: i18n.t('DISCARD_UNSAVED_CHANGES'),
    onDiscard: () =>
      route.params.direction === 'Installation'
        ? setParts(ticketUtils.resetSpareParts(parts))
        : setRemovalParts(ticketUtils.resetSpareParts(removalParts)),
  })

  useEffect(() => {
    route.params.direction === 'Installation' && parts && ticketUtils.checkSparePartsPending(parts, setStatus)
  }, [parts])

  useEffect(() => {
    route.params.direction === 'Removed' && removalParts && ticketUtils.checkSparePartsPending(removalParts, setStatus)
  }, [removalParts])

  const handleSDNavigation = () => {
    navigation.navigate('ActivityModalCommonStack', { screen: 'SparePartsFilter' })
  }

  const partDirection = route.params.direction === 'Installation' ? parts : removalParts

  return (
    <SearchProvider>
      <SparePartsFilterContext.Consumer>
        {context => {
          let activeFilters = false
          context?.filters.forEach(filter =>
            filter.elements.forEach(el => {
              if (el.active) activeFilters = true
            })
          )
          const activeOrder = context?.orders.filter(el => el.active)

          return (
            <Screen>
              <Screen.Header goBack={handleGoBack} backHandlerCallback={handleGoBack}>
                <HeaderWithIcons
                  enabledOffline
                  title={route.params.direction === 'Installation' ? i18n.t('SPARE_PARTS_INSTALLATION') : i18n.t('SPARE_PARTS_REMOVAL')}
                  description={route.params.activityCode}
                  hideFilter={route.params.direction === 'Removed'}
                  tabletSearchComponent={
                    <SearchDetailHeaderContainer
                      enabledOffline
                      dividers={route.params.direction === 'Installation' ? ['left'] : undefined}
                      iconPosition="right"
                      enableQR
                    />
                  }
                  tabletFilterComponent={
                    <FilterDetailHeaderContainer dividers={['left']}>
                      <SparePartsFilterDetailHeader onSDNavigation={handleSDNavigation} enabled={enabledFilters} />
                    </FilterDetailHeaderContainer>
                  }
                  highlightFilter={route.params.direction === 'Installation' ? activeFilters || activeOrder?.length !== 0 : false}
                  disabled={route.params.direction === 'Installation' ? !enabledFilters : false}
                />
                {isSmallDevice && (
                  <PressableIcon
                    icon={['fal', route.params.direction === 'Installation' ? 'cart-plus' : 'cart-minus']}
                    color="white"
                    disabledColor={theme.text.detail}
                    size={21}
                    disabled={partDirection === undefined}
                    badgeLoading={partDirection === undefined}
                    badgeNumber={partDirection ? partDirection.filter(el => el.status !== 'deleted').length : 0}
                    badgeColor={route.params.direction === 'Installation' ? theme.general.info : theme.general.error}
                    hitSlop={{ top: 10, bottom: 8, right: 10 }}
                    onPress={() =>
                      navigation.navigate('ActivitySparePartsShoppingCart', {
                        activityCode: route.params.activityCode,
                        activityId: route.params.activityId,
                        ticketId: route.params.ticketId,
                        direction: route.params.direction,
                      })
                    }
                  />
                )}
                <IM.View style={styles.sync}>
                  <SyncIcon />
                </IM.View>
              </Screen.Header>
              <Screen.DetailHeader>
                <SearchDetailHeaderContainer enableQR />
                <FilterDetailHeaderContainer>
                  <SparePartsFilterDetailHeader onSDNavigation={handleSDNavigation} enabled={enabledFilters} />
                </FilterDetailHeaderContainer>
              </Screen.DetailHeader>
              <Screen.Content>
                <MasterDetailProvider>
                  <MasterDetail flexRatio={0.5}>
                    <MasterDetail.Master>
                      <ActivitySparePartsView
                        activityId={route.params.activityId}
                        direction={route.params.direction}
                        ticketId={route.params.ticketId}
                      />
                    </MasterDetail.Master>
                    <MasterDetail.Detail>
                      <ActivitySparePartsShoppingCartView
                        parts={partDirection}
                        activityId={route.params.activityId}
                        direction={route.params.direction}
                      />
                    </MasterDetail.Detail>
                  </MasterDetail>
                </MasterDetailProvider>
              </Screen.Content>
            </Screen>
          )
        }}
      </SparePartsFilterContext.Consumer>
    </SearchProvider>
  )
}

const styles = StyleSheet.create({
  sync: { paddingLeft: 4 },
})
