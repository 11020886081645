import { IM, SpacingProps, SpacingType, useTheme } from '@infominds/react-native-components'
import React, { memo, ReactNode } from 'react'
import { Platform, StyleSheet } from 'react-native'
import Animated from 'react-native-reanimated'

import { SCREEN_CONSTANTS, ScreenHeaderShadows } from '../constants/constants'
import useContentAnimation, { AnimatedOnScroll } from '../hooks/useContentAnimation'
import useScreen from '../hooks/useScreen'

interface ContentContainerProps {
  children: ReactNode | ((animatedOnScroll: AnimatedOnScroll) => ReactNode)
  spacing?: SpacingProps
  spacingType?: SpacingType
  forceBackground?: string
}

const ContentContainer = memo(function ContentContainer({ children, spacingType, forceBackground, spacing }: ContentContainerProps) {
  const { theme } = useTheme()
  const { height, detail } = useScreen()
  const { animatedStyle, onScrollHandler } = useContentAnimation()
  const enableAnimation = typeof children === 'function'

  return (
    <Animated.View
      style={[
        styles.container,
        { top: height.header + (enableAnimation ? 0 : detail.open ? height.detail : 0) },
        enableAnimation ? animatedStyle : {},
        Platform.OS !== 'web' && ScreenHeaderShadows,
      ]}>
      <IM.View
        spacing={spacing}
        spacingType={spacingType}
        style={[styles.view, { backgroundColor: forceBackground ?? theme.background.default }, Platform.OS === 'web' && ScreenHeaderShadows]}>
        {typeof children === 'function' ? children(onScrollHandler) : children}
      </IM.View>
    </Animated.View>
  )
})

export default ContentContainer

const styles = StyleSheet.create({
  container: {
    position: 'absolute',
    width: '100%',
    bottom: 0,
  },
  view: {
    flex: 1,
    overflow: 'hidden',
    borderTopLeftRadius: SCREEN_CONSTANTS.headerRadius,
    borderTopRightRadius: SCREEN_CONSTANTS.headerRadius,
  },
})
