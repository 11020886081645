import { IM, IMLayout, SpacingProps, useDimensions, useLanguage } from '@infominds/react-native-components'
import { NavigationProp, useNavigation } from '@react-navigation/native'
import React, { memo, useEffect } from 'react'
import { Platform, StyleProp, StyleSheet, ViewStyle } from 'react-native'

import api from '../../../apis/apiCalls'
import CardLeftTitle from '../../../components/card/left/CardLeftTitle'
import CellText from '../../../components/CellText'
import Chip from '../../../components/Chip'
import DynamicView from '../../../components/Infominds/DynamicView'
import useControlledLoader from '../../../components/Infominds/hooks/useControlledLoader'
import Pressable from '../../../components/Infominds/Pressable'
import Separator from '../../../components/Infominds/Separator'
import NavigationIcon from '../../../components/NavigationIcon'
import SkeletonText from '../../../components/skeleton/SkeletonText'
import { REQUEST_SERIAL_NUMBER_TICKETS } from '../../../constants/Keys'
import { TicketDetailCommonStackParamList } from '../../../navigation/navigators/common/TicketDetailCommonNavigator'
import TimeUtils from '../../../utils/TimeUtils'

interface Props {
  serialNumberId: string
  spacing?: SpacingProps
  style?: StyleProp<ViewStyle>
}

const firstColumn = IMLayout.flex.f2
const secondColumn = IMLayout.flex.f2
const thirdColumn = IMLayout.flex.f2
const fourthColumn = IMLayout.flex.f5
const fifthColum = { width: 18 }

const TicketArticlePastTicketsCard = memo(function TicketArticlePastTicketsCard({ serialNumberId, spacing, style }: Props) {
  const { i18n, language } = useLanguage()
  const { isSmallDevice } = useDimensions()
  const navigation = useNavigation<NavigationProp<TicketDetailCommonStackParamList>>()

  const { item, loadItem, loading } = useControlledLoader(api.getSerialNumberTickets, { id: REQUEST_SERIAL_NUMBER_TICKETS })

  useEffect(() => {
    loadItem({ serialnumberId: serialNumberId })
  }, [serialNumberId])

  return (
    <IM.Card head={<CardLeftTitle text={i18n.t('PAST_TICKETS')} />} spacing={spacing} style={[styles.container, style]} noContentSpacing>
      {/* eslint-disable-next-line react-native/no-inline-styles */}
      <IM.View style={{ margin: isSmallDevice ? 6 : 10 }}>
        {loading === false && item !== undefined && item.length !== 0 ? (
          <>
            {!isSmallDevice && (
              <IM.View>
                <DynamicView style={[IMLayout.flex.f1, styles.headerContainer]}>
                  <CellText style={firstColumn} secondary numberOfLines={1} value={i18n.t('STATUS')} />
                  <CellText style={secondColumn} secondary numberOfLines={1} value={i18n.t('TICKET_NUMBER')} />
                  <CellText style={thirdColumn} secondary numberOfLines={1} value={i18n.t('DATE')} />
                  <CellText style={fourthColumn} secondary numberOfLines={1} value={i18n.t('DESCRIPTION')} />
                  <IM.View style={fifthColum} />
                </DynamicView>
                <Separator spacing="none" type="primary" />
              </IM.View>
            )}
            {item?.map((data, index) => (
              <IM.View key={data.id}>
                <Pressable onPress={() => navigation.navigate('TicketDetailPastTicket', { ticketId: data.id, ticketCode: data.code })}>
                  <IM.View style={IMLayout.flex.row}>
                    <DynamicView style={IMLayout.flex.f1} flexLimitSmallDevice={1}>
                      <CellText
                        title={i18n.t('STATUS')}
                        value={
                          <IM.View style={IMLayout.flex.f1}>
                            <Chip value={data.stateCode} size="small" />
                          </IM.View>
                        }
                        style={[firstColumn, styles.align]}
                        disableSpacing
                      />
                      <CellText title={i18n.t('TICKET_NUMBER')} value={data.code} style={secondColumn} />
                      <CellText title={i18n.t('DATE')} value={TimeUtils.format(data.documentDate, language)} style={thirdColumn} />
                      <CellText
                        title={i18n.t('DESCRIPTION')}
                        value={data.description ?? '-'}
                        style={fourthColumn}
                        numberOfLines={Platform.OS === 'web' ? 1 : 2}
                      />
                      {!isSmallDevice && (
                        <IM.View style={[IMLayout.flex.row, fifthColum, styles.iconContainer]}>
                          <NavigationIcon style={styles.justify} />
                        </IM.View>
                      )}
                    </DynamicView>
                    {isSmallDevice && <NavigationIcon enableTableStyle />}
                  </IM.View>
                </Pressable>
                {index !== item?.length - 1 && <Separator spacing="none" />}
              </IM.View>
            ))}
          </>
        ) : (
          <>
            {item === undefined ? (
              <IM.View spacing="horizontal">
                {isSmallDevice ? (
                  <>
                    <SkeletonText width="100%" spacing="top" />
                    <SkeletonText width="100%" spacing="top" />
                    <SkeletonText width="100%" spacing="top" />
                    <SkeletonText width="100%" spacing="vertical" />
                  </>
                ) : (
                  <>
                    <SkeletonText width="100%" spacing="top" />
                    <Separator spacing="bottom" />
                    <SkeletonText width="100%" spacing="bottom" />
                  </>
                )}
              </IM.View>
            ) : (
              <IM.View spacing="all">
                <IM.Text secondary>{i18n.t('NO_PAST_TICKETS_FOUND')}</IM.Text>
              </IM.View>
            )}
          </>
        )}
      </IM.View>
    </IM.Card>
  )
})

export default TicketArticlePastTicketsCard

const styles = StyleSheet.create({
  align: { justifyContent: 'center', alignItems: 'center' },
  container: {
    flexGrow: 1,
  },
  headerContainer: { paddingHorizontal: 8, marginBottom: 2 },
  justify: { justifyContent: 'center' },
  iconContainer: { justifyContent: 'flex-end' },
})
