import { IM, IMLayout } from '@infominds/react-native-components'
import React, { memo, PropsWithChildren } from 'react'
import { StyleProp, StyleSheet, ViewStyle } from 'react-native'

import { Border } from '../../../types'
import appUtils from '../../../utils/appUtils'
import useBaseTextInput from './hooks/useBaseTextInput'

interface Props {
  borderColor?: string
  disableBorder?: Border | Border[]
  style?: StyleProp<ViewStyle>
}

const BaseTextInputRightIcon = memo(function BaseTextInputRightIcon({ children, borderColor, disableBorder, style }: PropsWithChildren<Props>) {
  const { backgroundColor, borderColor: contextBorderColor } = useBaseTextInput()

  return (
    <IM.View
      style={[
        styles.icon,
        styles.rightIcon,
        styles.container,
        { backgroundColor, borderColor: borderColor ?? contextBorderColor },
        disableBorder && appUtils.handleBorderStyle(disableBorder, { borderTopRightRadius: 0 }, { borderBottomRightRadius: 0 }),
        style,
      ]}>
      {children}
    </IM.View>
  )
})

export default BaseTextInputRightIcon

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
  },
  icon: {
    borderWidth: 1,
    alignItems: 'center',
    justifyContent: 'center',
    paddingLeft: 4,
    paddingRight: 2,
  },
  rightIcon: {
    borderLeftWidth: 0,
    borderTopRightRadius: IMLayout.borderRadius,
    borderBottomRightRadius: IMLayout.borderRadius,
    marginLeft: -2,
  },
})
