import React from 'react'

import { InfoboxStackScreenProps } from '../../navigation/navigators/common/InfoboxCommonNavigators'
import InfoboxAssetView from '../../views/infobox/InfoboxAssetView'

const InfoboxAssetScreen = ({ route }: InfoboxStackScreenProps<'InfoboxNoBottomTabAsset'>) => {
  return <InfoboxAssetView id={route.params.id} infoboxType={route.params.infoboxType} origin={route.params.origin} />
}

export default InfoboxAssetScreen
