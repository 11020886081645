import React, { createContext, Dispatch, ReactNode, SetStateAction, useMemo, useState } from 'react'

interface SearchContextProps {
  search: string
  setSearch: (text: string, isBarcodeSearch?: boolean) => void
  tabletOpen: boolean
  setTabletOpen: Dispatch<SetStateAction<boolean>>
  qrCodeScanned: boolean
}

const SearchContext = createContext<SearchContextProps | undefined>(undefined)

export const SearchProvider = ({ children }: { children: ReactNode }) => {
  const [search, setSearch] = useState('')
  const [qrCodeScanned, setQrCodeScanned] = useState(false)
  const [tabletOpen, setTabletOpen] = useState(false)

  const handleSearch = (text: string, isBarcodeSearch = false) => {
    setSearch(text)
    setQrCodeScanned(isBarcodeSearch)
  }

  const props = useMemo<SearchContextProps>(
    () => ({
      search,
      setSearch: handleSearch,
      tabletOpen,
      setTabletOpen,
      qrCodeScanned,
    }),
    [search, tabletOpen, qrCodeScanned]
  )

  return <SearchContext.Provider value={props}>{children}</SearchContext.Provider>
}

export default SearchContext
