import { IM, IMLayout, useDimensions, useEvent, useLanguage, useModalController } from '@infominds/react-native-components'
import { NavigationProp, useNavigation } from '@react-navigation/native'
import { FlashList, ListRenderItemInfo } from '@shopify/flash-list'
import dayjs from 'dayjs'
import cloneDeep from 'lodash/cloneDeep'
import groupBy from 'lodash/groupBy'
import React, { createRef, useContext, useEffect, useMemo } from 'react'
import { DnDSource, SchedulerData } from 'react-big-scheduler-stch'
import { Platform } from 'react-native'
import { useSetRecoilState } from 'recoil'

import api from '../../apis/apiCalls'
import { Activity, SMActivityType } from '../../apis/types/apiResponseTypes'
import ActivityListCard from '../../cards/activity/ActivityListCard'
import FlashListData from '../../components/FlashListData'
import useControlledLoader from '../../components/Infominds/hooks/useControlledLoader'
import { PlannerContext } from '../../components/planner/PlannerContext'
import { DnDType } from '../../components/planner/types'
import useSearch from '../../components/screen/hooks/useSearch'
import { REFRESH_ACTIVITY_PLANNING_LIST_EVENT_KEY, REMOVE_ACTIVITY_PLANNING_LIST_EVENT_KEY } from '../../constants/EmitterKeys'
import { REQUEST_ACTIVITY } from '../../constants/Keys'
import { FilterType, GroupType, OrderType, PlanDateFilterType } from '../../contexts/FilterContext'
import useFilter from '../../hooks/useFilter'
import ActivityAllocationModal from '../../modals/activity/ActivityAllocationModal'
import { PlanningStackParamList } from '../../navigation/navigators/bottomTabs/PlanningBottomTabNavigator'
import { ActivityAllocationParams, FilterElement } from '../../types'
import appUtils from '../../utils/appUtils'
import { activityPlanningFilterEnableAtom } from '../../utils/stateManager'
import ticketUtils from '../../utils/TicketUtils'
import TimeUtils from '../../utils/TimeUtils'

type PlanningMultipleAllocation = {
  enabled: boolean
  ids: string[]
}

type Props = {
  multipleAllocation?: PlanningMultipleAllocation
  onSelected?: (id: string) => void
  forceLayout?: false | 'small' | 'medium' | 'large'
  dndSource?: DnDSource
  newEvent?: (schedulerData: SchedulerData, slotId: string, slotName: string, start: string, end: string, type: DnDType, item: Activity) => void
}

export default function PlanningView({ multipleAllocation, forceLayout, dndSource, newEvent, onSelected }: Props) {
  const { search } = useSearch()
  const { i18n, language } = useLanguage()
  const { isSmallDevice } = useDimensions()
  const plannerContext = useContext(PlannerContext)
  const { filters, groups, orders, initFilters } = useFilter()
  const allocationController = useModalController<ActivityAllocationParams>()
  const navigation = useNavigation<NavigationProp<PlanningStackParamList>>()
  const enableFilter = useSetRecoilState(activityPlanningFilterEnableAtom)
  const { item: activities, loadItem: load, loading, setItem: setActivities } = useControlledLoader(api.getActivity, { id: REQUEST_ACTIVITY })

  const listRef = createRef<FlashList<string | Activity>>()

  useEvent({ key: REFRESH_ACTIVITY_PLANNING_LIST_EVENT_KEY }, () => refresh())
  useEvent<string>({ key: REMOVE_ACTIVITY_PLANNING_LIST_EVENT_KEY }, id =>
    setActivities(prev => {
      return prev?.filter(el => el.id !== id)
    })
  )

  const RenderItem = dndSource ? dndSource.getDragSource() : ActivityListCard
  const isPlanner = plannerContext !== undefined

  useEffect(() => {
    listRef.current?.scrollToOffset({
      animated: true,
      offset: 0,
    })
  }, [filters, groups, orders])

  useEffect(() => {
    refresh()
  }, [])

  useEffect(() => {
    if (loading === false) {
      const activitiesType: FilterElement[] = []
      const assistance: FilterElement[] = []
      const team: FilterElement[] = []
      const zone: FilterElement[] = []

      activities?.forEach(activity => {
        activity.activityType && activitiesType.push({ id: activity.activityType, active: false })
        activity.area && zone.push({ id: activity.area, active: false })
        activity.team && team.push({ id: activity.team, active: false })
        activity.maintenanceType && assistance.push({ id: activity.maintenanceType, active: false })
      })

      initFilters(activitiesType, assistance, zone, team)
      enableFilter(true)
    } else {
      enableFilter(false)
    }
  }, [loading, activities])

  function refresh() {
    load({ state: 'UserFilterActivitiesToPlanning', smActivityType: SMActivityType.InPlanning })
  }

  const renderItem = (elem: ListRenderItemInfo<Activity | string>) => {
    const isLast = elem.index === (filtered?.length ?? 0) - 1
    const item = elem.item

    if (typeof item === 'string') return <></>

    return (
      <RenderItem
        schedulerData={plannerContext?.schedulerData}
        newEvent={newEvent}
        type="planning"
        forceLayout={forceLayout}
        activity={item}
        selectable={multipleAllocation?.enabled}
        onPress={() =>
          navigation.navigate('PlanningActivityCommonStack', {
            screen: 'ActivityPlanning',
            params: {
              activityId: item.id,
              ticketId: item.ticketId,
              documentId: item.ticketDocumentId,
              activityCode: item.code,
              start: false,
              ticketCode: item.ticketCode,
              link: true,
            },
          })
        }
        onSelected={onSelected}
        style={[
          // eslint-disable-next-line react-native/no-inline-styles
          {
            marginTop: IMLayout.horizontalMargin,
            marginBottom: isLast ? IMLayout.horizontalMargin : 0,
            marginHorizontal: (isPlanner ? 1 : 2) * IMLayout.horizontalMargin,
          },
        ]}
      />
    )
  }

  const handleActivityAllocation = () => {
    if (multipleAllocation === undefined) return

    let firstActivityEmployeeId: string | undefined

    for (const activity of activities ?? []) {
      if (!multipleAllocation.ids.includes(activity.id)) continue

      firstActivityEmployeeId = activity.employeeId

      if (firstActivityEmployeeId !== undefined) break
    }

    const data: ActivityAllocationParams = {
      activityIds: multipleAllocation.ids,
      employeeId: firstActivityEmployeeId,
      origin: 'planningList',
    }

    isSmallDevice && (Platform.OS === 'android' || Platform.OS === 'ios')
      ? navigation.navigate('PlanningActivityCommonStack', { screen: 'ActivityAllocation', params: data })
      : allocationController.show(data)
  }

  const filtered: (Activity | string)[] | undefined = useMemo(() => {
    let activitiesClone = cloneDeep(activities)

    // Search
    activitiesClone = activitiesClone
      ? appUtils.filter(
          activitiesClone,
          search,
          [
            'code',
            'ticketCode',
            'customer',
            'articleDescription',
            'serialnumber',
            'ticketDescription',
            'serialnumberManufacturerNumber',
            'serialnumberLocation',
            'shippingAddress',
            'location',
            'planDate',
          ],
          language
        )
      : []

    // Grouping
    let activityGrouped: { [key: string]: Activity[] } = { undefined: activitiesClone }
    const activeGroups = groups.find(group => group.active)
    switch (activeGroups?.data.id) {
      case GroupType.Assistance: {
        activityGrouped = groupBy(activitiesClone, el => el.maintenanceType)
        activityGrouped = ticketUtils.replaceUndefinedSection(activityGrouped, i18n.t('NO_ASSISTANCE_TYPE'))

        break
      }
      case GroupType.Customer: {
        activityGrouped = groupBy(activitiesClone, el => el.customer)
        activityGrouped = ticketUtils.replaceUndefinedSection(activityGrouped, i18n.t('NO_CUSTOMER'))

        break
      }
      case GroupType.Zone: {
        activityGrouped = groupBy(activitiesClone, el => el.area)
        activityGrouped = ticketUtils.replaceUndefinedSection(activityGrouped, i18n.t('NO_ZONE'))

        break
      }
    }

    const activeOrder = orders.find(order => order.active)
    switch (activeOrder?.data.id) {
      case OrderType.DateAscending: {
        Object.keys(activityGrouped).forEach(key => {
          activityGrouped[key] = TimeUtils.sortDate(activityGrouped[key], 'date', 'asc', (a, b) => a.code.localeCompare(b.code))
        })

        break
      }
      case OrderType.DateDescending: {
        Object.keys(activityGrouped).forEach(key => {
          activityGrouped[key] = TimeUtils.sortDate(activityGrouped[key], 'date', 'desc', (a, b) => b.code.localeCompare(a.code))
        })

        break
      }
      case OrderType.PlanningAscending: {
        Object.keys(activityGrouped).forEach(key => {
          activityGrouped[key] = TimeUtils.sortDate(
            activityGrouped[key],
            'planDate',
            'asc',
            (a, b) => (a.planStartTime ?? 0) - (b.planStartTime ?? 0)
          )
        })

        break
      }
      case OrderType.PlanningDescending: {
        Object.keys(activityGrouped).forEach(key => {
          activityGrouped[key] = TimeUtils.sortDate(
            activityGrouped[key],
            'planDate',
            'desc',
            (a, b) => (b.planStartTime ?? 0) - (a.planStartTime ?? 0)
          )
        })

        break
      }
      case OrderType.TownAscending: {
        Object.keys(activityGrouped).forEach(key => {
          activityGrouped[key] = appUtils.sortAlphabetically(activityGrouped[key], 'town', 'asc', (a, b) => a.code.localeCompare(b.code))
        })

        break
      }
      case OrderType.TownDescending: {
        Object.keys(activityGrouped).forEach(key => {
          activityGrouped[key] = appUtils.sortAlphabetically(activityGrouped[key], 'town', 'desc', (a, b) => b.code.localeCompare(a.code))
        })

        break
      }
    }

    const activeFilters = ticketUtils.activeFilters(filters)
    activeFilters.forEach(filter => {
      switch (filter.id) {
        case FilterType.Teams: {
          Object.keys(activityGrouped).forEach(key =>
            Object.assign(activityGrouped, {
              ...activityGrouped,
              [key]: activityGrouped[key].filter(clone => (clone.team ? filter.elements.find(el => el.id === clone.team) !== undefined : false)),
            })
          )
          break
        }
        case FilterType.Activity: {
          Object.keys(activityGrouped).forEach(key =>
            Object.assign(activityGrouped, {
              ...activityGrouped,
              [key]: activityGrouped[key].filter(clone =>
                clone.activityType ? filter.elements.find(el => el.id === clone.activityType) !== undefined : false
              ),
            })
          )
          break
        }
        case FilterType.Assistance: {
          Object.keys(activityGrouped).forEach(key =>
            Object.assign(activityGrouped, {
              ...activityGrouped,
              [key]: activityGrouped[key].filter(clone =>
                clone.maintenanceType ? filter.elements.find(el => el.id === clone.maintenanceType) !== undefined : false
              ),
            })
          )
          break
        }
        case FilterType.Zone: {
          Object.keys(activityGrouped).forEach(key =>
            Object.assign(activityGrouped, {
              ...activityGrouped,
              [key]: activityGrouped[key].filter(clone => (clone.area ? filter.elements.find(el => el.id === clone.area) !== undefined : false)),
            })
          )

          break
        }
        case FilterType.PlanDate: {
          const from = filter.elements.find(el => el.id === PlanDateFilterType.PlanDateFrom)?.value
          const to = filter.elements.find(el => el.id === PlanDateFilterType.PlanDateTo)?.value

          Object.keys(activityGrouped).forEach(key =>
            Object.assign(activityGrouped, {
              ...activityGrouped,
              [key]: activityGrouped[key].filter(clone => (clone.planDate ? dayjs(clone.planDate).isBetween(from, to, null, '[]') : false)),
            })
          )

          break
        }
      }
    })

    const data: (string | Activity)[] = []
    const keys = Object.keys(activityGrouped)

    keys.forEach(key => {
      if (activityGrouped[key].length !== 0) {
        if (key !== 'undefined') {
          data.push(key)
          data.push(...activityGrouped[key])
        } else {
          data.push(...activityGrouped[key])
        }
      }
    })

    return data
  }, [filters, groups, orders, activities, search, language])

  return (
    <>
      <FlashListData
        ref={listRef}
        data={filtered}
        loading={loading}
        noDataMessage={i18n.t('NO_ACTIVITY_FOUND')}
        renderItem={renderItem}
        isSearching={search !== ''}
        refresh={refresh}
        marginTop={isPlanner ? 0 : undefined}
        extraData={multipleAllocation?.enabled}
      />
      <IM.SideButton
        show={multipleAllocation?.enabled && multipleAllocation.ids.length > 0}
        icon={['fal', 'plus']}
        text={i18n.t('ACTIVITY_ALLOCATION_SHORT')}
        onPress={handleActivityAllocation}
        bottom={20}
        iconSize={20}
      />
      <ActivityAllocationModal controller={allocationController} />
    </>
  )
}
