import { useDimensions } from '@infominds/react-native-components'
import { useAuthentication } from '@infominds/react-native-license'
import { CameraView } from '@infominds/react-native-media'
import { NavigationProp, useNavigation } from '@react-navigation/native'
import React from 'react'
import { OrientationType } from 'react-native-orientation-locker'
import { useRecoilValue } from 'recoil'

import { InfoboxCommonStackParamList } from '../../navigation/navigators/common/InfoboxCommonNavigators'
import { saveMediaOnStorageAtom } from '../../utils/stateManager'

const InfoboxCameraView = () => {
  const { isSmallDevice } = useDimensions()
  const { sessionKey } = useAuthentication()
  const navigation = useNavigation<NavigationProp<InfoboxCommonStackParamList>>()
  const saveMediaOnStorage = useRecoilValue(saveMediaOnStorageAtom(sessionKey))

  return (
    <CameraView
      saveOnGallery={saveMediaOnStorage}
      orientationOnExit={isSmallDevice ? OrientationType.PORTRAIT : undefined}
      onClose={() => {
        navigation.goBack()
      }}
      onSettings={() => navigation.navigate('InfoboxNoBottomTabCameraSettings')}
    />
  )
}

export default InfoboxCameraView
