import { useInfomindsAnalyticsNavigation } from '@infominds/react-native-analytics'
import { useEvent, useLanguage, useTheme } from '@infominds/react-native-components'
import AsyncStorage from '@react-native-async-storage/async-storage'
import { DarkTheme, DefaultTheme, InitialState, NavigationState, NavigationContainer as ReactNavigationContainer } from '@react-navigation/native'
import React, { PropsWithChildren, useCallback, useEffect, useRef, useState } from 'react'
import { Platform } from 'react-native'
import { useSetRecoilState } from 'recoil'

import { HIDE_NAVIGATION_EVENT_KEY } from '../constants/EmitterKeys'
import { STORAGE_KEY_NAVIGATION_STATE } from '../constants/Keys'
import useMenu from '../hooks/useMenu'
import appUtils from '../utils/appUtils'
import navigationUtils from '../utils/navigationUtils'
import { landingPageUrlAtom } from '../utils/stateManager'
import { utils } from '../utils/utils'
import { HistoryStackNames } from './navigators/bottomTabs/HistoryBottomTabNavigator'
import { PasswordStackNames } from './navigators/bottomTabs/PasswordBottomTabNavigator'
import { PlanningStackNames } from './navigators/bottomTabs/PlanningBottomTabNavigator'
import { QualityStackNames } from './navigators/bottomTabs/QualityBottomTabNavigator'
import { SettingsStackNames } from './navigators/bottomTabs/SettingsBottomTabNavigator'
import { SparePartsStackNames } from './navigators/bottomTabs/SparePartsBottomTabNavigator'
import { SynchronizationStackNames } from './navigators/bottomTabs/SynchronizationBottomTabNavigator'
import { TicketStackNames } from './navigators/bottomTabs/TicketBottomTabNavigator'
import { RootStackParamList } from './navigators/RootNavigator'

interface Props extends PropsWithChildren {
  basePath: string
}

export default function NavigationContainer({ children, basePath }: Props) {
  const previousRouteStack = useRef<string>()

  const { i18n } = useLanguage()
  const { colorScheme } = useTheme()
  const { onRouteChange } = useMenu()

  const [isNavigationReady, setNavigationReady] = useState(!__DEV__)
  const [initialState, setInitialState] = useState<InitialState | undefined>()
  const [key, setKey] = useState(utils.generateUuid())

  const setLandingPage = useSetRecoilState(landingPageUrlAtom)

  const { navigationRef, onReady, onStateChange: onStateChangeAnalytics } = useInfomindsAnalyticsNavigation<keyof RootStackParamList>()

  const { emit } = useEvent<boolean>({ key: HIDE_NAVIGATION_EVENT_KEY })

  useEffect(() => {
    Platform.OS === 'web' && setLandingPage(window.location.href)
  }, [])

  useEffect(() => {
    Platform.OS === 'web' && console.info(`base path: ${basePath}`)
  }, [basePath])

  useEffect(() => {
    if (!isNavigationReady) {
      AsyncStorage.getItem(STORAGE_KEY_NAVIGATION_STATE)
        .then(savedStateString => {
          const state = savedStateString ? (JSON.parse(savedStateString) as InitialState) : undefined
          setInitialState(state)
        })
        .catch(console.error)
        .finally(() => setNavigationReady(true))
    }
  }, [isNavigationReady])

  useEffect(() => {
    if (navigationRef.current) {
      const currentRoute = navigationRef.getCurrentRoute()

      if (currentRoute) {
        previousRouteStack.current = navigationUtils.findStack(navigationRef.current.getRootState(), currentRoute.key)
      }
    }
  }, [key])

  const onStateChange = useCallback((state: NavigationState | undefined) => {
    AsyncStorage.setItem(STORAGE_KEY_NAVIGATION_STATE, JSON.stringify(state)).catch(err => console.error('Can not save state', err))

    onStateChangeAnalytics()

    const currentRoute = navigationRef.getCurrentRoute()

    if (currentRoute?.name.includes('InfoboxNoBottomTab')) {
      emit(true)
    } else {
      emit(false)
    }

    if (currentRoute) {
      onRouteChange(previousRouteStack.current)
      previousRouteStack.current = navigationUtils.findStack(navigationRef.getRootState(), currentRoute.key)
    }
  }, [])

  return (
    <>
      {isNavigationReady && (
        <ReactNavigationContainer
          ref={navigationRef}
          onReady={() => {
            setKey(utils.generateUuid())
            onReady()
          }}
          initialState={Platform.OS === 'web' || !__DEV__ ? undefined : initialState}
          onStateChange={onStateChange}
          documentTitle={{
            formatter: (_options, route) => appUtils.getDocumentTitle(route, i18n),
          }}
          linking={{
            enabled: true,
            prefixes: [],
            config: {
              screens: {
                BottomTab: {
                  path: basePath,
                  screens: {
                    HistoryStack: HistoryStackNames,
                    PasswordsStack: PasswordStackNames,
                    PlanningStack: PlanningStackNames,
                    QualityStack: QualityStackNames,
                    SettingsStack: SettingsStackNames,
                    SparePartsStack: SparePartsStackNames,
                    SynchronizationStack: SynchronizationStackNames,
                    TicketsStack: TicketStackNames,
                    MoreEmpty: {},
                  },
                },
                EditNavigation: `${basePath}edit`,
                Login: `${basePath}login`,
                NotFound: `${basePath}*`,
                More: `${basePath}more`,
              },
            },
          }}
          theme={colorScheme === 'light' ? DefaultTheme : DarkTheme}>
          {children}
        </ReactNavigationContainer>
      )}
    </>
  )
}
