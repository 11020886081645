import { CompositeScreenProps } from '@react-navigation/native'
import { createNativeStackNavigator, NativeStackScreenProps } from '@react-navigation/native-stack'
import React from 'react'

import { QualityCheckFilterProvider } from '../../../contexts/QualityCheckFilterContext'
import EditAddFieldsScreen from '../../../screens/common/EditAddFieldsScreen'
import EditClassificationScreen from '../../../screens/common/EditClassificationScreen'
import EditInvoiceTypeScreen from '../../../screens/common/EditInvoiceTypeScreen'
import EditStateScreen from '../../../screens/common/EditStateScreen'
import FilterScreen from '../../../screens/common/FilterScreen'
import NoteEditOrCreateScreen from '../../../screens/common/NoteEditOrCreateScreen'
import QualityCheckFilterScreen from '../../../screens/common/QualityCheckFilterScreen'
import QualityCheckScreen from '../../../screens/common/QualityCheckScreen'
import QualityFilterScreen from '../../../screens/common/QualityFilterScreen'
import SparePartsFilterScreen from '../../../screens/common/SparePartsFilterScreen'
import { AdditionalFieldsParams, ClassificationParams, EditInvoiceTypeParams, EditStateParams, NoteEditOrCreateParams } from '../../../types'
import { screenOptions } from '../../Navigator'
import { HistoryStackScreenProps } from '../bottomTabs/HistoryBottomTabNavigator'
import { PlanningStackScreenProps } from '../bottomTabs/PlanningBottomTabNavigator'
import { QualityStackScreenProps } from '../bottomTabs/QualityBottomTabNavigator'
import { SparePartsStackScreenProps } from '../bottomTabs/SparePartsBottomTabNavigator'
import { TicketStackScreenProps } from '../bottomTabs/TicketBottomTabNavigator'
import { ActivityStackScreenProps } from './ActivityCommonNavigator'
import { TicketDetailCommonStackScreenProps } from './TicketDetailCommonNavigator'

export type ModalCommonStackParamList = {
  EditAddFields: AdditionalFieldsParams
  EditClassification: ClassificationParams
  NoteEditOrCreate: NoteEditOrCreateParams
  Filter: undefined
  SparePartsFilter: undefined
  QualityFilter: undefined
  QualityCheck: { activityId?: string; activityCode?: string; serialNumberId?: string; serialNumber?: string; serialNumberManufacturer?: string }
  QualityCheckFilters: undefined
  EditState: EditStateParams
  EditInvoiceType: EditInvoiceTypeParams
}

export const ModalCommonStackNames: { path: string; screens: Record<keyof ModalCommonStackParamList, string> } = {
  path: 'common',
  screens: {
    EditAddFields: 'addfields',
    EditClassification: 'editclass',
    NoteEditOrCreate: 'noteedit',
    Filter: 'filter',
    SparePartsFilter: 'sparepartsfilter',
    QualityFilter: 'qualityfilter',
    QualityCheck: 'qualitycheck',
    QualityCheckFilters: 'qualitycheckfilters',
    EditState: 'editstate',
    EditInvoiceType: 'editinvoicetype',
  },
}

export type ModalCommonStackScreenProps<T extends keyof ModalCommonStackParamList> = CompositeScreenProps<
  NativeStackScreenProps<ModalCommonStackParamList, T>,
  | TicketStackScreenProps<'TicketModalCommonStack'>
  | TicketDetailCommonStackScreenProps<'TicketDetailModalCommonStack'>
  | HistoryStackScreenProps<'HistoryModalCommonStack'>
  | PlanningStackScreenProps<'PlanningModalCommonStack'>
  | ActivityStackScreenProps<'ActivityModalCommonStack'>
  | SparePartsStackScreenProps<'SparePartsModalCommonStack'>
  | QualityStackScreenProps<'QualityModalCommonStack'>
>

const ModalCommonStack = createNativeStackNavigator<ModalCommonStackParamList>()
export function ModalCommonStackNavigator() {
  return (
    <QualityCheckFilterProvider>
      <ModalCommonStack.Navigator screenOptions={screenOptions}>
        <ModalCommonStack.Screen name="EditAddFields" component={EditAddFieldsScreen} />
        <ModalCommonStack.Screen name="EditClassification" component={EditClassificationScreen} />
        <ModalCommonStack.Screen name="NoteEditOrCreate" component={NoteEditOrCreateScreen} />
        <ModalCommonStack.Screen name="Filter" component={FilterScreen} />
        <ModalCommonStack.Screen name="SparePartsFilter" component={SparePartsFilterScreen} />
        <ModalCommonStack.Screen name="QualityFilter" component={QualityFilterScreen} />
        <ModalCommonStack.Screen name="QualityCheck" component={QualityCheckScreen} />
        <ModalCommonStack.Screen name="QualityCheckFilters" component={QualityCheckFilterScreen} />
        <ModalCommonStack.Screen name="EditState" component={EditStateScreen} />
        <ModalCommonStack.Screen name="EditInvoiceType" component={EditInvoiceTypeScreen} />
      </ModalCommonStack.Navigator>
    </QualityCheckFilterProvider>
  )
}
