import { CompositeScreenProps, NavigatorScreenParams } from '@react-navigation/native'
import { createNativeStackNavigator, NativeStackScreenProps } from '@react-navigation/native-stack'
import React from 'react'

import { FilterProvider } from '../../../contexts/FilterContext'
import TicketCreationScreen from '../../../screens/tickets/TicketCreationScreen'
import TicketsScreen from '../../../screens/tickets/TicketsScreen'
import { screenOptions } from '../../Navigator'
import { AppBottomTabScreenProps, BottomTabParamList } from '../../types'
import { ModalCommonStackNames, ModalCommonStackNavigator, ModalCommonStackParamList } from '../common/ModalCommonNavigator'
import {
  TicketDetailCommonStackNames,
  TicketDetailCommonStackNavigator,
  TicketDetailCommonStackParamList,
} from '../common/TicketDetailCommonNavigator'

export type TicketStackParamList = {
  Tickets: undefined
  TicketCreation: undefined
  TicketModalCommonStack: NavigatorScreenParams<ModalCommonStackParamList>
  TicketDetailCommonStack: NavigatorScreenParams<TicketDetailCommonStackParamList>
}

export const TicketStackNames: {
  path: string
  screens: Record<keyof TicketStackParamList, string | typeof TicketDetailCommonStackNames | typeof ModalCommonStackNames>
} = {
  path: 'ticket',
  screens: {
    Tickets: 'list',
    TicketCreation: 'creation',
    TicketModalCommonStack: ModalCommonStackNames,
    TicketDetailCommonStack: TicketDetailCommonStackNames,
  },
}

export type TicketStackScreenProps<T extends keyof TicketStackParamList> = CompositeScreenProps<
  NativeStackScreenProps<TicketStackParamList, T>,
  AppBottomTabScreenProps<keyof BottomTabParamList>
>

const TicketsStack = createNativeStackNavigator<TicketStackParamList>()
export function TicketStackNavigator() {
  return (
    <FilterProvider disableOrderByTown disableFilterByPlanDate storageKeyUniqueId="TicketsStack">
      <TicketsStack.Navigator screenOptions={screenOptions}>
        <TicketsStack.Screen name="Tickets" component={TicketsScreen} />
        <TicketsStack.Screen name="TicketCreation" component={TicketCreationScreen} />
        <TicketsStack.Screen name="TicketModalCommonStack" component={ModalCommonStackNavigator} />
        <TicketsStack.Screen name="TicketDetailCommonStack" component={TicketDetailCommonStackNavigator} />
      </TicketsStack.Navigator>
    </FilterProvider>
  )
}
