import { CompositeScreenProps, NavigatorScreenParams } from '@react-navigation/native'
import { createNativeStackNavigator, NativeStackScreenProps } from '@react-navigation/native-stack'
import React from 'react'

import { AccessData } from '../../../apis/types/apiResponseTypes'
import { AccessDataPermission } from '../../../contexts/AccessDataFilterContext'
import AccessDataEditOrCreateScreen from '../../../screens/accessData/AccessDataEditOrCreateScreen'
import AccessDataFilterScreen from '../../../screens/accessData/AccessDataFilterScreen'
import AccessDataScreen from '../../../screens/accessData/AccessDataScreen'
import { AccessDataEditOrCreateParams, AccessDataFilter } from '../../../types'
import { screenOptions } from '../../Navigator'
import { PasswordStackScreenProps } from '../bottomTabs/PasswordBottomTabNavigator'
import { CustomerCommonStackNames, CustomerCommonStackNavigator, CustomerCommonStackParamList } from './CustomerCommonNavigators'
import { TicketDetailCommonStackScreenProps } from './TicketDetailCommonNavigator'

export type AccessDataCommonStackParamList = {
  AccessData: CustomerCommonStackParamList['CustomerDetail']
  AccessDataEditOrCreation: AccessDataEditOrCreateParams
  AccessDataFilter: { filters: AccessDataFilter[]; permissions: AccessDataPermission[] }
  AccessDataDetail: { accessData: AccessData; groupAccessGranted: boolean; levelAccessGranted: boolean; color: string }
  AccessDataCustomerCommonStack: NavigatorScreenParams<CustomerCommonStackParamList>
}

export const AccessDataCommonStackNames: {
  path: string
  screens: Record<keyof AccessDataCommonStackParamList, string | typeof CustomerCommonStackNames>
} = {
  path: 'accessdata',
  screens: {
    AccessData: 'list',
    AccessDataEditOrCreation: 'edit',
    AccessDataFilter: 'filters',
    AccessDataDetail: 'detail',
    AccessDataCustomerCommonStack: CustomerCommonStackNames,
  },
}

export type AccessDataStackScreenProps<T extends keyof AccessDataCommonStackParamList> = CompositeScreenProps<
  NativeStackScreenProps<AccessDataCommonStackParamList, T>,
  TicketDetailCommonStackScreenProps<'TicketCustomerCommonStack'> | PasswordStackScreenProps<'PasswordAccessDataCommonStack'>
>

const AccessDataCommonStack = createNativeStackNavigator<AccessDataCommonStackParamList>()
export function AccessDataCommonStackNavigator() {
  return (
    <AccessDataCommonStack.Navigator screenOptions={screenOptions}>
      <AccessDataCommonStack.Screen name="AccessData" component={AccessDataScreen} />
      <AccessDataCommonStack.Screen name="AccessDataEditOrCreation" component={AccessDataEditOrCreateScreen} />
      <AccessDataCommonStack.Screen name="AccessDataFilter" component={AccessDataFilterScreen} />
      <AccessDataCommonStack.Screen name="AccessDataCustomerCommonStack" component={CustomerCommonStackNavigator} />
    </AccessDataCommonStack.Navigator>
  )
}
