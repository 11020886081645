/* eslint-disable react-native/no-inline-styles */

import { IM, IMLayout, IMStyle, useLanguage, useModalController, useTheme } from '@infominds/react-native-components'
import { Badge } from 'antd'
import React, { memo, useState } from 'react'
import { DnDSource } from 'react-big-scheduler-stch'
import { StyleSheet } from 'react-native'
import { useRecoilValue } from 'recoil'

import { Activity } from '../../apis/types/apiResponseTypes'
import Pressable from '../../components/Infominds/Pressable'
import TextWithIcon from '../../components/Infominds/TextWithIcon'
import PlannerExtraActivityList from '../../components/planner/PlannerExtraActivityList'
import { DnDType } from '../../components/planner/types'
import TabSelector from '../../components/TabSelector'
import Text from '../../components/Text'
import useFilter from '../../hooks/useFilter'
import FilterModal from '../../modals/common/FilterModal'
import { ModalCommonStackParamList } from '../../navigation/navigators/common/ModalCommonNavigator'
import { ThemeColorExpanded } from '../../types'
import { activityPlanningFilterEnableAtom } from '../../utils/stateManager'
import PlanningView from './PlanningView'

enum ViewType {
  ACTIVITY,
  EXTRA,
}

type Props = {
  dndSource: DnDSource
  extraDndSource: DnDSource
  onNewEvent: (activity: Activity, slotId: string, start: string, end: string) => void
  onNewTravelTime: (slotId: string, start: string, end: string) => void
}

function PlannerSideView({ dndSource, extraDndSource, onNewEvent, onNewTravelTime }: Props) {
  const { i18n } = useLanguage()
  const { theme } = useTheme<ThemeColorExpanded>()
  const controller = useModalController<ModalCommonStackParamList['Filter']>()

  const [view, setView] = useState<ViewType>(ViewType.ACTIVITY)

  const enabled = useRecoilValue(activityPlanningFilterEnableAtom)

  const { activeCount } = useFilter()

  return (
    <>
      <div style={{ position: 'absolute', top: 0, right: 0, height: '100%', width: '30%', overflowY: 'auto' }}>
        <>
          <IM.View style={[IMLayout.flex.f1, IMLayout.flex.row, { justifyContent: 'space-between', alignItems: 'center' }]}>
            <IM.View style={[styles.title, IMLayout.flex.f1]}>
              <Text style={{ fontSize: IMStyle.typography.fontSizeMedium, fontWeight: IMStyle.typography.fontWeightMedium }}>
                {i18n.t('ACTIVITIES_TO_BE_PLANNED')}
              </Text>
              <Text secondary>{i18n.t('ACTIVITIES_TO_BE_PLANNED_EXPLANATION')}</Text>
            </IM.View>
            {view === ViewType.ACTIVITY && (
              <Pressable
                disabled={!enabled}
                onPress={() => controller.show(undefined)}
                style={{ marginLeft: IMLayout.horizontalMargin }}
                hitSlop={{ top: 10, bottom: 8 }}>
                <TextWithIcon alignIcon="right" icon={['fal', 'chevron-right']} secondary={!enabled}>
                  <Badge
                    count={activeCount}
                    showZero={enabled}
                    color={theme.general.info}
                    size="small"
                    overflowCount={99}
                    style={{ boxShadow: `0 0 0 1px ${theme.background.default}` }}
                    offset={[3, -1]}>
                    {i18n.t('FILTERS')}
                  </Badge>
                </TextWithIcon>
              </Pressable>
            )}
            <TabSelector selectionColor={theme.pressable.highlight} height={26} width={100}>
              <TabSelector.Item
                icon={['fal', 'ticket']}
                iconSize={20}
                selected={view === ViewType.ACTIVITY}
                onPress={() => setView(ViewType.ACTIVITY)}
                textColor={theme.text.default}
                selectedTextColor={theme.general.info}
                dividerColor={theme.drawerNavigator.background}
              />
              <TabSelector.Item
                icon={['fal', 'business-time']}
                iconSize={20}
                selected={view === ViewType.EXTRA}
                onPress={() => setView(ViewType.EXTRA)}
                textColor={theme.text.default}
                selectedTextColor={theme.general.info}
                dividerColor={theme.drawerNavigator.background}
                disableDivider
              />
            </TabSelector>
          </IM.View>
          {view === ViewType.ACTIVITY && (
            <PlanningView
              forceLayout="small"
              dndSource={dndSource}
              newEvent={(_, slotId, _slotName, start, end, _type, activity) => {
                onNewEvent(activity, slotId, start, end)
              }}
            />
          )}
          {view === ViewType.EXTRA && (
            <PlannerExtraActivityList
              dndSource={extraDndSource}
              newEvent={(_, slotId, _slotName, start, end, type, _activity) => {
                console.log('🚀 ~ PlannerSideView ~ slotId:', slotId, type)
                if (type === DnDType.TRAVEL_TIME) {
                  onNewTravelTime(slotId, start, end)
                }
              }}
            />
          )}
        </>
      </div>
      <FilterModal controller={controller} />
    </>
  )
}

const styles = StyleSheet.create({
  title: {
    marginTop: 8,
    marginLeft: 8,
  },
})

export default memo(PlannerSideView)
