import { IM, useDimensions } from '@infominds/react-native-components'
import React, { memo, PropsWithChildren, ReactNode, useEffect, useRef } from 'react'
import { Platform, StyleProp, StyleSheet, ViewStyle } from 'react-native'
import { initialWindowMetrics, SafeAreaProvider } from 'react-native-safe-area-context'

import SearchDetailHeaderContainer from '../components/screen/headers/containers/SearchDetailHeaderContainer'
import { DefaultSearchDetailHeaderProps } from '../components/screen/headers/default/DefaultSearchDetailHeader'
import HeaderWithIcons from '../components/screen/headers/HeaderWithIcons'
import useSearch from '../components/screen/hooks/useSearch'
import { Screen } from '../components/screen/Screen'

export type ContentModalProps = {
  isVisible: boolean
  title?: string
  style?: StyleProp<ViewStyle>
  focusSearch?: boolean
  deferredTimeout?: number | undefined
  disableBackdropOpacity?: boolean
  extraIcon?: ReactNode
  close: () => void
  onSearchChange?: (searchText: string) => void
}

const SelectModal = memo(function SelectModal({
  title = '',
  focusSearch = false,
  isVisible,
  style,
  children,
  deferredTimeout = 350,
  extraIcon,
  disableBackdropOpacity,
  ...other
}: PropsWithChildren<ContentModalProps>) {
  const { close, onSearchChange } = other
  const firstRender = useRef(true)
  const { search } = useSearch()
  const { isSmallDevice } = useDimensions()

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false
    } else {
      onSearchChange?.(search)
    }
  }, [search])

  const commonSearchProps: DefaultSearchDetailHeaderProps = {
    deferredTimeout: deferredTimeout,
    enableQR: false,
  }

  return (
    <IM.Modal
      statusBarTranslucent
      isVisible={isVisible}
      backdropOpacity={disableBackdropOpacity ? 0 : undefined}
      onBackdropPress={close}
      onBackButtonPress={close}
      style={[style, styles.modal]}>
      <SafeAreaProvider initialMetrics={initialWindowMetrics}>
        <Screen forceDetailOpen={focusSearch} transparent={!isSmallDevice}>
          <Screen.Header goBack={close} isModal={Platform.OS === 'web' ? true : !isSmallDevice}>
            <HeaderWithIcons
              enabledOffline
              title={title}
              hideFilter
              hideSearch={onSearchChange === undefined}
              extraIcon={extraIcon}
              tabletSearchComponent={
                onSearchChange === undefined ? undefined : <SearchDetailHeaderContainer enabledOffline iconPosition="right" {...commonSearchProps} />
              }
            />
          </Screen.Header>
          <Screen.DetailHeader>
            <SearchDetailHeaderContainer {...commonSearchProps} />
          </Screen.DetailHeader>
          <Screen.Content>{children}</Screen.Content>
        </Screen>
      </SafeAreaProvider>
    </IM.Modal>
  )
})

const styles = StyleSheet.create({
  modal: { backgroundColor: 'transparent' },
})

export default SelectModal
