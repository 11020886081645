import { IMStyle, useDimensions, useTheme } from '@infominds/react-native-components'
import { useAuthentication } from '@infominds/react-native-license'
import React from 'react'
import { useRecoilState, useRecoilValue } from 'recoil'

import { MasterDetailProvider } from '../../components/MasterDetail/contexts/MasterDetailContext'
import { SearchProvider } from '../../components/screen/contexts/SearchContext'
import FilterDetailHeaderContainer from '../../components/screen/headers/containers/FilterDetailHeaderContainer'
import SearchDetailHeaderContainer from '../../components/screen/headers/containers/SearchDetailHeaderContainer'
import FilterDetailHeader from '../../components/screen/headers/FilterDetailHeader'
import ServiceHeader from '../../components/screen/headers/ServiceHeader'
import { Screen } from '../../components/screen/Screen'
import FilterContext from '../../contexts/FilterContext'
import { TicketStackScreenProps } from '../../navigation/navigators/bottomTabs/TicketBottomTabNavigator'
import { ThemeColorExpanded } from '../../types'
import { ticketFilterEnableAtom, ticketListSplitViewEnabledAtom } from '../../utils/stateManager'
import TicketView from '../../views/ticket/TicketView'

export default function TicketsScreen({ navigation }: TicketStackScreenProps<'Tickets'>) {
  const { isSmallDevice } = useDimensions()
  const { sessionKey } = useAuthentication()
  const { theme } = useTheme<ThemeColorExpanded>()

  const enabled = useRecoilValue(ticketFilterEnableAtom)
  const [splitViewEnabled, setSplitViewEnabled] = useRecoilState(ticketListSplitViewEnabledAtom(sessionKey))

  const handleSDNavigation = () => {
    navigation.navigate('TicketModalCommonStack', { screen: 'Filter' })
  }

  return (
    <SearchProvider>
      <FilterContext.Consumer>
        {context => {
          let activeFilters = false
          context?.filters.forEach(filter =>
            filter.elements.forEach(el => {
              if (el.active) activeFilters = true
            })
          )
          const activeGroups = context?.groups.filter(el => el.active)
          const activeOrder = context?.orders.filter(el => el.active)

          return (
            <MasterDetailProvider forceSmallDevice={!splitViewEnabled}>
              <Screen>
                <Screen.Header>
                  <ServiceHeader
                    enabledOffline
                    tabletSearchComponent={<SearchDetailHeaderContainer enabledOffline dividers={['right']} enableQR />}
                    tabletFilterComponent={
                      <FilterDetailHeaderContainer>
                        <FilterDetailHeader enabled={enabled} onSDNavigation={handleSDNavigation} />
                      </FilterDetailHeaderContainer>
                    }
                    highlightFilter={activeFilters || activeGroups?.length !== 0 || activeOrder?.length !== 0}
                    layoutIcon={
                      isSmallDevice
                        ? undefined
                        : {
                            color: splitViewEnabled ? theme.general.info : IMStyle.palette.white,
                            onPress: () => setSplitViewEnabled(!splitViewEnabled),
                          }
                    }
                  />
                </Screen.Header>
                <Screen.DetailHeader>
                  <SearchDetailHeaderContainer enableQR />
                  <FilterDetailHeaderContainer>
                    <FilterDetailHeader enabled={enabled} onSDNavigation={handleSDNavigation} />
                  </FilterDetailHeaderContainer>
                </Screen.DetailHeader>
                <Screen.Content>
                  <TicketView splitViewEnabled={splitViewEnabled} />
                </Screen.Content>
              </Screen>
            </MasterDetailProvider>
          )
        }}
      </FilterContext.Consumer>
    </SearchProvider>
  )
}
