import { useTheme } from '@infominds/react-native-components'
import React from 'react'
import { Animated } from 'react-native'

import { SearchProvider } from '../../components/screen/contexts/SearchContext'
import SearchDetailHeaderContainer from '../../components/screen/headers/containers/SearchDetailHeaderContainer'
import HeaderWithIconsSync from '../../components/screen/headers/HeaderWithIconsSync'
import { Screen } from '../../components/screen/Screen'
import { CustomerStackScreenProps } from '../../navigation/navigators/common/CustomerCommonNavigators'
import { ThemeColorExpanded } from '../../types'
import CustomerDetailsView from '../../views/customer/CustomerDetailsView'

export default function CustomerDetailsScreen({ route }: CustomerStackScreenProps<'CustomerDetail'>) {
  const buttonAnimationValue = new Animated.Value(0)
  const { theme } = useTheme<ThemeColorExpanded>()

  return (
    <SearchProvider>
      <Screen>
        <Screen.Header goBack>
          <HeaderWithIconsSync
            enabledOffline
            title={route.params.customerName + (route.params.customerNumber ? ` (${route.params.customerNumber})` : '')}
            icon={route.params.blocked === 'true' ? ['fas', 'user-slash'] : undefined}
            iconSize={20}
            iconColor={theme.general.error}
            description={route.params.vat}
            hideFilter
            tabletSearchComponent={<SearchDetailHeaderContainer enabledOffline iconPosition="right" />}
          />
        </Screen.Header>
        <Screen.DetailHeader>
          <SearchDetailHeaderContainer />
        </Screen.DetailHeader>
        <Screen.Content>
          <CustomerDetailsView customerId={route.params.customerId} buttonAnimationValue={buttonAnimationValue} />
        </Screen.Content>
      </Screen>
    </SearchProvider>
  )
}
