import { useLanguage } from '@infominds/react-native-components'
import React, { createRef, ForwardedRef, forwardRef, useImperativeHandle, useState } from 'react'
import { Keyboard } from 'react-native'

import EditOrCreateHeader from '../../components/screen/headers/EditOrCreateHeader'
import { Screen } from '../../components/screen/Screen'
import { FormProvider } from '../../contexts/FormContext'
import useIsOnline from '../../dataProvider/hooks/useIsOnline'
import useEditOrCreateScreenBackManager from '../../hooks/useEditOrCreateScreenBackManager'
import { CustomerStackScreenProps } from '../../navigation/navigators/common/CustomerCommonNavigators'
import { DestinationEditOrCreateParams, EditOrCreateViewRef, ModalScreenProps, ModalScreenRef, UploadStatus } from '../../types'
import DestinationEditOrCreateView from '../../views/customer/DestinationEditOrCreateView'

const CustomerDetailDestinationEditOrCreateScreen = (
  {
    route,
    controller,
  }: Pick<CustomerStackScreenProps<'CustomerDetailDestinationEditOrCreate'>, 'route'> & ModalScreenProps<DestinationEditOrCreateParams>,
  ref: ForwardedRef<ModalScreenRef>
) => {
  useImperativeHandle(ref, () => ({
    handleGoBack: handleGoBack,
  }))

  const mode = route.params.destination === undefined ? 'creation' : 'edit'

  const editOrCreateViewRef = createRef<EditOrCreateViewRef>()
  const isOnline = useIsOnline()

  const [editEnabled, setEditEnabled] = useState(mode === 'creation' ? true : false)

  const { i18n } = useLanguage()
  const { status, setStatus, handleGoBack } = useEditOrCreateScreenBackManager({
    title: i18n.t('UNSAVED_CHANGES_TITLE'),
    description: i18n.t('DISCARD_UNSAVED_CHANGES'),
    controller,
  })

  const common = {
    ref: editOrCreateViewRef,
    disabled: !editEnabled,
    onUploadStatus: (newStatus: UploadStatus) => setStatus(newStatus),
    onDone: handleGoBack,
  }

  const isModal = controller !== undefined

  return (
    <Screen transparent={isModal}>
      <Screen.Header
        goBack={handleGoBack}
        backHandlerCallback={handleGoBack}
        isModal={isModal}
        onRightIconPress={
          !editEnabled
            ? undefined
            : () => {
                Keyboard.dismiss()
                editOrCreateViewRef.current?.handleUpload()
              }
        }
        rightIcon={!editEnabled ? undefined : ['fal', 'check']}
        disable={status === 'done' || status === 'mandatoryMissing'}
        loading={status === 'uploading'}>
        <EditOrCreateHeader
          title={!editEnabled ? i18n.t('DESTINATION') : mode === 'edit' ? i18n.t('EDIT_DESTINATION') : i18n.t('CREATE_DESTINATION')}
          icon={['fal', 'pen-field']}
          iconSize={20}
          showButton={!editEnabled && isOnline}
          onPress={() => setEditEnabled(true)}
        />
      </Screen.Header>
      <Screen.Content>
        <FormProvider>
          {mode === 'edit' ? (
            <>{route.params.destination && <DestinationEditOrCreateView mode="edit" destination={route.params.destination} {...common} />}</>
          ) : (
            <DestinationEditOrCreateView mode="creation" customerId={route.params.customerId} {...common} />
          )}
        </FormProvider>
      </Screen.Content>
    </Screen>
  )
}

export default forwardRef(CustomerDetailDestinationEditOrCreateScreen)
