import { StringUtils, useAlert, useEvent, useLanguage } from '@infominds/react-native-components'
import { useAuthentication } from '@infominds/react-native-license'
import { assetManagementUtils, AssetOrigin, AssetView } from '@infominds/react-native-media'
import { NavigationProp, useNavigation } from '@react-navigation/native'
import React, { useState } from 'react'
import { Platform } from 'react-native'

import api from '../../apis/apiCalls'
import { REFRESH_INFOBOX_EVENT_KEY } from '../../constants/EmitterKeys'
import { InfoboxCommonStackParamList } from '../../navigation/navigators/common/InfoboxCommonNavigators'
import { InfoboxType } from '../../types'
import { utils } from '../../utils/utils'

interface Props {
  id: string
  infoboxType: InfoboxType
  origin: AssetOrigin | undefined
}

let abortController: AbortController | undefined

const InfoboxAssetView = ({ id, infoboxType, origin }: Props) => {
  const { alert } = useAlert()
  const { i18n } = useLanguage()
  const { url, bearerToken } = useAuthentication()
  const navigation = useNavigation<NavigationProp<InfoboxCommonStackParamList>>()

  const [error, setError] = useState(false)
  const [uuid, setUuid] = useState(utils.generateUuid())

  useEvent({ key: REFRESH_INFOBOX_EVENT_KEY }, () => {
    setUuid(utils.generateUuid())
  })

  const fetchHighResolutionAsset = (idSelected: string) => {
    if (abortController) {
      abortController.abort()
    }

    setError(false)
    abortController = new AbortController()

    return new Promise<{ path?: string; base64?: string }>((resolve, reject) => {
      api
        .getInfoboxFile({ id: idSelected, infoboxTyp: infoboxType })
        .then(result => {
          if (!result) {
            reject('no data')
            return
          }
          if (Platform.OS === 'web') {
            resolve({ base64: result.data })
          } else {
            const hrPath = assetManagementUtils.getCacheDir() + `/highRes/${result.filename}`
            assetManagementUtils
              .writeFile(hrPath, result.data, 'base64')
              .then(() => {
                resolve({ path: hrPath })
              })
              .catch(err => {
                setError(true)
                alert(i18n.t('ERROR'), StringUtils.stringValueReplacer(i18n.t('ERROR_SAVING_FILE'), hrPath, err))
                reject(err)
              })
          }
        })
        .catch(err => {
          setError(true)
          alert(i18n.t('ERROR'), StringUtils.stringValueReplacer(i18n.t('ERROR_FETCH_ASSET'), err))
          reject(err)
        })
    })
  }

  if (!url || !bearerToken) return <></>

  return (
    <AssetView
      key={uuid}
      id={id}
      disableInfoButton={error}
      onGoBack={() => navigation.goBack()}
      onAssetInfo={() => navigation.navigate('InfoboxNoBottomTabAssetInfo', { id, infoboxType, origin })}
      fetchHighResAsset={fetchHighResolutionAsset}
      assetFetch={{
        headers: { Authorization: bearerToken },
        url: assetId => {
          const completeUrl = `${url}/api/common/infoboxfileonly?infoboxTyp=${infoboxType}&infoboxFileId=${assetId}`
          return completeUrl
        },
      }}
    />
  )
}

export default InfoboxAssetView
