import { IM, IMLayout, IMStyle, SpacingProps, useDimensions, useEvent, useTheme } from '@infominds/react-native-components'
import { FlashList, ListRenderItemInfo, FlashListProps as SPFlashListProps } from '@shopify/flash-list'
import React, { ForwardedRef, forwardRef, ReactNode, useCallback, useMemo } from 'react'
import { Keyboard, NativeScrollEvent, NativeSyntheticEvent, Platform } from 'react-native'

import { SECTION_LIST_CLOSE_TO_END_EVENT_KEY } from '../constants/EmitterKeys'
import { InfiniteLoadingType } from '../types'
import appUtils from '../utils/appUtils'
import FlashListFooter from './FlashListFooter'
import FlashListHeader from './FlashListHeader'
import { AnimatedButtonHideEvent } from './Infominds/AnimatedButton'
import RefreshControl from './Infominds/RefreshControl'

type FlashListProps<T> = Omit<
  SPFlashListProps<T>,
  | 'ref'
  | 'onRefresh'
  | 'style'
  | 'estimatedItemSize'
  | 'scrollEnabled'
  | 'ListHeaderComponent'
  | 'ListFooterComponent'
  | 'refreshControl'
  | 'onEndReachedThreshold'
  | 'onEndReached'
  | 'getItemType'
  | 'stickyHeaderIndices'
  | 'renderItem'
> & {
  noDataMessage: string
  isSearching?: boolean
  loading?: InfiniteLoadingType
  spacingSkeleton?: SpacingProps
  closeEndThreshold?: number
  hideButtonId?: string
  allDataLoaded?: boolean
  marginTop?: number
  footer?: ReactNode
  refresh?: () => void
  renderItem: (item: ListRenderItemInfo<T>) => ReactNode
  onLoadMore?: () => void
}

function List<T>(
  {
    noDataMessage,
    loading = false,
    isSearching = false,
    marginTop,
    closeEndThreshold,
    hideButtonId,
    spacingSkeleton,
    allDataLoaded,
    footer,
    refresh,
    onLoadMore,
    renderItem,
    ...others
  }: FlashListProps<T>,
  ref: ForwardedRef<FlashList<T>>
) {
  const { theme } = useTheme()
  const { isSmallDevice } = useDimensions()
  const { emit } = useEvent<AnimatedButtonHideEvent>({ key: SECTION_LIST_CLOSE_TO_END_EVENT_KEY })

  const handleScroll = (e: NativeSyntheticEvent<NativeScrollEvent>) => {
    Platform.OS === 'ios' && Keyboard.isVisible() && Keyboard.dismiss()
    others.onScroll?.(e)

    if (closeEndThreshold === undefined) return

    if (appUtils.closeToEndDetector(e, closeEndThreshold, loading === false && allDataLoaded !== false)) {
      emit({ hide: true, id: hideButtonId ?? '' })
    } else {
      emit({ hide: false, id: hideButtonId ?? '' })
    }
  }

  const stickyIndex = useMemo(
    () => others.data?.map((el, index) => (typeof el === 'string' ? index : undefined)).filter(el => el !== undefined) as number[],
    [others.data]
  )

  const render = useCallback(
    (props: ListRenderItemInfo<T>) => {
      const isLastElementOfSection = stickyIndex.includes(props.index + 1)

      if (typeof props.item === 'string') {
        return (
          <IM.View
            style={{
              paddingTop: IMLayout.horizontalMargin,
              paddingBottom: IMLayout.horizontalMargin / 2,
              paddingHorizontal: 2 * IMLayout.horizontalMargin,
              backgroundColor: theme.background.default,
            }}>
            <IM.Text style={{ fontWeight: IMStyle.typography.fontWeightMedium }}>{props.item}</IM.Text>
          </IM.View>
        )
      }

      return (
        <IM.View style={{ paddingBottom: isLastElementOfSection ? 2 * IMLayout.horizontalMargin : undefined }}>{renderItem({ ...props })}</IM.View>
      )
    },
    [theme, stickyIndex]
  )

  return (
    <IM.View style={IMLayout.flex.f1}>
      <FlashList
        {...others}
        ref={ref}
        estimatedItemSize={isSmallDevice ? 200 : 100}
        scrollEnabled={loading !== 'reloading'}
        ListHeaderComponent={
          <FlashListHeader
            dataLength={others.data?.length}
            noDataMessage={noDataMessage}
            loading={loading}
            spacingSkeleton={spacingSkeleton}
            marginTop={marginTop}
          />
        }
        ListFooterComponent={
          <FlashListFooter loading={loading} spacingSkeleton={spacingSkeleton} allDataLoaded={allDataLoaded}>
            {footer}
          </FlashListFooter>
        }
        refreshControl={!isSearching ? refresh ? <RefreshControl refreshing={false} onRefresh={refresh} /> : undefined : undefined}
        onScroll={handleScroll}
        onEndReachedThreshold={0.5}
        onEndReached={onLoadMore}
        getItemType={item => {
          // To achieve better performance, specify the type based on the item
          return typeof item === 'string' ? 'sectionHeader' : 'row'
        }}
        stickyHeaderIndices={stickyIndex}
        renderItem={render}
      />
    </IM.View>
  )
}

const FlashListData = forwardRef(List) as <T>(props: FlashListProps<T> & { ref?: ForwardedRef<FlashList<T>> }) => ReturnType<typeof List>

export default FlashListData
