import { CompositeScreenProps, NavigatorScreenParams } from '@react-navigation/native'
import { createNativeStackNavigator, NativeStackScreenProps } from '@react-navigation/native-stack'
import React from 'react'

import CustomersListScreen from '../../../screens/passwords/CustomersListScreen'
import { screenOptions } from '../../Navigator'
import { AppBottomTabScreenProps, BottomTabParamList } from '../../types'
import { AccessDataCommonStackNames, AccessDataCommonStackNavigator, AccessDataCommonStackParamList } from '../common/AccessDataCommonNavigator'

export type PasswordsStackParamList = {
  PasswordCustomersList: undefined
  PasswordAccessDataCommonStack: NavigatorScreenParams<AccessDataCommonStackParamList>
}

export const PasswordStackNames: {
  path?: string
  screens: Record<keyof PasswordsStackParamList, string | typeof AccessDataCommonStackNames>
} = {
  path: 'password',
  screens: {
    PasswordCustomersList: 'list',
    PasswordAccessDataCommonStack: AccessDataCommonStackNames,
  },
}

export type PasswordStackScreenProps<T extends keyof PasswordsStackParamList> = CompositeScreenProps<
  NativeStackScreenProps<PasswordsStackParamList, T>,
  AppBottomTabScreenProps<keyof BottomTabParamList>
>

const PasswordsStack = createNativeStackNavigator<PasswordsStackParamList>()
export function PasswordsStackNavigator() {
  return (
    <PasswordsStack.Navigator screenOptions={screenOptions}>
      <PasswordsStack.Screen name="PasswordCustomersList" component={CustomersListScreen} />
      <PasswordsStack.Screen name="PasswordAccessDataCommonStack" component={AccessDataCommonStackNavigator} />
    </PasswordsStack.Navigator>
  )
}
