import { IMStyle } from '@infominds/react-native-components'
import { Checkbox as AntCheckbox, ConfigProvider } from 'antd'
import React, { memo } from 'react'

import { CheckboxProps } from './Checkbox'

const Checkbox = memo(({ checked }: CheckboxProps) => {
  return (
    <ConfigProvider theme={{ token: { colorPrimary: IMStyle.palette.tint } }}>
      {/* eslint-disable-next-line react-native/no-inline-styles */}
      <AntCheckbox checked={checked} style={{ lineHeight: 1 }} />
    </ConfigProvider>
  )
})

export default Checkbox
