import { IM, IMLayout, IMStyle } from '@infominds/react-native-components'
import { NavigationProp, useNavigation } from '@react-navigation/native'
import React, { useMemo } from 'react'
import { Image, StyleSheet } from 'react-native'

import { TicketArticle } from '../apis/types/apiResponseTypes'
import useLayout, { ForceLayoutType } from '../hooks/useLayout'
import { TicketDetailCommonStackParamList } from '../navigation/navigators/common/TicketDetailCommonNavigator'
import ticketUtils from '../utils/TicketUtils'
import ContractLoader from './ContractLoader'
import DynamicView from './Infominds/DynamicView'
import Pressable from './Infominds/Pressable'
import Separator from './Infominds/Separator'
import TextWithIcon from './Infominds/TextWithIcon'
import MonitoringState from './MonitoringState'
import NavigationIcon from './NavigationIcon'
import WarrantyLoader from './WarrantyLoader'

interface Props {
  index: number
  ticketId: string
  activityId: string | undefined
  article: TicketArticle
  onlyRead?: boolean
  forceLayout: ForceLayoutType | undefined
}

export default function TicketArticleComponent({ ticketId, activityId, article, index, onlyRead, forceLayout }: Props) {
  const { isSmallDevice } = useLayout(false, forceLayout)
  const navigation = useNavigation<NavigationProp<TicketDetailCommonStackParamList>>()

  const imageMarginRight = isSmallDevice ? IMLayout.horizontalMargin * 2 : IMLayout.horizontalMargin * 4
  const activeContract = useMemo(() => ticketUtils.getActiveContract(article.contracts), [article])
  const activeWarranty = useMemo(
    () => article.serialnumberCustomerWarrantyEnd !== undefined && article.serialnumberOutgoingDate !== undefined,
    [article]
  )
  const activeMonitoring = useMemo(() => article.serialnumberMonitoring !== 'None', [article])

  const articleImage = (uri: string) => (
    <IM.View style={{ marginRight: imageMarginRight }}>
      <Image source={{ uri: `data:image/png;base64,${uri}` }} style={styles.image} />
    </IM.View>
  )

  const articleSerialNumber = () =>
    article.serialnumber && (
      <TextWithIcon
        icon={['fal', 'barcode']}
        style={[IMLayout.flex.f1, { fontWeight: IMStyle.typography.fontWeightMedium }]}
        // eslint-disable-next-line react-native/no-inline-styles
        viewStyle={{ marginBottom: isSmallDevice ? 4 : 0, marginRight: isSmallDevice ? 0 : 4 }}>
        {article.serialnumber + (article.serialnumberNumberManufactor ? ` (${article.serialnumberNumberManufactor})` : '')}
      </TextWithIcon>
    )

  const articleName = () =>
    article.articleDescription && (
      <TextWithIcon
        icon={['fal', 'cubes']}
        style={[IMLayout.flex.f1]}
        // eslint-disable-next-line react-native/no-inline-styles
        viewStyle={{ marginBottom: article.serialnumberLocation ? 4 : 0 }}
        numberOfLines={3}>
        {article.articleDescription}
      </TextWithIcon>
    )

  const articleLocation = () =>
    article.serialnumberLocation && (
      <TextWithIcon icon={['fal', 'location-dot']} style={IMLayout.flex.f1}>
        {article.serialnumberLocation}
      </TextWithIcon>
    )

  const contractLoader = () => {
    return <>{activeContract && <ContractLoader icon={['fal', 'file-signature']} contract={activeContract} />}</>
  }

  const warrantyLoader = () => {
    return (
      <>
        {activeWarranty && (
          <WarrantyLoader
            icon={['fal', 'shield-check']}
            // @ts-ignore already checked
            startDate={article.serialnumberOutgoingDate}
            // @ts-ignore already checked
            endDate={article.serialnumberCustomerWarrantyEnd}
          />
        )}
      </>
    )
  }

  const monitoringState = () => {
    return <>{article.serialnumberMonitoring !== 'None' && <MonitoringState state={article.serialnumberMonitoringState} />}</>
  }

  return (
    <>
      {index !== 0 && <Separator spacing="none" />}
      <Pressable
        style={styles.container}
        disabled={onlyRead}
        onPress={() =>
          navigation.navigate('TicketArticle', {
            ticketId,
            activityId,
            articleId: article.id,
            serialNumber: article.serialnumber,
            serialNumberManufactor: article.serialnumberNumberManufactor,
          })
        }>
        <IM.View style={IMLayout.flex.f1}>
          {isSmallDevice ? (
            <>
              <IM.View style={[IMLayout.flex.f1, IMLayout.flex.row]}>
                {article.thumbImageAsPNG && articleImage(article.thumbImageAsPNG)}
                <IM.View style={[IMLayout.flex.f1, styles.infoContainer]}>
                  {articleSerialNumber()}
                  {articleName()}
                  {articleLocation()}
                </IM.View>
                {!onlyRead && <NavigationIcon />}
              </IM.View>
              {(activeContract || activeWarranty || activeMonitoring) && (
                <DynamicView style={styles.infoContainer} spacing="top">
                  <IM.View style={IMLayout.flex.f2} spacing="right">
                    {contractLoader()}
                  </IM.View>
                  <IM.View style={IMLayout.flex.f2} spacing="horizontal">
                    {warrantyLoader()}
                  </IM.View>
                  <IM.View style={IMLayout.flex.f1} spacing="left">
                    {monitoringState()}
                  </IM.View>
                </DynamicView>
              )}
            </>
          ) : (
            <DynamicView style={IMLayout.flex.f1} forceLayout={forceLayout}>
              <IM.View style={[IMLayout.flex.f2, styles.justify]}>{articleSerialNumber()}</IM.View>
              <IM.View style={[styles.justify, { width: styles.image.width, marginRight: imageMarginRight }]} spacing="horizontal">
                {article.thumbImageAsPNG && articleImage(article.thumbImageAsPNG)}
              </IM.View>
              <IM.View style={[IMLayout.flex.f4, styles.justify, { marginRight: IMLayout.horizontalMargin }]}>
                {articleName()}
                {articleLocation()}
              </IM.View>
              <IM.View style={[IMLayout.flex.f1, styles.justify]}>
                <IM.View spacing="bottom">{contractLoader()}</IM.View>
                {warrantyLoader()}
              </IM.View>
              <IM.View style={[styles.justify, styles.monitoringState, { marginLeft: IMLayout.horizontalMargin }]}>{monitoringState()}</IM.View>
              {!onlyRead && <NavigationIcon style={styles.justify} />}
            </DynamicView>
          )}
        </IM.View>
      </Pressable>
    </>
  )
}

const styles = StyleSheet.create({
  container: { flexDirection: 'row', flex: 1 },
  infoContainer: { marginRight: 4 },
  image: {
    width: 50,
    height: 50,
    resizeMode: 'contain',
    borderRadius: 4,
  },
  justify: { justifyContent: 'center' },
  monitoringState: { width: 55 },
})
