import { library } from '@fortawesome/fontawesome-svg-core'
import { fa00 } from '@fortawesome/pro-light-svg-icons/fa00'
import { faAddressBook } from '@fortawesome/pro-light-svg-icons/faAddressBook'
import { faAnglesLeft } from '@fortawesome/pro-light-svg-icons/faAnglesLeft'
import { faAnglesRight } from '@fortawesome/pro-light-svg-icons/faAnglesRight'
import { faArrowDown } from '@fortawesome/pro-light-svg-icons/faArrowDown'
import { faArrowDown19 } from '@fortawesome/pro-light-svg-icons/faArrowDown19'
import { faArrowDownArrowUp } from '@fortawesome/pro-light-svg-icons/faArrowDownArrowUp'
import { faArrowDownAZ } from '@fortawesome/pro-light-svg-icons/faArrowDownAZ'
import { faArrowDownToBracket } from '@fortawesome/pro-light-svg-icons/faArrowDownToBracket'
// WARNING: Import fontAwesome icons like these to prevent the build time to increase. https://fontawesome.com/docs/web/use-with/react-native
import { faArrowLeft } from '@fortawesome/pro-light-svg-icons/faArrowLeft'
import { faArrowRight } from '@fortawesome/pro-light-svg-icons/faArrowRight'
import { faArrowsFromLine } from '@fortawesome/pro-light-svg-icons/faArrowsFromLine'
import { faArrowsRepeat } from '@fortawesome/pro-light-svg-icons/faArrowsRepeat'
import { faArrowsRotate } from '@fortawesome/pro-light-svg-icons/faArrowsRotate'
import { faArrowUp } from '@fortawesome/pro-light-svg-icons/faArrowUp'
import { faArrowUp19 } from '@fortawesome/pro-light-svg-icons/faArrowUp19'
import { faArrowUpArrowDown } from '@fortawesome/pro-light-svg-icons/faArrowUpArrowDown'
import { faArrowUpAZ } from '@fortawesome/pro-light-svg-icons/faArrowUpAZ'
import { faArrowUpRightFromSquare } from '@fortawesome/pro-light-svg-icons/faArrowUpRightFromSquare'
import { faBadgeCheck } from '@fortawesome/pro-light-svg-icons/faBadgeCheck'
import { faBarcode } from '@fortawesome/pro-light-svg-icons/faBarcode'
import { faBars } from '@fortawesome/pro-light-svg-icons/faBars'
import { faBinarySlash } from '@fortawesome/pro-light-svg-icons/faBinarySlash'
import { faBox } from '@fortawesome/pro-light-svg-icons/faBox'
import { faBuilding } from '@fortawesome/pro-light-svg-icons/faBuilding'
import { faBusinessTime } from '@fortawesome/pro-light-svg-icons/faBusinessTime'
import { faCalendar } from '@fortawesome/pro-light-svg-icons/faCalendar'
import { faCalendarArrowDown } from '@fortawesome/pro-light-svg-icons/faCalendarArrowDown'
import { faCalendarArrowUp } from '@fortawesome/pro-light-svg-icons/faCalendarArrowUp'
import { faCalendarClock } from '@fortawesome/pro-light-svg-icons/faCalendarClock'
import { faCalendarDay } from '@fortawesome/pro-light-svg-icons/faCalendarDay'
import { faCalendarPlus } from '@fortawesome/pro-light-svg-icons/faCalendarPlus'
import { faCalendarRange } from '@fortawesome/pro-light-svg-icons/faCalendarRange'
import { faCalendarWeek } from '@fortawesome/pro-light-svg-icons/faCalendarWeek'
import { faCartMinus } from '@fortawesome/pro-light-svg-icons/faCartMinus'
import { faCartPlus } from '@fortawesome/pro-light-svg-icons/faCartPlus'
import { faCarWrench } from '@fortawesome/pro-light-svg-icons/faCarWrench'
import { faChartGantt } from '@fortawesome/pro-light-svg-icons/faChartGantt'
import { faCheck } from '@fortawesome/pro-light-svg-icons/faCheck'
import { faChevronLeft } from '@fortawesome/pro-light-svg-icons/faChevronLeft'
import { faChevronRight } from '@fortawesome/pro-light-svg-icons/faChevronRight'
import { faChevronUp } from '@fortawesome/pro-light-svg-icons/faChevronUp'
import { faCircleInfo } from '@fortawesome/pro-light-svg-icons/faCircleInfo'
import { faCircleNotch } from '@fortawesome/pro-light-svg-icons/faCircleNotch'
import { faClock } from '@fortawesome/pro-light-svg-icons/faClock'
import { faClockRotateLeft } from '@fortawesome/pro-light-svg-icons/faClockRotateLeft'
import { faCloudArrowDown } from '@fortawesome/pro-light-svg-icons/faCloudArrowDown'
import { faCloudArrowUp } from '@fortawesome/pro-light-svg-icons/faCloudArrowUp'
import { faCloudCheck } from '@fortawesome/pro-light-svg-icons/faCloudCheck'
import { faCloudExclamation } from '@fortawesome/pro-light-svg-icons/faCloudExclamation'
import { faCloudSlash } from '@fortawesome/pro-light-svg-icons/faCloudSlash'
import { faCopy } from '@fortawesome/pro-light-svg-icons/faCopy'
import { faCrosshairs } from '@fortawesome/pro-light-svg-icons/faCrosshairs'
import { faCubes } from '@fortawesome/pro-light-svg-icons/faCubes'
import { faDisplay } from '@fortawesome/pro-light-svg-icons/faDisplay'
import { faDisplayChartUp } from '@fortawesome/pro-light-svg-icons/faDisplayChartUp'
import { faEllipsisVertical } from '@fortawesome/pro-light-svg-icons/faEllipsisVertical'
import { faEnvelope } from '@fortawesome/pro-light-svg-icons/faEnvelope'
import { faEuroSign } from '@fortawesome/pro-light-svg-icons/faEuroSign'
import { faEye } from '@fortawesome/pro-light-svg-icons/faEye'
import { faEyeSlash } from '@fortawesome/pro-light-svg-icons/faEyeSlash'
import { faFile } from '@fortawesome/pro-light-svg-icons/faFile'
import { faFileCircleMinus } from '@fortawesome/pro-light-svg-icons/faFileCircleMinus'
import { faFileCirclePlus } from '@fortawesome/pro-light-svg-icons/faFileCirclePlus'
import { faFileDoc } from '@fortawesome/pro-light-svg-icons/faFileDoc'
import { faFileExcel } from '@fortawesome/pro-light-svg-icons/faFileExcel'
import { faFilePen } from '@fortawesome/pro-light-svg-icons/faFilePen'
import { faFilePrescription } from '@fortawesome/pro-light-svg-icons/faFilePrescription'
import { faFileSignature } from '@fortawesome/pro-light-svg-icons/faFileSignature'
import { faFileSlash } from '@fortawesome/pro-light-svg-icons/faFileSlash'
import { faFilter } from '@fortawesome/pro-light-svg-icons/faFilter'
import { faFloppyDisk } from '@fortawesome/pro-light-svg-icons/faFloppyDisk'
import { faFolder } from '@fortawesome/pro-light-svg-icons/faFolder'
import { faFolderOpen } from '@fortawesome/pro-light-svg-icons/faFolderOpen'
import { faFunction } from '@fortawesome/pro-light-svg-icons/faFunction'
import { faGear } from '@fortawesome/pro-light-svg-icons/faGear'
import { faGlobe } from '@fortawesome/pro-light-svg-icons/faGlobe'
import { faGripLines } from '@fortawesome/pro-light-svg-icons/faGripLines'
import { faHeadset } from '@fortawesome/pro-light-svg-icons/faHeadset'
import { faHome } from '@fortawesome/pro-light-svg-icons/faHome'
import { faIdBadge } from '@fortawesome/pro-light-svg-icons/faIdBadge'
import { faImage } from '@fortawesome/pro-light-svg-icons/faImage'
import { faImageSlash } from '@fortawesome/pro-light-svg-icons/faImageSlash'
import { faLayerGroup } from '@fortawesome/pro-light-svg-icons/faLayerGroup'
import { faList } from '@fortawesome/pro-light-svg-icons/faList'
import { faLocationDot } from '@fortawesome/pro-light-svg-icons/faLocationDot'
import { faLocationDotSlash } from '@fortawesome/pro-light-svg-icons/faLocationDotSlash'
import { faLock } from '@fortawesome/pro-light-svg-icons/faLock'
import { faMagnifyingGlass } from '@fortawesome/pro-light-svg-icons/faMagnifyingGlass'
import { faMapLocationDot } from '@fortawesome/pro-light-svg-icons/faMapLocationDot'
import { faMessageDots } from '@fortawesome/pro-light-svg-icons/faMessageDots'
import { faMinus } from '@fortawesome/pro-light-svg-icons/faMinus'
import { faNotes } from '@fortawesome/pro-light-svg-icons/faNotes'
import { faNoteSticky } from '@fortawesome/pro-light-svg-icons/faNoteSticky'
import { faPaperPlane } from '@fortawesome/pro-light-svg-icons/faPaperPlane'
import { faPen } from '@fortawesome/pro-light-svg-icons/faPen'
import { faPenField } from '@fortawesome/pro-light-svg-icons/faPenField'
import { faPersonToDoor } from '@fortawesome/pro-light-svg-icons/faPersonToDoor'
import { faPhone } from '@fortawesome/pro-light-svg-icons/faPhone'
import { faPhoneOffice } from '@fortawesome/pro-light-svg-icons/faPhoneOffice'
import { faPhotoFilmMusic } from '@fortawesome/pro-light-svg-icons/faPhotoFilmMusic'
import { faPlay } from '@fortawesome/pro-light-svg-icons/faPlay'
import { faPlus } from '@fortawesome/pro-light-svg-icons/faPlus'
import { faQrcode } from '@fortawesome/pro-light-svg-icons/faQrcode'
import { faRepeat } from '@fortawesome/pro-light-svg-icons/faRepeat'
import { faRoute } from '@fortawesome/pro-light-svg-icons/faRoute'
import { faScrewdriverWrench } from '@fortawesome/pro-light-svg-icons/faScrewdriverWrench'
import { faShieldCheck } from '@fortawesome/pro-light-svg-icons/faShieldCheck'
import { faSquareCheck } from '@fortawesome/pro-light-svg-icons/faSquareCheck'
import { faStop } from '@fortawesome/pro-light-svg-icons/faStop'
import { faStopwatch } from '@fortawesome/pro-light-svg-icons/faStopwatch'
import { faTableLayout } from '@fortawesome/pro-light-svg-icons/faTableLayout'
import { faTicket } from '@fortawesome/pro-light-svg-icons/faTicket'
import { faTrash } from '@fortawesome/pro-light-svg-icons/faTrash'
import { faTruckClock } from '@fortawesome/pro-light-svg-icons/faTruckClock'
import { faUser } from '@fortawesome/pro-light-svg-icons/faUser'
import { faUserPen } from '@fortawesome/pro-light-svg-icons/faUserPen'
import { faUserSlash } from '@fortawesome/pro-light-svg-icons/faUserSlash'
import { faWarehouseFull } from '@fortawesome/pro-light-svg-icons/faWarehouseFull'
import { faXmark } from '@fortawesome/pro-light-svg-icons/faXmark'
import { faCheck as RegularCheck } from '@fortawesome/pro-regular-svg-icons/faCheck'
import { faChevronDown } from '@fortawesome/pro-regular-svg-icons/faChevronDown'
import { faChevronUp as RegularChevronUp } from '@fortawesome/pro-regular-svg-icons/faChevronUp'
import { faStopwatch as RegularStopwatch } from '@fortawesome/pro-regular-svg-icons/faStopwatch'
import { faTruckClock as TruckClock } from '@fortawesome/pro-regular-svg-icons/faTruckClock'
import { faCheckCircle as faCheckCircleSolid } from '@fortawesome/pro-solid-svg-icons/faCheckCircle'
import { faCircleCheck as faCircleCheckSolid } from '@fortawesome/pro-solid-svg-icons/faCircleCheck'
import { faCircleExclamation as faCircleExclamationSolid } from '@fortawesome/pro-solid-svg-icons/faCircleExclamation'
import { faCircleMinus } from '@fortawesome/pro-solid-svg-icons/faCircleMinus'
import { faCircleXmark } from '@fortawesome/pro-solid-svg-icons/faCircleXmark'
import { faCloudSlash as SolidCloudSlash } from '@fortawesome/pro-solid-svg-icons/faCloudSlash'
import { faEnvelope as faEnvelopeSolid } from '@fortawesome/pro-solid-svg-icons/faEnvelope'
import { faLocationArrow } from '@fortawesome/pro-solid-svg-icons/faLocationArrow'
import { faLocationDot as SolidLocationDot } from '@fortawesome/pro-solid-svg-icons/faLocationDot'
import { faLock as SolidLock } from '@fortawesome/pro-solid-svg-icons/faLock'
import { faMinus as faSolidMinus } from '@fortawesome/pro-solid-svg-icons/faMinus'
import { faPhone as SolidPhone } from '@fortawesome/pro-solid-svg-icons/faPhone'
import { faPlus as faSolidPlus } from '@fortawesome/pro-solid-svg-icons/faPlus'
import { faTimesCircle as faTimesCircleSolid } from '@fortawesome/pro-solid-svg-icons/faTimesCircle'
import { faTriangleExclamation } from '@fortawesome/pro-solid-svg-icons/faTriangleExclamation'
import { faUnlock as SolidUnlock } from '@fortawesome/pro-solid-svg-icons/faUnlock'
import { faUserSlash as faSolidUserSlash } from '@fortawesome/pro-solid-svg-icons/faUserSlash'
import { DefaultTheme, IMStyle, InfomindsColors, ThemeType } from '@infominds/react-native-components'
import { DefaultInfoboxTheme, InfoboxThemeColorExpanded } from '@infominds/react-native-media'
import Color from 'color'
import React from 'react'

import { ThemeColorExpanded } from './types'

library.add(
  faCircleCheckSolid,
  faBusinessTime,
  faChartGantt,
  faCalendarPlus,
  faCalendarRange,
  faArrowUp19,
  faArrowDown19,
  faArrowDown,
  faArrowUp,
  faArrowDownAZ,
  faArrowUpAZ,
  faSolidUserSlash,
  faFloppyDisk,
  faArrowLeft,
  faPersonToDoor,
  faXmark,
  faHome,
  faMagnifyingGlass,
  faFilter,
  faCloudArrowDown,
  faCloudArrowUp,
  faCloudSlash,
  faCloudExclamation,
  faMapLocationDot,
  faTicket,
  faClockRotateLeft,
  faGear,
  faChevronRight,
  faBars,
  faAnglesRight,
  faAnglesLeft,
  faIdBadge,
  faLock,
  faArrowsRepeat,
  faBadgeCheck,
  faArrowDownArrowUp,
  faArrowsFromLine,
  faGripLines,
  faUserSlash,
  faUser,
  faBuilding,
  faQrcode,
  faBinarySlash,
  SolidLock,
  faBarcode,
  faArrowUpRightFromSquare,
  faEye,
  faEyeSlash,
  faCopy,
  SolidUnlock,
  faCircleNotch,
  faPlus,
  faCheck,
  faArrowsRotate,
  faTrash,
  fa00,
  faEnvelope,
  faFileSlash,
  faArrowRight,
  faPhoneOffice,
  faLocationDot,
  faStopwatch,
  faMessageDots,
  faHeadset,
  faDisplay,
  faCarWrench,
  faSquareCheck,
  faScrewdriverWrench,
  faGlobe,
  faCircleInfo,
  faCircleMinus,
  faCalendar,
  faAddressBook,
  faPhotoFilmMusic,
  faChevronLeft,
  faClock,
  faPhone,
  faUserPen,
  SolidPhone,
  SolidCloudSlash,
  faEnvelopeSolid,
  faLocationDotSlash,
  faRoute,
  faPenField,
  faFolder,
  faTriangleExclamation,
  faFolderOpen,
  faCrosshairs,
  faList,
  SolidLocationDot,
  faRepeat,
  faImage,
  faArrowDownToBracket,
  faFile,
  faFileDoc,
  faFileExcel,
  faCircleXmark,
  faChevronDown,
  faChevronUp,
  RegularCheck,
  faImageSlash,
  RegularChevronUp,
  faLocationArrow,
  faLayerGroup,
  faArrowUpArrowDown,
  faPen,
  faCubes,
  faFileSignature,
  faShieldCheck,
  faDisplayChartUp,
  faPaperPlane,
  faFunction,
  faNotes,
  faTruckClock,
  faPlay,
  faStop,
  faEllipsisVertical,
  faCircleExclamationSolid,
  faSolidMinus,
  faSolidPlus,
  faBox,
  faEuroSign,
  faCartPlus,
  faMinus,
  faCartMinus,
  faCalendarArrowDown,
  faCalendarArrowUp,
  RegularStopwatch,
  TruckClock,
  faCalendarDay,
  faCalendarWeek,
  faCalendarClock,
  faNoteSticky,
  faFilePrescription,
  faBars,
  faWarehouseFull,
  faCheckCircleSolid,
  faTimesCircleSolid,
  faCloudCheck,
  faFileCirclePlus,
  faFileCircleMinus,
  faFilePen,
  faTableLayout
)

const themeExpansion: ThemeType<ThemeColorExpanded & InfoboxThemeColorExpanded> = {
  light: {
    ...DefaultTheme.light,
    access: {
      denied: IMStyle.palette.red,
      granted: '#25D07C',
      inactive: '#a9a9a9',
    },
    article: {
      status: {
        green: '#25D07C',
        red: '#FF6161',
        yellow: '#FDCF57',
        grey: '#92918E',
      },
      image: {
        placeholder: '#8E8E8E',
      },
    },
    background: { default: '#F0F2F5', secondary: '' },
    button: {
      ...DefaultTheme.light.button,
      background: '#333B47',
      disabledBackground: '#BCBCBC',
      disabledIcon: IMStyle.palette.white,
      icon: IMStyle.palette.white,
      qualityCheck: '#8E98F6',
      tertiaryButton: '#989898',
    },
    card: {
      ...DefaultTheme.light.card,
      background: '#FFFFFF',
      cardBackground: {
        active: '#FFFFFF',
      },
      accent: {
        active: '#333B47',
      },
      button: {
        active: IMStyle.palette.tint,
        inactive: '#D3D3D3',
        icon: {
          active: 'white',
          inactive: '#8B9F95',
        },
      },
    },
    chip: {
      ...DefaultTheme.light.chip,
      border: IMStyle.palette.neutral,
      background: '#EBEBEB',
      active: {
        background: '#535F71',
        border: '#535F71',
        text: '#FFFFFF',
      },
      text: '#333B47',
      icon: {
        enabled: '#333B47',
        disabled: '#848484',
      },
      installation: IMStyle.palette.tint,
      removal: '#FF5858',
    },
    closing: {
      first: '#F5F5F5',
      second: '#EEEEEE',
      third: '#E6E6E6',
      fourth: '#DFDFDF',
    },
    drag: {
      active: '#e1e1e1',
    },
    dropdown: {
      arrow: '#4C4D4D',
    },
    general: {
      info: IMStyle.palette.tint,
      error: '#FF5858',
      warn: '#FFA758',
      image: '#7682F6',
      excel: '#389663',
      word: '#5186D7',
      pdf: '#B30C01',
      text: '#8E8E8E',
      blue: '#1A73E8',
    },
    header: {
      main: { background: '#333B47', error: '#FF5858', text: { primary: IMStyle.palette.white, secondary: '#C3C7C7' } },
      detail: { background: '#535F71', skeletonBackground: '#627085' },
    },
    infobox: {
      ...DefaultInfoboxTheme.light.infobox,
      disableButtonBackground: '#CBCBCB',
      disableButtonIcon: '#B4B4B4',
    },
    inputBox: {
      background: { active: '#fcfcfc', disabled: '#fafafa' },
      border: { active: '#D5D4DC', disabled: '#fafafa', error: '#FF5858' },
    },
    item: { selected: '#f2f2f2' },
    loader: {
      background: '#DEDEDE',
      border: '#D0CECE',
    },
    map: {
      marker: {
        background: '#333B47',
        highlight: IMStyle.palette.tint,
      },
    },
    moreSheet: {
      background: '#FFFFFF',
      navigationElement: { background: Color('#FFFFFF').darken(0.03).toString(), selected: Color(IMStyle.palette.tint).darken(0.07).toString() },
      indicator: 'gray',
    },
    pressable: {
      ...DefaultTheme.light.pressable,
      disabled: '#BFBFBF',
      highlight: '#F7F7F7',
      animatedHighlight: '#DFF2E8',
    },
    pressableIcon: {
      disabled: '#e7e7e7',
    },
    radioButton: IMStyle.palette.tint,
    settings: { badge: { background: '#E3E7EC' } },
    skeleton: {
      background: '#E5ECF0',
      highlight: '#DDE4E8',
    },
    tabNavigator: {
      ...DefaultTheme.light.tabNavigator,
      background: '#FFFFFF',
    },
    drawerNavigator: {
      background: '#535F71',
      bar: '#333B47',
      hover: '#535F71',
      icon: {
        focused: '#25D07C',
        unFocused: '#DDE4E8',
      },
    },
    timeline: {
      background: '#B3B9C2',
    },
    separator: '#E8E8E8',
    sync: {
      payloadBackground: '#F3F3F3',
    },
  },
  dark: {
    ...DefaultTheme.dark,
    access: {
      denied: IMStyle.palette.red,
      granted: '#25D07C',
      inactive: '#a9a9a9',
    },
    article: {
      status: {
        green: '#25D07C',
        red: '#FF6161',
        yellow: '#FDCF57',
        grey: '#92918E',
      },
      image: {
        placeholder: '#919192',
      },
    },
    background: { default: '#151617', secondary: '' },
    button: {
      ...DefaultTheme.light.button,
      background: '#424242',
      disabledBackground: '#898989',
      disabledIcon: IMStyle.palette.white,
      icon: IMStyle.palette.white,
      qualityCheck: '#7682F6',
      tertiaryButton: InfomindsColors.greyDarker,
    },
    card: {
      ...DefaultTheme.dark.card,
      background: '#272829',
      cardBackground: {
        active: '#242526',
      },
      accent: {
        active: '#424242',
      },
      button: {
        active: IMStyle.palette.tint, // Color(IMStyle.palette.tint).lighten(0.2).toString(),
        inactive: '#0B3F25',
        icon: {
          active: 'white',
          inactive: '#5C5C5C',
        },
      },
    },
    chip: {
      ...DefaultTheme.dark.chip,
      border: IMStyle.palette.neutral,
      background: '#7D7D7D',
      active: {
        background: '#7D7D7D',
        border: '#7D7D7D',
        text: '#333B47',
      },
      text: '#FFFFFF',
      icon: {
        enabled: '#333B47',
        disabled: '#9F9F9F',
      },
      installation: IMStyle.palette.tint,
      removal: '#FF5858',
    },
    closing: {
      first: '#1E1E1E',
      second: '#242424',
      third: '#2A2A2A',
      fourth: '#303030',
    },
    drag: {
      active: '#424242',
    },
    dropdown: {
      arrow: '#9CA2AD',
    },
    general: {
      info: IMStyle.palette.tint,
      error: '#FF5858',
      warn: '#FFA758',
      image: '#97A1F8',
      excel: '#389663',
      word: '#5186D7',
      pdf: '#F8372B',
      text: '#919192',
      blue: '#1A73E8',
    },
    header: {
      main: { background: '#424242', error: '#FF5858', text: { primary: IMStyle.palette.white, secondary: '#C3C7C7' } },
      detail: { background: '#202020', skeletonBackground: '#424242' },
    },
    infobox: {
      ...DefaultInfoboxTheme.dark.infobox,
      disableButtonBackground: '#252526',
      disableButtonIcon: '#535356',
    },
    inputBox: {
      background: { active: '#2f2f2f', disabled: '#222222' },
      border: { active: '#2f2f2f', disabled: '#222222', error: '#FF5858' },
    },
    item: { selected: '#343435' },
    loader: {
      background: '#454545',
      border: '#595959',
    },
    map: {
      marker: {
        background: InfomindsColors.textDetail,
        highlight: IMStyle.palette.tint,
      },
    },
    moreSheet: {
      background: '#252526',
      navigationElement: { background: '#333333', selected: Color(IMStyle.palette.tint).darken(0.25).toString() },
      indicator: 'gray',
    },
    pressable: {
      ...DefaultTheme.dark.pressable,
      disabled: '#3C3C3C',
      highlight: '#2B2B2C',
      animatedHighlight: '#23362C',
    },
    pressableIcon: {
      disabled: '#626262',
    },
    radioButton: IMStyle.palette.tint,
    settings: { badge: { background: '#1E2021' } },
    skeleton: {
      background: '#303233',
      highlight: '#424242',
    },
    tabNavigator: {
      ...DefaultTheme.dark.tabNavigator,
      background: '#242526',
    },
    drawerNavigator: {
      background: '#2B2C2E',
      bar: '#18191A',
      hover: '#242526',
      icon: {
        focused: '#25D07C',
        unFocused: '#D0D0D0',
      },
    },
    timeline: {
      background: '#676767',
    },
    separator: '#35312E',
    sync: {
      payloadBackground: '#2f2f2f',
    },
  },
}

export const themeWebExpansion: ThemeType<ThemeColorExpanded> = {
  ...themeExpansion,
  light: {
    ...themeExpansion.light,
    header: {
      ...themeExpansion.light.header,
      main: {
        ...themeExpansion.light.header.main,
        background: '#535F71',
      },
      detail: {
        ...themeExpansion.light.header.detail,
        background: themeExpansion.light.header.main.background,
      },
    },
  },
  dark: {
    ...themeExpansion.dark,
    header: {
      ...themeExpansion.dark.header,
      main: {
        ...themeExpansion.dark.header.main,
        background: '#2B2C2E',
      },
    },
  },
}

//There are some atoms that at first render gets the last store value fetched from storage.
// This is a workaround to ha the stored value before the screen first render.
export default function InitStructures() {
  // const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)')

  // const { updateColorScheme } = useTheme()

  // useMemo(() => {
  //   Platform.OS === 'web' && updateColorScheme(prefersDarkMode ? 'dark' : 'light')
  // }, [prefersDarkMode])

  return <></>
}

export { themeExpansion }
