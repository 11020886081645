import { CompositeScreenProps, NavigatorScreenParams } from '@react-navigation/native'
import { createNativeStackNavigator, NativeStackScreenProps } from '@react-navigation/native-stack'
import React from 'react'

import CustomerDetailContactEditOrCreateScreen from '../../../screens/customer/CustomerDetailContactEditOrCreateScreen'
import CustomerDetailDestinationEditOrCreateScreen from '../../../screens/customer/CustomerDetailDestinationEditOrCreateScreen'
import CustomerDetailsScreen from '../../../screens/customer/CustomerDetailScreen'
import { ContactEditOrCreateParams, DestinationEditOrCreateParams } from '../../../types'
import { screenOptions } from '../../Navigator'
import { AccessDataStackScreenProps } from './AccessDataCommonNavigator'
import { InfoboxCommonStackNames, InfoboxCommonStackNavigator, InfoboxCommonStackParamList } from './InfoboxCommonNavigators'
import { TicketDetailCommonStackScreenProps } from './TicketDetailCommonNavigator'

export type CustomerCommonStackParamList = {
  CustomerDetail: { customerId: string; customerName: string; customerNumber?: number; blocked: string; vat?: string }
  CustomerDetailContactEditOrCreate: ContactEditOrCreateParams
  CustomerDetailDestinationEditOrCreate: DestinationEditOrCreateParams
  CustomerDetailInfoboxCommonStack: NavigatorScreenParams<InfoboxCommonStackParamList>
}

export const CustomerCommonStackNames: {
  path: string
  screens: Record<keyof CustomerCommonStackParamList, string | typeof InfoboxCommonStackNames>
} = {
  path: 'customer',
  screens: {
    CustomerDetail: 'detail',
    CustomerDetailContactEditOrCreate: 'contact-edit-or-create',
    CustomerDetailDestinationEditOrCreate: 'destination-edit-or-create',
    CustomerDetailInfoboxCommonStack: InfoboxCommonStackNames,
  },
}

export type CustomerStackScreenProps<T extends keyof CustomerCommonStackParamList> = CompositeScreenProps<
  NativeStackScreenProps<CustomerCommonStackParamList, T>,
  TicketDetailCommonStackScreenProps<'TicketCustomerCommonStack'> | AccessDataStackScreenProps<'AccessDataCustomerCommonStack'>
>

const CustomerCommonStack = createNativeStackNavigator<CustomerCommonStackParamList>()
export function CustomerCommonStackNavigator() {
  return (
    <CustomerCommonStack.Navigator screenOptions={screenOptions}>
      <CustomerCommonStack.Screen name="CustomerDetail" component={CustomerDetailsScreen} />
      <CustomerCommonStack.Screen name="CustomerDetailContactEditOrCreate" component={CustomerDetailContactEditOrCreateScreen} />
      <CustomerCommonStack.Screen name="CustomerDetailDestinationEditOrCreate" component={CustomerDetailDestinationEditOrCreateScreen} />
      <CustomerCommonStack.Screen name="CustomerDetailInfoboxCommonStack" component={InfoboxCommonStackNavigator} />
    </CustomerCommonStack.Navigator>
  )
}
