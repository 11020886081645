import { IM, IMLayout, IMStyle, useDimensions, useEvent, useLanguage, useModalController, useTheme } from '@infominds/react-native-components'
import React, { createRef, useEffect, useState } from 'react'
import { Platform, StyleSheet } from 'react-native'

import { TicketActivity } from '../../apis/types/apiResponseTypes'
import Button from '../../components/Button'
import OnlineView from '../../components/offline/OnlineView'
import { SCREEN_CONSTANTS } from '../../components/screen/constants/constants'
import HeaderWithSync from '../../components/screen/headers/HeaderWithSync'
import { Screen } from '../../components/screen/Screen'
import SkeletonText from '../../components/skeleton/SkeletonText'
import {
  REFRESH_ACTIVITY_HISTORY_LIST_EVENT_KEY,
  REFRESH_ACTIVITY_PLANNING_LIST_EVENT_KEY,
  REFRESH_TICKET_DETAIL_EVENT_KEY,
  REFRESH_TICKET_LIST_EVENT_KEY,
} from '../../constants/EmitterKeys'
import useIsOnline from '../../dataProvider/hooks/useIsOnline'
import useCloseManager from '../../hooks/useCloseManager'
import useEditOrCreateScreenBackManager from '../../hooks/useEditOrCreateScreenBackManager'
import useUserSettings from '../../hooks/useUserSettings'
import ActivityAllocationModal from '../../modals/activity/ActivityAllocationModal'
import ActivityUnitUpModal from '../../modals/activity/ActivityUnitUpModal'
import { ActivityStackScreenProps } from '../../navigation/navigators/common/ActivityCommonNavigator'
import {
  ActivityAllocationParams,
  ActivityUnitUpParams,
  ActivityViewRef,
  ActivityViewType,
  ClosedActivityStates,
  ThemeColorExpanded,
} from '../../types'
import ActivityView, { ONLINE_FAKE_HEADER_MARGIN_BOTTOM } from '../../views/activity/ActivityView'

export default function ActivityScreen({
  route,
  navigation,
}:
  | ActivityStackScreenProps<'Activity'>
  | ActivityStackScreenProps<'ActivityPast'>
  | ActivityStackScreenProps<'ActivityHistory'>
  | ActivityStackScreenProps<'ActivityPlanning'>) {
  const { i18n } = useLanguage()
  const { isSmallDevice } = useDimensions()
  const { userSettings } = useUserSettings()
  const { theme } = useTheme<ThemeColorExpanded>()
  const { canBeClosed, check } = useCloseManager(route.params.activityId, route.params.ticketId, route.params.documentId, true)

  const [buttonReady, setButtonReady] = useState<{ ready: boolean; state: ActivityViewType; isClosed: boolean }>({
    ready: false,
    state: 'normal',
    isClosed: false,
  })
  const [loadingReset, setLoadingReset] = useState(false)
  const [closingPressed, setClosingPressed] = useState(false)
  const unitUpController = useModalController<ActivityUnitUpParams>()
  const allocationController = useModalController<ActivityAllocationParams>()
  const isOnline = useIsOnline()

  const viewRef = createRef<ActivityViewRef>()
  const { emit: emitHistory } = useEvent({ key: REFRESH_ACTIVITY_HISTORY_LIST_EVENT_KEY })
  const { emit: emitTickets } = useEvent({ key: REFRESH_TICKET_LIST_EVENT_KEY })
  const { emit: emitPlanning } = useEvent({ key: REFRESH_ACTIVITY_PLANNING_LIST_EVENT_KEY })
  const { emit: emitTicketDetail } = useEvent({ key: REFRESH_TICKET_DETAIL_EVENT_KEY })

  const { handleGoBack, handleShowEditedAlert } = useEditOrCreateScreenBackManager({
    title: i18n.t('UNSAVED_CHANGES_TITLE'),
    description: i18n.t('DISCARD_UNSAVED_CHANGES'),
  })

  useEffect(() => {
    if (closingPressed && canBeClosed === true) {
      setClosingPressed(false)
      viewRef.current?.closing()
    } else {
      if (closingPressed && canBeClosed === false) {
        setClosingPressed(false)
      }
    }

    if (canBeClosed === 'init') {
      setClosingPressed(false)
    }
  }, [closingPressed, canBeClosed])

  const handleNavigateBaseStack = () => {
    switch (buttonReady.state) {
      case 'normal': {
        emitTickets()
        emitPlanning()
        return navigation.navigate('BottomTab', { screen: 'TicketsStack', params: { screen: 'Tickets' } })
      }
      case 'history': {
        emitHistory()
        emitTickets()
        emitTicketDetail()
        return isHistoryStack ? navigation.navigate('BottomTab', { screen: 'HistoryStack', params: { screen: 'History' } }) : navigation.goBack()
      }
      case 'planning': {
        return
      }
    }
  }

  const handleNavigateTicketDetail = (ticketId: string, ticketCode: string) => {
    if (isPlanningStack) {
      navigation.navigate('BottomTab', {
        screen: 'PlanningStack',
        params: { screen: 'PlanningTicketDetailCommonStack', params: { screen: 'TicketDetail', params: { ticketId, ticketCode } } },
      })
    } else if (isHistoryStack) {
      navigation.navigate('BottomTab', {
        screen: 'HistoryStack',
        params: { screen: 'HistoryTicketDetailCommonStack', params: { screen: 'TicketDetail', params: { ticketId, ticketCode } } },
      })
    } else {
      console.warn('handleNavigateTicketDetail: this stack navigation is not managed yet!')
    }
  }

  const handleActivityAllocation = () => {
    handleShowEditedAlert(discarded => {
      if (discarded) viewRef.current?.descriptionRestore()

      const data: ActivityAllocationParams = {
        activityIds: [route.params.activityId],
        employeeId: viewRef.current?.getCollaboratorId(),
        ticketId: route.params.ticketId,
        ticketCode: route.params.ticketId,
        origin: isPlanningStack ? 'planningActivity' : 'ticketActivity',
      }
      isSmallDevice && (Platform.OS === 'android' || Platform.OS === 'ios')
        ? navigation.navigate('ActivityAllocation', data)
        : allocationController.show(data)
    })
  }

  const handleActivityReset = () => {
    handleShowEditedAlert(discarded => {
      if (discarded) viewRef.current?.descriptionRestore()

      viewRef.current?.reset()
    })
  }

  const handleUnitUp = () => {
    handleShowEditedAlert(discarded => {
      if (discarded) viewRef.current?.descriptionRestore()

      isSmallDevice && (Platform.OS === 'android' || Platform.OS === 'ios')
        ? navigation.navigate('ActivityUnitUp', {
            activityId: route.params.activityId,
            activityUnitUp: viewRef.current?.getUnitUp(),
          })
        : unitUpController.show({ activityId: route.params.activityId, activityUnitUp: viewRef.current?.getUnitUp() })
    })
  }

  const handleClosing = () => {
    handleShowEditedAlert(discarded => {
      if (discarded) viewRef.current?.descriptionRestore()

      setClosingPressed(true)
      check()
    })
  }

  const handleReactivate = () => {
    handleShowEditedAlert(discarded => {
      if (discarded) viewRef.current?.descriptionRestore()

      viewRef.current?.reactivate()
    })
  }

  const handleLoadingDone = (value: boolean, actType: ActivityViewType, activity?: TicketActivity) => {
    setButtonReady({ ready: value, state: actType, isClosed: !!activity?.state && ClosedActivityStates.includes(activity?.state) })
  }

  const isPast = route.name === 'ActivityPast'
  const isHistoryStack = route.name === 'ActivityHistory'
  const isPlanningStack = route.name === 'ActivityPlanning'
  const hideHeader = !isOnline && isPlanningStack

  return (
    <Screen>
      <Screen.Header goBack={handleGoBack} backHandlerCallback={handleGoBack}>
        <HeaderWithSync title={route.params.activityCode} />
      </Screen.Header>
      <Screen.Content forceBackground="transparent">
        {!isPast && !hideHeader && (
          <IM.View style={{ minHeight: SCREEN_CONSTANTS.headerDetailMinBarHeight + 15, backgroundColor: theme.header.detail.background }}>
            <IM.View style={[IMLayout.flex.row, styles.fakeHeader]}>
              {!buttonReady.ready ? (
                <SkeletonText width="100%" color={theme.header.detail.skeletonBackground} style={IMLayout.flex.f1} />
              ) : (
                <>
                  {buttonReady.state === 'normal' && (
                    <IM.View style={[IMLayout.flex.row, IMLayout.flex.f1, styles.header]}>
                      <IM.View style={IMLayout.flex.row}>
                        <OnlineView>
                          <Button
                            title={i18n.t('ACTIVITY_RESET')}
                            onPress={handleActivityReset}
                            spacing="right"
                            fade={1}
                            loading={loadingReset}
                            color={
                              userSettings?.allowChangeActivityStateFromEditingInPlanning && (!buttonReady || loadingReset)
                                ? theme.button.tertiaryButton
                                : IMStyle.palette.white
                            }
                            disabled={userSettings?.allowChangeActivityStateFromEditingInPlanning ? !buttonReady || loadingReset : true}
                          />
                        </OnlineView>

                        <Button
                          title={i18n.t('UNIT_UP')}
                          onPress={handleUnitUp}
                          fade={1}
                          color={!buttonReady || loadingReset ? theme.button.tertiaryButton : IMStyle.palette.white}
                          disabled={!buttonReady || loadingReset || buttonReady.isClosed}
                        />
                      </IM.View>
                      <Button
                        title={i18n.t('CLOSING')}
                        onPress={handleClosing}
                        loading={closingPressed}
                        color={!buttonReady || loadingReset ? theme.button.tertiaryButton : theme.general.info}
                        disabled={!buttonReady || loadingReset || buttonReady.isClosed}
                        fade={1}
                      />
                    </IM.View>
                  )}
                  {buttonReady.state === 'history' && (
                    <Button
                      title={i18n.t('ACTIVITY_REACTIVATE')}
                      onPress={handleReactivate}
                      fade={1}
                      loading={loadingReset}
                      color={!buttonReady || loadingReset ? theme.button.tertiaryButton : IMStyle.palette.white}
                      disabled={!buttonReady || loadingReset}
                    />
                  )}
                  {buttonReady.state === 'planning' && (
                    <OnlineView>
                      <Button
                        title={i18n.t('ACTIVITY_ALLOCATION')}
                        onPress={handleActivityAllocation}
                        fade={1}
                        loading={loadingReset}
                        color={!buttonReady || loadingReset ? theme.button.tertiaryButton : IMStyle.palette.white}
                        disabled={!buttonReady || loadingReset}
                      />
                    </OnlineView>
                  )}
                </>
              )}
            </IM.View>
          </IM.View>
        )}
        {hideHeader && <IM.View style={{ marginTop: IMLayout.verticalMargin * 4 }} />}
        <ActivityView
          ref={viewRef}
          forceStart={route.params.start}
          activityCode={route.params.activityCode}
          activityId={route.params.activityId}
          ticketCode={route.params.ticketCode}
          ticketId={route.params.ticketId}
          showTicketLink={route.params.link}
          isPast={isPast}
          onLoadingReset={setLoadingReset}
          onLoadingDone={handleLoadingDone}
          onNavigateBaseStack={handleNavigateBaseStack}
          onNavigateTicketDetail={handleNavigateTicketDetail}
        />
        <ActivityUnitUpModal controller={unitUpController} />
        <ActivityAllocationModal controller={allocationController} />
      </Screen.Content>
    </Screen>
  )
}

const styles = StyleSheet.create({
  fakeHeader: {
    paddingHorizontal: 14,
    alignItems: 'center',
    flex: 1,
    marginBottom: ONLINE_FAKE_HEADER_MARGIN_BOTTOM,
    zIndex: 100,
  },
  header: {
    justifyContent: 'space-between',
  },
})
