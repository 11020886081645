import { IM, ModalController, useEvent, useLanguage, useTheme } from '@infominds/react-native-components'
import React, { useEffect, useRef, useState } from 'react'
import { Platform, StyleSheet } from 'react-native'

import api from '../apis/apiCalls'
import { TicketArticle } from '../apis/types/apiResponseTypes'
import useRequest from '../components/Infominds/hooks/useRequest'
import Pressable from '../components/Infominds/Pressable'
import TextInput from '../components/input/TextInput'
import { REFRESH_TICKET_ARTICLE_EVENT_KEY } from '../constants/EmitterKeys'
import { ThemeColorExpanded } from '../types'
import AvoidingViewModal from './AvoidingViewModal'

interface Props {
  controller: ModalController
  article: TicketArticle
}

export default function EditArticleLocationModal({ controller, article }: Props) {
  const { i18n } = useLanguage()
  const { theme } = useTheme<ThemeColorExpanded>()

  const initial = useRef(article.serialnumberLocation)

  const [save, setSave] = useState(false)
  const [text, setText] = useState(article.serialnumberLocation)

  const { request: edit, loading } = useRequest(api.editSerialNumber)

  const { emit } = useEvent({ key: REFRESH_TICKET_ARTICLE_EVENT_KEY })

  useEffect(() => {
    if (save && loading === false) {
      emit()
      controller.close()
    }
  }, [loading, save])

  const disabled = initial.current === text
  const paddingTop = Platform.OS === 'ios' ? 2 : 0

  return (
    <AvoidingViewModal controller={controller} title={i18n.t('EDIT_ARTICLE_LOCATION')}>
      <TextInput value={text ?? ''} onChangeText={val => setText(val === '' ? undefined : val)} multiline fixMultilineHeight />
      <IM.View style={styles.buttonsContainer} spacing="top">
        <Pressable spacing="right" onPress={controller.close}>
          <IM.Text style={styles.text}>{i18n.t('CANCEL').toUpperCase()}</IM.Text>
        </Pressable>
        <Pressable
          disabled={disabled}
          onPress={() => {
            setSave(true)
            edit({ id: article.serialnumberId, location: text })
          }}>
          {loading === false || loading === 'catched' ? (
            <IM.Text secondary={disabled} style={[styles.text, { color: disabled ? theme.text.detail : theme.general.info }]}>
              {i18n.t('SAVE').toUpperCase()}
            </IM.Text>
          ) : (
            <IM.LoadingSpinner isVisible size="small" style={{ paddingTop }} />
          )}
        </Pressable>
      </IM.View>
    </AvoidingViewModal>
  )
}

const styles = StyleSheet.create({
  text: {
    textAlign: 'center',
  },
  buttonsContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
})
