import { IM, IMLayout, SpacingProps } from '@infominds/react-native-components'
import React, { memo } from 'react'
import { StyleSheet } from 'react-native'

import { InfiniteLoadingType } from '../types'
import Error from './Error'
import NoEntry from './NoEntry'
import SkeletonCard from './skeleton/SkeletonCard'

type Props = {
  dataLength: number | undefined
  noDataMessage: string
  loading?: InfiniteLoadingType
  spacingSkeleton?: SpacingProps
  marginTop?: number
}

function FlashListHeader({ dataLength, noDataMessage, marginTop, loading, spacingSkeleton }: Props) {
  return (
    <>
      {loading === 'reloading' && (
        <IM.View spacing="all">
          {Array(20)
            .fill(0)
            .map((_, index) => {
              return (
                <IM.View spacing={spacingSkeleton ?? ['top', 'horizontal']} key={index}>
                  <SkeletonCard />
                </IM.View>
              )
            })}
        </IM.View>
      )}
      <IM.View style={[styles.container, { marginTop: marginTop ?? IMLayout.horizontalMargin }]}>
        <>
          {loading === 'catched' && <Error />}
          {loading === false && dataLength === 0 && <>{dataLength === 0 && <NoEntry description={noDataMessage} />}</>}
        </>
      </IM.View>
    </>
  )
}

export default memo(FlashListHeader)

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
  },
})
