import { IM, IMStyle, useAlert, useLanguage, useTheme } from '@infominds/react-native-components'
import React, { useMemo } from 'react'
import { StyleSheet } from 'react-native'

import { AccessData } from '../apis/types/apiResponseTypes'
import { accessDataCardStyle } from '../cards/accessData/AccessDataCard'
import appUtils from '../utils/appUtils'
import TimeUtils from '../utils/TimeUtils'
import Pressable from './Infominds/Pressable'

interface Props {
  open: boolean
  levelAccessGranted: boolean
  groupAccessGranted: boolean
  data: AccessData
  showEmailField?: boolean
}

export default function AccessInfo({ open, levelAccessGranted, groupAccessGranted, data, showEmailField = false }: Props) {
  const { theme } = useTheme()
  const { i18n, language } = useLanguage()

  const alert = useAlert()

  const lastModify = useMemo(() => TimeUtils.format(data.lastModification, language), [data, language])

  return (
    <>
      {open && <IM.Text style={accessDataCardStyle.title}>{i18n.t('COMMENT')}</IM.Text>}
      <IM.Text numberOfLines={open ? undefined : 3} style={styles.text} selectable>
        {data.note}
      </IM.Text>
      {open && (
        <>
          <IM.Text style={styles.text}>{data.note2}</IM.Text>
          {levelAccessGranted && groupAccessGranted && <IM.Text style={styles.text}>{data.noteWithAccesslevel}</IM.Text>}
          {data.url && (
            <IM.View spacing="top">
              <IM.Text style={accessDataCardStyle.title}>{i18n.t('WEBSITE')}</IM.Text>
              {/* TODO RT: Pressable highlight should be only visible around the text */}
              <Pressable
                hitSlop={{ top: 20, bottom: 10, left: 8, right: 8 }}
                style={styles.pressable}
                disabled={data.url === undefined}
                onPress={() => data.url && appUtils.openUrl(data.url, alert, i18n.t('OPEN_URL_ERROR'))}>
                <IM.View style={styles.linkContainer}>
                  <IM.Text style={styles.text}>{data.url}</IM.Text>
                  <IM.View spacing="left">
                    <IM.Icon icon={['fal', 'arrow-up-right-from-square']} size={10} color={theme.text.default} />
                  </IM.View>
                </IM.View>
              </Pressable>
            </IM.View>
          )}
          {showEmailField && data.email && (
            <IM.View spacing="top">
              <IM.Text style={accessDataCardStyle.title}>{i18n.t('EMAIL')}</IM.Text>
              <IM.Text style={styles.text} selectable>
                {data.email}
              </IM.Text>
            </IM.View>
          )}
          {data.serialnumber && (
            <IM.View spacing="top">
              <IM.Text style={accessDataCardStyle.title}>{i18n.t('SERIAL_NUMBER')}</IM.Text>
              <IM.Text style={styles.text} selectable>
                {data.serialnumber}
              </IM.Text>
            </IM.View>
          )}
          <IM.View spacing="top">
            <IM.Text style={accessDataCardStyle.title}>{i18n.t('LAST_EDIT')}</IM.Text>
            <IM.Text style={styles.text} selectable>
              {data.lastModificationUser ? `${data.lastModificationUser} - ` : ''}
              {lastModify}
            </IM.Text>
          </IM.View>
        </>
      )}
    </>
  )
}

const styles = StyleSheet.create({
  linkContainer: { flexDirection: 'row', alignItems: 'center' },
  pressable: { padding: 0 },
  text: { fontSize: IMStyle.typography.fontSizeRegular - 2 },
  centralContainer: {
    flex: 1,
  },
})
