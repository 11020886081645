import { useDimensions, useLanguage } from '@infominds/react-native-components'
import React, { createRef } from 'react'

import { MasterDetailProvider } from '../../components/MasterDetail/contexts/MasterDetailContext'
import MasterDetail from '../../components/MasterDetail/MasterDetails'
import { SearchProvider } from '../../components/screen/contexts/SearchContext'
import { Screen } from '../../components/screen/Screen'
import useInfoboxManagement from '../../hooks/useInfoboxManagement'
import { InfoboxStackScreenProps } from '../../navigation/navigators/common/InfoboxCommonNavigators'
import InfoboxAssetsViewMasterDetail from '../../views/infobox/InfoboxAssetsViewMasterDetail'
import InfoboxFolderView from '../../views/infobox/InfoboxFolderView'
import { InfoboxMediaViewImperativeMethods } from '../../views/infobox/InfoboxMediaView'

const InfoboxFolderScreen = ({ route }: InfoboxStackScreenProps<'InfoboxFolders'>) => {
  const { i18n } = useLanguage()
  const { isSmallDevice } = useDimensions()
  const mediaRef = createRef<InfoboxMediaViewImperativeMethods>()
  const { displayMethod, newAssets, handleInfoboxGoBack, handleInfoboxIconPress } = useInfoboxManagement({
    mediaRef,
  })

  return (
    <SearchProvider>
      <Screen>
        <Screen.Header
          title={isSmallDevice ? i18n.t('FOLDERS') : i18n.t('INFOBOX')}
          subTitle={route.params.subTitle}
          goBack={isSmallDevice ? true : handleInfoboxGoBack}
          backHandlerCallback={isSmallDevice ? undefined : handleInfoboxGoBack}
          rightIcon={isSmallDevice ? undefined : newAssets > 0 ? ['fal', 'check'] : ['fal', displayMethod === 'twice' ? 'list' : 'grid-2']}
          onRightIconPress={isSmallDevice ? undefined : handleInfoboxIconPress}
        />
        <Screen.Content>
          <MasterDetailProvider>
            <MasterDetail>
              <MasterDetail.Master>
                <InfoboxFolderView id={route.params.id} type={route.params.type} />
              </MasterDetail.Master>
              <MasterDetail.Detail>
                <InfoboxAssetsViewMasterDetail innerRef={mediaRef} customerId={route.params.id} />
              </MasterDetail.Detail>
            </MasterDetail>
          </MasterDetailProvider>
        </Screen.Content>
      </Screen>
    </SearchProvider>
  )
}

export default InfoboxFolderScreen
