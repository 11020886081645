import { StringUtils, useAlert, useDimensions, useEvent, useLanguage, useModalController, useTheme } from '@infominds/react-native-components'
import { NavigationProp, useNavigation } from '@react-navigation/native'
import React, { createRef, ForwardedRef, forwardRef, ReactNode, useEffect, useImperativeHandle, useMemo, useState } from 'react'
import { Animated, Platform, StyleSheet } from 'react-native'

import api from '../../apis/apiCalls'
import { Activity, TicketActivity } from '../../apis/types/apiResponseTypes'
import ActivityDescriptionCard, { ActivityDescriptionCardRef } from '../../cards/activity/ActivityDescriptionCard'
import ActivityInfoCard from '../../cards/activity/ActivityInfoCard'
import ActivityOrderCard from '../../cards/activity/ActivityOrderCard'
import ActivityPlanningCard from '../../cards/activity/ActivityPlanningCard'
import ActivitySparePartsCard from '../../cards/activity/ActivitySparePartsCard'
import ActivityTimeCard from '../../cards/activity/ActivityTimeCard'
import AdditionalFieldsCard from '../../cards/common/AdditionalFieldsCard'
import NotesCard from '../../cards/common/NotesCard'
import TipCard from '../../cards/common/TipCard'
import ActivityTimeButton from '../../components/ActivityTimeButton'
import Button from '../../components/Button'
import InfoboxButton from '../../components/InfoboxButton'
import useControlledLoader from '../../components/Infominds/hooks/useControlledLoader'
import useRequest from '../../components/Infominds/hooks/useRequest'
import PressableTextIcon from '../../components/Infominds/PressableTextIcon'
import OnlineView from '../../components/offline/OnlineView'
import { SCREEN_CONSTANTS, ScreenHeaderShadows } from '../../components/screen/constants/constants'
import ScrollViewData from '../../components/ScrollViewData'
import { TICKET_ARTICLE_INFOBOX_ID } from '../../constants/ButtonIds'
import CONSTANTS from '../../constants/Constants'
import {
  EDIT_ARTICLE_COUNTERS_EVENT_KEY,
  REFRESH_ACTIVITY_QUALITY_CHECK_EVENT_KEY,
  REFRESH_ACTIVITY_VIEW_EVENT_KEY,
  REFRESH_INFOBOX_BUTTON_COUNTER_EVENT_KEY,
} from '../../constants/EmitterKeys'
import { REQUEST_INFOBOX_FILES, REQUEST_QUALITY, REQUEST_SERIAL_NUMBER_COUNTERS, REQUEST_TICKET_ACTIVITY } from '../../constants/Keys'
import { useDataProvider } from '../../dataProvider/hooks/useDataProvider'
import useUserSettings from '../../hooks/useUserSettings'
import ActivityArticleCounterModal from '../../modals/activity/ActivityArticleCounterModal'
import ActivitySendReportModal from '../../modals/activity/ActivitySendReportModal'
import { ActivityCommonStackParamList } from '../../navigation/navigators/common/ActivityCommonNavigator'
import {
  ActivityArticleCounterParams,
  ActivitySendReportParams,
  ActivityState,
  ActivityViewRef,
  ActivityViewType,
  ThemeColorExpanded,
} from '../../types'
import qualityUtils from '../../utils/QualityUtils'
import ticketUtils from '../../utils/TicketUtils'

interface Props {
  activityId: string
  activityCode: string
  forceStart: boolean
  ticketCode: string
  ticketId: string
  isPast: boolean
  showTicketLink: boolean
  onLoadingReset: (value: boolean) => void
  onLoadingDone: (value: boolean, type: ActivityViewType, activity?: TicketActivity) => void
  onNavigateBaseStack: () => void
  onNavigateTicketDetail: (ticketId: string, ticketCode: string) => void
}

export const ONLINE_FAKE_HEADER_MARGIN_BOTTOM = 25
const buttonAnimationValue = new Animated.Value(0)

const ActivityView = (
  { activityId, activityCode, forceStart, ticketCode, ticketId, isPast, showTicketLink, ...others }: Props,
  ref: ForwardedRef<ActivityViewRef>
) => {
  useImperativeHandle(ref, () => ({
    reset: () => handleReset(),
    getUnitUp: () => {
      if (activity) {
        return {
          unitUp: activity.unitUp,
          dateTime: activity.unitUpDateTime,
        }
      } else {
        return undefined
      }
    },
    reactivate: () => handleReactivate(),
    getCollaboratorId: () => activity?.employeeId,
    closing: () =>
      activity &&
      navigation.navigate('ActivityClose', {
        activityId: activityId,
        customerId: activity.customerId,
        shippingAddressId: activity.shippingAddressId,
        email: activity.customerTicketActivityEmail ?? activity.contactEmail,
        contact: activity.contact,
        addressId: activity.addressId,
      }),
    descriptionRestore: () => {
      descriptionRef.current?.reset()
      return
    },
  }))

  const { onLoadingReset, onLoadingDone, onNavigateBaseStack, onNavigateTicketDetail } = others

  const { alert } = useAlert()
  const { i18n } = useLanguage()
  const { isOnline } = useDataProvider()
  const { isSmallDevice } = useDimensions()
  const { userSettings, menuItems } = useUserSettings()
  const { theme } = useTheme<ThemeColorExpanded>()
  const descriptionRef = createRef<ActivityDescriptionCardRef>()
  const navigation = useNavigation<NavigationProp<ActivityCommonStackParamList>>()
  const controller = useModalController<ActivitySendReportParams>()
  const counterController = useModalController<ActivityArticleCounterParams>()

  const [requestReset, setRequestReset] = useState(false)
  const [loadingEdit, setLoadingEdit] = useState(false)
  const [forced, setForced] = useState(false)

  const FAKE_HEADER_MARGIN_BOTTOM = isOnline ? ONLINE_FAKE_HEADER_MARGIN_BOTTOM : 35

  const {
    item: activities,
    loadItem: getActivities,
    loading,
  } = useControlledLoader(api.getTicketActivity, {
    id: REQUEST_TICKET_ACTIVITY,
  })
  const {
    item: counters,
    loadItem: loadCounter,
    loading: loadingCounters,
  } = useControlledLoader(api.getSerialNumberCounter, { id: REQUEST_SERIAL_NUMBER_COUNTERS })
  const {
    item: files,
    loadItem: loadFiles,
    loading: loadingFiles,
  } = useControlledLoader(api.getInfoboxFiles, {
    id: REQUEST_INFOBOX_FILES,
  })
  const {
    item: quality,
    loadItem: loadQuality,
    loading: loadingQuality,
  } = useControlledLoader(api.getQuality, {
    id: REQUEST_QUALITY,
  })
  const { request: editActivity, loading: loadingEditActivity } = useRequest(api.editActivity)

  useEvent({ key: REFRESH_ACTIVITY_VIEW_EVENT_KEY }, () => refresh())
  useEvent({ key: REFRESH_INFOBOX_BUTTON_COUNTER_EVENT_KEY }, () => refreshInfobox())
  useEvent({ key: EDIT_ARTICLE_COUNTERS_EVENT_KEY }, () => refresh())
  useEvent({ key: REFRESH_ACTIVITY_QUALITY_CHECK_EVENT_KEY }, () => refreshQuality())

  useEffect(() => {
    refresh()
    refreshQuality()
  }, [])

  useEffect(() => {
    if (activities?.length === 1) {
      if (historyActivity) {
        onLoadingDone(true, 'history', activities[0])
      } else if (planningActivity) {
        onLoadingDone(true, 'planning', activities[0])
      } else {
        onLoadingDone(
          loading === false &&
            activities !== undefined &&
            activities.length === 1 &&
            loadingCounters !== 'reloading' &&
            loadingQuality !== 'reloading'
            ? true
            : false,
          'normal',
          activities[0]
        )
      }
    }
  }, [loading, activities, loadingCounters, loadingQuality])

  useEffect(() => {
    loadingEditActivity !== false && setRequestReset(true)
  }, [loadingEditActivity])

  useEffect(() => {
    if (!requestReset) return

    if (loadingEditActivity === 'catched') {
      setRequestReset(false)
      handleChangeLoading(false)

      return
    }

    if (loadingEditActivity === false) {
      onNavigateBaseStack()
    }

    handleChangeLoading(loadingEditActivity === false ? false : true)
  }, [requestReset, loadingEditActivity])

  const handleChangeLoading = (value: boolean) => {
    setLoadingEdit(value)
    onLoadingReset(value)
  }

  const refresh = () => {
    onLoadingDone(false, 'normal')
    refreshInfobox()
    loadCounter({ ticketId })
    getActivities({ id: activityId })
  }

  const refreshInfobox = () => {
    loadFiles({ infoboxTyp: 'Activity', id: activityId })
  }

  const refreshQuality = () => {
    loadQuality({ activityId })
  }

  const handleReset = () => {
    handleChangeLoading(true)
    alert(
      i18n.t('RESET_ACTIVITY_CONFIRMATION_TITLE'),
      StringUtils.stringValueReplacer(i18n.t('RESET_ACTIVITY_CONFIRMATION_DESCRIPTION'), activity?.code, activity?.state, 'Plan' as ActivityState),
      [
        {
          text: i18n.t('ACTIVITY_RESET'),
          onPress: () => {
            activity && editActivity({ id: activity.id, state: 'ResetInPlanning' } as Activity)
          },
          style: 'destructive',
        },
        {
          text: i18n.t('CANCEL'),
          onPress: () => {
            handleChangeLoading(false)
          },
          style: 'cancel',
        },
      ]
    )
  }

  const handleReactivate = () => {
    handleChangeLoading(true)
    alert(
      i18n.t('REACTIVATE_ACTIVITY_CONFIRMATION_TITLE'),
      StringUtils.stringValueReplacer(i18n.t('REACTIVATE_ACTIVITY_CONFIRMATION_DESCRIPTION'), activity?.code),
      [
        {
          text: i18n.t('CANCEL'),
          onPress: () => {
            handleChangeLoading(false)
          },
          style: 'cancel',
        },
        {
          text: i18n.t('ACTIVITY_REACTIVATE'),
          onPress: () => {
            activity && editActivity({ id: activity.id, state: 'Reactivation' } as Activity)
          },
          style: 'destructive',
        },
      ]
    )
  }

  const handleSendReport = (act: TicketActivity) => {
    isSmallDevice && (Platform.OS === 'android' || Platform.OS === 'ios')
      ? navigation.navigate('ActivitySendReport', {
          activityId: act.id,
          init: act.customerTicketActivityEmail ?? act.contactEmail,
        })
      : controller.show({ activityId: act.id, init: act.customerTicketActivityEmail ?? act.contactEmail })
  }

  const handleQualityCheck = (act: TicketActivity) => {
    navigation.navigate('ActivityModalCommonStack', { screen: 'QualityCheck', params: { activityId: act.id, activityCode: act.code } })
  }

  const handleCounters = () => {
    if (counters === undefined) return

    isSmallDevice && (Platform.OS === 'android' || Platform.OS === 'ios')
      ? navigation.navigate('ActivityArticleCounter', {
          counters: counters,
          activityId,
        })
      : counterController.show({ counters: counters, activityId })
  }

  const sendReportButton = (activity: TicketActivity): ReactNode | undefined => {
    if (historyActivity || planningActivity) {
      return undefined
    } else {
      return (
        <Button
          title={i18n.t('SEND_REPORT')}
          onPress={() => handleSendReport(activity)}
          disabled={!activityEditable || loadingEdit}
          color={theme.general.info}
          numberOfLines={1}
        />
      )
    }
  }

  const navigateButton = (activity: TicketActivity): ReactNode | undefined => {
    if (historyActivity || planningActivity) {
      if (!showTicketLink) return

      return (
        <PressableTextIcon
          onPress={() => onNavigateTicketDetail(activity.ticketId, ticketCode)}
          icon={['fal', 'chevron-right']}
          alignIcon="right"
          iconSize={12}
          iconColor={theme.text.detail}
          color={theme.general.info}
          pressableStyle={styles.pressable}>
          {ticketCode}
        </PressableTextIcon>
      )
    } else {
      return undefined
    }
  }

  const qualityButton = (activity: TicketActivity): ReactNode | undefined => {
    const disabled = !activityEditable || loadingQuality === 'reloading' || quality === undefined || quality.length === 0

    if (historyActivity || planningActivity) {
      return undefined
    } else {
      if (menuItems?.qualititymanagement) {
        return (
          <Button
            title={i18n.t('QUALITY_CHECK')}
            onPress={() => handleQualityCheck(activity)}
            disabled={disabled}
            color={theme.button.qualityCheck}
            icon={loadingQuality === 'reloading' || disabled ? undefined : checkQuality ? ['far', 'check'] : ['fal', 'times']}
            iconColor={checkQuality ? theme.general.info : theme.error}
            loading={loadingQuality === 'reloading'}
            alignIcon="right"
            numberOfLines={1}
          />
        )
      } else {
        return undefined
      }
    }
  }

  const counterButton = (): ReactNode | undefined => {
    const disabled = !activityEditable || loadingCounters === 'reloading' || counters === undefined || counters.length === 0

    if (historyActivity || planningActivity) {
      return undefined
    } else {
      return (
        <Button
          title={i18n.t('COUNTERS')}
          onPress={handleCounters}
          disabled={disabled}
          color={theme.button.qualityCheck}
          icon={
            loadingCounters === 'reloading' || disabled || checkCounter === undefined ? undefined : checkCounter ? ['far', 'check'] : ['fal', 'times']
          }
          iconColor={checkCounter ? theme.general.info : theme.error}
          loading={loadingCounters === 'reloading'}
          alignIcon="right"
          numberOfLines={1}
        />
      )
    }
  }

  const checkQuality = useMemo(() => qualityUtils.closingQualityCheck(quality), [quality])
  const checkCounter = useMemo(() => ticketUtils.closingCountersCheck(counters, userSettings), [userSettings, counters])

  const activity = activities?.at(0)
  const historyActivity = activities?.at(0)?.state === 'Done'
  const planningActivity = activities?.at(0)?.state === 'Plan' || activities?.at(0)?.state === 'Request'
  const activityEditable = isPast ? false : activity ? userSettings?.employeeId === activity.employeeId : false

  return (
    <>
      <ScrollViewData
        refresh={() => {
          refresh()
          refreshQuality()
        }}
        disableHideKeyboardOnScroll
        buttonId={TICKET_ARTICLE_INFOBOX_ID}
        loading={loading === false && activity !== undefined ? false : loading}
        style={[
          // eslint-disable-next-line react-native/no-inline-styles
          {
            marginTop: isPast ? 0 : -FAKE_HEADER_MARGIN_BOTTOM,
            backgroundColor: theme.background.default,
            borderTopLeftRadius: SCREEN_CONSTANTS.headerRadius,
            borderTopRightRadius: SCREEN_CONSTANTS.headerRadius,
          },
          ScreenHeaderShadows,
        ]}
        contentContainerStyle={{ marginTop: Platform.OS === 'web' ? (isPast ? 0 : FAKE_HEADER_MARGIN_BOTTOM) : undefined }}>
        {activity && (
          <>
            {(historyActivity || planningActivity) && !!activity.tip && <TipCard tip={activity.tip} spacing="bottom" />}
            <ActivityInfoCard
              activity={activity}
              spacing="bottom"
              sendReportButton={sendReportButton(activity)}
              navigateButton={navigateButton(activity)}
              qualityCheckButton={qualityButton(activity)}
              counterButton={counterButton()}
              editable={activityEditable}
            />
            <ActivityDescriptionCard
              ref={descriptionRef}
              activity={activity}
              spacing="bottom"
              disabled={isPast ? true : planningActivity ? loadingEditActivity !== false : !activityEditable || loadingEditActivity !== false}
              disableNextActivity={historyActivity || planningActivity}
            />
            <ActivityTimeCard
              activity={activity}
              spacing="bottom"
              onlyRead={!activityEditable || (historyActivity && userSettings?.allowChangeTimeOfHistoricalActivity === false) || loadingEdit}
              onlyCreate={!historyActivity && userSettings?.allowChangeSavedTimeOfActivity === false}
            />
            <AdditionalFieldsCard id={activity.id} type="Activity" spacing="bottom" onlyRead={!activityEditable || historyActivity || loadingEdit} />
            <OnlineView>
              <ActivityPlanningCard
                id={activity.id}
                ticketId={activity.ticketId}
                spacing="bottom"
                onlyRead={!activityEditable || historyActivity || loadingEdit || userSettings?.allowCreateAndChangePlanningTimes === false}
              />
            </OnlineView>
            <ActivitySparePartsCard
              id={activity.id}
              ticketId={activity.ticketId}
              activityCode={activity.code}
              spacing="bottom"
              onlyRead={!activityEditable || historyActivity || loadingEdit}
            />
            {isOnline && activity.sparePartInternalPurchaseOrderRegisterId && (
              <ActivityOrderCard ticketId={activity.ticketId} spacing="bottom" onlyRead={!activityEditable || historyActivity || loadingEdit} />
            )}
            <NotesCard id={activityId} type="Activity" code={activityCode} onlyRead={!activityEditable || loadingEdit} forceLayout={undefined} />
          </>
        )}
      </ScrollViewData>
      <InfoboxButton
        id={TICKET_ARTICLE_INFOBOX_ID}
        value={buttonAnimationValue}
        loading={loadingFiles}
        onPress={() => {
          files &&
            navigation.navigate('ActivityInfoboxCommonStack', {
              screen: 'InfoboxFolders',
              params: {
                id: activityId,
                type: 'Activity',
                subTitle: activityCode,
              },
            })
        }}
        text={files?.length.toString() ?? 'NaN'}
      />
      {!historyActivity && !planningActivity && activityEditable && (
        <ActivityTimeButton
          id={TICKET_ARTICLE_INFOBOX_ID}
          value={buttonAnimationValue}
          bottom={CONSTANTS.secondButtonBottom}
          activityId={activityId}
          forceStart={forced === false ? forceStart : false}
          onForced={() => setForced(true)}
        />
      )}
      <ActivitySendReportModal controller={controller} />
      <ActivityArticleCounterModal controller={counterController} />
    </>
  )
}

export default forwardRef(ActivityView)

const styles = StyleSheet.create({
  pressable: { padding: 0, paddingVertical: 6 },
})
