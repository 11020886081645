import { useLanguage } from '@infominds/react-native-components'
import React, { createRef, ForwardedRef, forwardRef, useImperativeHandle } from 'react'
import { Keyboard } from 'react-native'

import { Screen } from '../../components/screen/Screen'
import { FormProvider } from '../../contexts/FormContext'
import useEditOrCreateScreenBackManager from '../../hooks/useEditOrCreateScreenBackManager'
import { ModalCommonStackScreenProps } from '../../navigation/navigators/common/ModalCommonNavigator'
import { AdditionalFieldsParams, EditOrCreateViewRef, ModalScreenProps, ModalScreenRef } from '../../types'
import EditAddFieldsView from '../../views/common/EditAddFieldsView'

function EditAddFieldsScreen(
  { route, controller }: Omit<ModalCommonStackScreenProps<'EditAddFields'>, 'navigation'> & ModalScreenProps<AdditionalFieldsParams>,
  ref: ForwardedRef<ModalScreenRef>
) {
  useImperativeHandle(ref, () => ({
    handleGoBack: handleGoBack,
  }))

  const { i18n } = useLanguage()
  const createViewRef = createRef<EditOrCreateViewRef>()

  const { status, setStatus, handleGoBack } = useEditOrCreateScreenBackManager({
    title: i18n.t('UNSAVED_CHANGES_TITLE'),
    description: i18n.t('DISCARD_UNSAVED_CHANGES'),
    controller,
  })

  const isModal = controller !== undefined

  return (
    <Screen transparent={isModal}>
      <Screen.Header
        goBack={handleGoBack}
        backHandlerCallback={handleGoBack}
        isModal={isModal}
        title={i18n.t('EDIT_ADD_FIELDS')}
        onRightIconPress={() => {
          Keyboard.dismiss()
          createViewRef.current?.handleUpload()
        }}
        rightIcon={['fal', 'check']}
        disable={status === 'done' || status === 'mandatoryMissing'}
        loading={status === 'uploading'}
      />
      <Screen.Content>
        <FormProvider>
          <EditAddFieldsView
            ref={createViewRef}
            id={route.params.id}
            type={route.params.type}
            addFields={route.params.values}
            onUploadStatus={setStatus}
          />
        </FormProvider>
      </Screen.Content>
    </Screen>
  )
}

export default forwardRef(EditAddFieldsScreen)
