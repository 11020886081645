import React from 'react'

import HeaderWithIcons, { HeaderWithIconsProps } from './HeaderWithIcons'
import SyncIcon from './SyncIcon'

export default function HeaderWithIconsSync({ ...props }: HeaderWithIconsProps) {
  return (
    <HeaderWithIcons {...props}>
      <SyncIcon />
    </HeaderWithIcons>
  )
}
