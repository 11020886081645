import { useDimensions, useLanguage, useModalController } from '@infominds/react-native-components'
import React, { useMemo } from 'react'
import { Platform, StyleProp, ViewStyle } from 'react-native'

import { QualityCheckGroupType, QualityCheckOrderType } from '../../../contexts/QualityCheckFilterContext'
import useQualityCheckFilter from '../../../hooks/useQualityCheckFilter'
import QualityCheckFilterModal from '../../../modals/common/QualityCheckFilterModal'
import { ModalCommonStackParamList } from '../../../navigation/navigators/common/ModalCommonNavigator'
import CommonFilterDetailHeader from './CommonFilterDetailHeader'

interface Props {
  style?: StyleProp<ViewStyle>
  onSDNavigation: () => void
}

export default function QualityCheckFilterDetailHeader({ style, onSDNavigation }: Props) {
  const { groups, orders, changeFilterStatus } = useQualityCheckFilter()
  const { language } = useLanguage()
  const { isSmallDevice } = useDimensions()
  const controller = useModalController<ModalCommonStackParamList['QualityCheckFilters']>()

  const activeGroups = useMemo(() => groups.filter(el => el.active), [groups, language])
  const activeOrders = useMemo(() => orders.filter(el => el.active), [orders, language])

  return (
    <>
      <CommonFilterDetailHeader
        activeFilters={undefined}
        activeGroups={activeGroups}
        activeOrders={activeOrders}
        enabled={true}
        changeFilterStatus={id => {
          changeFilterStatus(id as QualityCheckOrderType | QualityCheckGroupType)
        }}
        onNavigate={() => (isSmallDevice && (Platform.OS === 'android' || Platform.OS === 'ios') ? onSDNavigation() : controller.show(undefined))}
        style={style}
      />
      <QualityCheckFilterModal controller={controller} />
    </>
  )
}
