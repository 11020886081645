import { useLanguage } from '@infominds/react-native-components'
import React, { createRef, ForwardedRef, forwardRef, useImperativeHandle } from 'react'
import { Keyboard } from 'react-native'

import { Screen } from '../../components/screen/Screen'
import { FormProvider } from '../../contexts/FormContext'
import useEditOrCreateScreenBackManager from '../../hooks/useEditOrCreateScreenBackManager'
import { TicketDetailCommonStackScreenProps } from '../../navigation/navigators/common/TicketDetailCommonNavigator'
import { EditOrCreateViewRef, ModalScreenProps, ModalScreenRef, TicketCreateActivityParams } from '../../types'
import TicketActivityCreateView from '../../views/ticket/TicketActivityCreateView'

const TicketActivityCreateScreen = (
  { route, controller }: Pick<TicketDetailCommonStackScreenProps<'TicketActivityCreation'>, 'route'> & ModalScreenProps<TicketCreateActivityParams>,
  ref: ForwardedRef<ModalScreenRef>
) => {
  useImperativeHandle(ref, () => ({
    handleGoBack: handleGoBack,
  }))

  const editOrCreateViewRef = createRef<EditOrCreateViewRef>()

  const { i18n } = useLanguage()
  const { status, setStatus, handleGoBack } = useEditOrCreateScreenBackManager({
    title: i18n.t('UNSAVED_CHANGES_TITLE'),
    description: i18n.t('DISCARD_UNSAVED_CHANGES'),
    controller,
  })

  const common = {
    ref: editOrCreateViewRef,
    onUploadStatus: setStatus,
  }

  const isModal = controller !== undefined

  return (
    <Screen transparent={isModal}>
      <Screen.Header
        title={i18n.t('CREATE_ACTIVITY')}
        goBack={handleGoBack}
        backHandlerCallback={handleGoBack}
        isModal={isModal}
        onRightIconPress={() => {
          Keyboard.dismiss()
          editOrCreateViewRef.current?.handleUpload()
        }}
        rightIcon={['fal', 'check']}
        disable={status === 'done' || status === 'mandatoryMissing'}
        loading={status === 'uploading'}
      />
      <Screen.Content>
        <FormProvider>
          <TicketActivityCreateView ticketId={route.params.ticketId} {...common} />
        </FormProvider>
      </Screen.Content>
    </Screen>
  )
}

export default forwardRef(TicketActivityCreateScreen)
