import { IM, ModalController, useLanguage, useTheme } from '@infominds/react-native-components'
import React from 'react'
import { Platform, StyleSheet } from 'react-native'

import Pressable from '../components/Infominds/Pressable'
import { LoadingType, ThemeColorExpanded } from '../types'
import AvoidingViewModal from './AvoidingViewModal'

type Button = {
  title: string
  color?: string
  loading?: LoadingType
  onPress: () => void
}

interface Props {
  controller: ModalController
  description: string
  buttons: Button[]
}

export default function ErrorModal({ controller, description, buttons }: Props) {
  const { i18n } = useLanguage()
  const { theme } = useTheme<ThemeColorExpanded>()

  const paddingTop = Platform.OS === 'ios' ? 2 : 0

  return (
    <AvoidingViewModal
      controller={controller}
      title={i18n.t('ERROR')}
      titleStyle={{ color: theme.error }}
      description={description}
      onRequestClose={() => {}}>
      <IM.View style={styles.buttonsContainer} spacing="vertical">
        {buttons.map((button, index) => (
          <Pressable onPress={button.onPress} key={index}>
            {button.loading === false || button.loading === undefined ? (
              <IM.Text style={[styles.text, { color: button.color ?? theme.text.default }]}>{button.title.toUpperCase()}</IM.Text>
            ) : (
              <IM.LoadingSpinner isVisible size="small" style={{ paddingTop }} />
            )}
          </Pressable>
        ))}
      </IM.View>
    </AvoidingViewModal>
  )
}

const styles = StyleSheet.create({
  text: {
    textAlign: 'center',
  },
  buttonsContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
})
