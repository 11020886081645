import { BottomSheetModalProvider } from '@gorhom/bottom-sheet'
import { OptionsProvider } from '@infominds/react-native-camera'
import { useDimensions, useLanguage, useModalController, useTheme } from '@infominds/react-native-components'
import { useAuthentication, useInAppUpdates } from '@infominds/react-native-license'
import { AssetProvider } from '@infominds/react-native-media'
import { NativeStackNavigationOptions } from '@react-navigation/native-stack'
import React, { memo, useEffect, useMemo } from 'react'
import { Platform, StatusBar } from 'react-native'

import AppContent from '../components/Infominds/AppContent'
import TabNavigator from '../components/Infominds/TabNavigator'
import CONSTANTS from '../constants/Constants'
import { MenuProvider } from '../contexts/MenuContext'
import useUserSettings from '../hooks/useUserSettings'
import ErrorModal from '../modals/ErrorModal'
import LoginScreen from '../screens/login/LoginScreen'
import EditNavigationScreen from '../screens/more/EditNavigationScreen'
import EmptyMoreScreen from '../screens/more/EmptyMoreScreen'
import MoreScreen from '../screens/more/MoreScreen'
import NotFoundScreen from '../screens/notFound/NotFoundScreen'
import ScannerCameraScreen from '../screens/scanner/ScannerCameraScreen'
import navigationUtils from '../utils/navigationUtils'
import NavigationContainer from './NavigationContainer'
import { HistoryStackNavigator } from './navigators/bottomTabs/HistoryBottomTabNavigator'
import { PasswordsStackNavigator } from './navigators/bottomTabs/PasswordBottomTabNavigator'
import { PlanningStackNavigator } from './navigators/bottomTabs/PlanningBottomTabNavigator'
import { QualityStackNavigator } from './navigators/bottomTabs/QualityBottomTabNavigator'
import { SettingsStackNavigator } from './navigators/bottomTabs/SettingsBottomTabNavigator'
import { SparePartsStackStackNavigator } from './navigators/bottomTabs/SparePartsBottomTabNavigator'
import { SynchronizationStackNavigator } from './navigators/bottomTabs/SynchronizationBottomTabNavigator'
import { TicketStackNavigator } from './navigators/bottomTabs/TicketBottomTabNavigator'
import { RootStack } from './navigators/RootNavigator'
import { BottomTabParamList, TabNavigationScreen } from './types'

export const screenOptions: NativeStackNavigationOptions = {
  headerShown: false,
  animation: Platform.OS === 'ios' ? 'default' : 'fade',
}

interface Props {
  isLoggedIn: boolean
  basePath: string
}

function Navigator({ isLoggedIn, basePath }: Props) {
  const { i18n, language } = useLanguage()
  const controller = useModalController()
  const { theme, colorScheme } = useTheme()
  const { isSmallDevice } = useDimensions()
  const { logout } = useAuthentication()
  const { userSettings, menuItems, loading, loadSettings } = useUserSettings()

  useInAppUpdates(true, language)

  useEffect(() => {
    if (userSettings === undefined) return

    userSettings.employeeId === undefined && controller.show()
    userSettings.employeeId !== undefined && controller.isShown && controller.close()
  }, [userSettings])

  let bottomTabs: TabNavigationScreen<keyof BottomTabParamList>[] = [
    {
      id: 0,
      name: 'TicketsStack',
      component: TicketStackNavigator,
      icon: ['fal', 'ticket'],
      title: i18n.t('TAB_TICKETS'),
      iconSize: 20,
    },
    {
      id: 1,
      name: 'PasswordsStack',
      component: PasswordsStackNavigator,
      icon: ['fal', 'lock'],
      title: i18n.t('TAB_PASSWORDS'),
      iconSize: 19,
    },
    { id: 2, name: 'SettingsStack', component: SettingsStackNavigator, icon: ['fal', 'gear'], title: i18n.t('TAB_SETTINGS'), iconSize: 20 },
    {
      id: 3,
      name: 'HistoryStack',
      component: HistoryStackNavigator,
      icon: ['fal', 'clock-rotate-left'],
      title: i18n.t('TAB_HISTORY'),
      iconSize: 19,
    },
    {
      id: 4,
      isFixed: true,
      name: 'MoreEmpty',
      component: EmptyMoreScreen,
      icon: ['fal', 'bars'],
      title: i18n.t('TAB_MORE'),
      iconSize: 19,
      listeners: ({ navigation }) => {
        return {
          tabPress: event => {
            event.preventDefault()
            navigation.navigate('More')
          },
        }
      },
    },
    {
      id: 6,
      name: 'PlanningStack',
      component: PlanningStackNavigator,
      icon: ['fal', 'id-badge'],
      title: i18n.t('TAB_PLANNING'),
      iconSize: 19,
    },
    {
      id: 7,
      name: 'SparePartsStack',
      component: SparePartsStackStackNavigator,
      icon: ['fal', 'arrows-repeat'],
      title: i18n.t('TAB_SPAREPARTS'),
      iconSize: 18,
    },
    {
      id: 8,
      name: 'QualityStack',
      component: QualityStackNavigator,
      icon: ['fal', 'badge-check'],
      title: i18n.t('TAB_QUALITY'),
      iconSize: 20,
    },
  ]

  Platform.OS !== 'web' &&
    bottomTabs.push({
      id: 100,
      name: 'SynchronizationStack',
      component: SynchronizationStackNavigator,
      icon: ['fal', 'cloud-arrow-down'],
      title: i18n.t('TAB_SYNCHRONIZATION'),
      iconSize: 21,
    })

  const elements = isSmallDevice ? CONSTANTS.maxNavBarElements : CONSTANTS.maxTabletNavBarElements

  bottomTabs = useMemo(() => {
    return navigationUtils.sortTabs(bottomTabs, elements, menuItems)
  }, [elements, menuItems])

  return (
    <AssetProvider>
      <OptionsProvider>
        <MenuProvider bottomTabs={bottomTabs} maxDynamicElements={elements}>
          <StatusBar backgroundColor="transparent" barStyle={colorScheme === 'dark' ? 'light-content' : 'dark-content'} translucent />
          <AppContent>
            <BottomSheetModalProvider>
              <NavigationContainer basePath={basePath}>
                <RootStack.Navigator
                  screenOptions={{
                    ...screenOptions,
                    navigationBarColor: theme.tabNavigator.background,
                    gestureEnabled: Platform.OS !== 'web', // This is to enable the navigation event listener "beforeRemove" on web
                  }}>
                  {!isLoggedIn ? (
                    <RootStack.Screen name="Login" component={LoginScreen} />
                  ) : (
                    <RootStack.Group>
                      <RootStack.Screen name="BottomTab">{() => <TabNavigator />}</RootStack.Screen>
                      {/* Alternative: https://github.com/th3rdwave/react-navigation-bottom-sheet */}
                      <RootStack.Screen
                        name="More"
                        component={MoreScreen}
                        options={{ presentation: 'containedTransparentModal', animation: 'fade' }}
                      />
                      <RootStack.Screen
                        name="EditNavigation"
                        component={EditNavigationScreen}
                        options={{ presentation: 'containedTransparentModal', animation: 'fade_from_bottom' }}
                      />
                      <RootStack.Screen name="NotFound" component={NotFoundScreen} />
                      <RootStack.Screen
                        name="Scanner"
                        component={ScannerCameraScreen}
                        options={{
                          animation: 'slide_from_bottom',
                          navigationBarColor: '#000000',
                        }}
                      />
                    </RootStack.Group>
                  )}
                </RootStack.Navigator>
              </NavigationContainer>
            </BottomSheetModalProvider>
          </AppContent>
        </MenuProvider>
        <ErrorModal
          controller={controller}
          description={i18n.t('COLLABORATOR_NOT_ASSIGNED')}
          buttons={[
            {
              title: i18n.t('CHECK'),
              loading,
              onPress: loadSettings,
            },
            {
              title: i18n.t('LOGOUT'),
              color: theme.error,
              onPress: () => {
                logout().catch(err => console.error('Logout error', err))
                controller.close()
              },
            },
          ]}
        />
      </OptionsProvider>
    </AssetProvider>
  )
}

export default memo(Navigator)
