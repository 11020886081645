import { IM, IMStyle, useAlert, useLanguage } from '@infominds/react-native-components'
import { useAuthentication } from '@infominds/react-native-license'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { StyleSheet } from 'react-native'

import api from '../../apis/apiCalls'
import { Company } from '../../apis/types/apiResponseTypes'
import useSynchronization from '../../dataProvider/hooks/useSynchronization'
import Text from '../Text'
import { dropdownStyle } from './LanguageSelector'

export type CompanySelectorRef = { close: () => void }

interface Props {
  onReLogIn: () => void
  offline?: boolean
}

const CompanySelector = ({ onReLogIn, offline }: Props) => {
  const { alert } = useAlert()
  const { i18n } = useLanguage()
  const { isLoggedIn, companies: licenseCompanies, company: selectedCompany, updateToken } = useAuthentication()
  const [companies, setCompanies] = useState<Company[]>([])
  const [selectedCompanyId, setSelectedCompanyId] = useState<string | undefined>(undefined)
  const { deleteLocalData } = useSynchronization()

  const originalCompanyId = useRef<string | undefined>(undefined)

  useEffect(() => {
    if (!isLoggedIn) return

    api
      .getCompanies()
      .then(gotCompanies => {
        const filteredArray = gotCompanies.filter(value => licenseCompanies?.includes(value.id))
        setCompanies(filteredArray)

        if (filteredArray.length > 0 && filteredArray.find(el => el.id === selectedCompany)) {
          originalCompanyId.current = selectedCompany
          setSelectedCompanyId(selectedCompany)
        } else {
          console.error('Empty company array loaded from db')
        }
      })
      .catch(console.error)
  }, [isLoggedIn])

  useEffect(() => {
    if (selectedCompanyId !== undefined) {
      const newCompany = companies.find(item => item.id === selectedCompanyId)

      if (newCompany) {
        if (originalCompanyId.current !== newCompany.id) {
          alert(i18n.t('WARNING'), i18n.t('CHANGE_COMPANY_WARNING'), [
            {
              text: i18n.t('CANCEL'),
              style: 'default',
              onPress: () => {
                return setSelectedCompanyId(originalCompanyId.current)
              },
            },
            {
              text: i18n.t('RESTART_APP'),
              style: 'destructive',
              onPress: () => {
                deleteLocalData()
                  .catch(console.error)
                  .finally(() => {
                    updateToken(newCompany.id, undefined)
                      .then(onReLogIn)
                      .catch(err => console.error('Failed company re-auth:', err))
                  })
              },
            },
          ])
        }
      } else {
        console.error(`Company ${selectedCompanyId ?? ''} not found`)
      }
    }
  }, [selectedCompanyId])

  const companyItems = useMemo(
    () =>
      companies.map(company => {
        return { label: company.description, value: company.id }
      }),
    [companies]
  )

  return (
    <>
      {offline ? (
        <IM.View style={styles.offlineMessage}>
          <Text style={{ fontWeight: IMStyle.typography.fontWeightMedium }}>{companies.find(el => el.id === selectedCompany)?.description}</Text>
          {/* eslint-disable-next-line react-native/no-inline-styles */}
          <Text secondary style={{ fontSize: IMStyle.typography.fontSizeRegular - 1, textAlign: 'right' }}>
            {i18n.t('COMPANY_OFFLINE')}
          </Text>
        </IM.View>
      ) : (
        <IM.Dropdown
          value={selectedCompanyId}
          onChange={({ value }) => setSelectedCompanyId(value)}
          data={companyItems}
          containerStyle={dropdownStyle.noShadow}
          style={dropdownStyle.dropdown}
          selectedTextProps={{ numberOfLines: 1 }}
        />
      )}
    </>
  )
}

const styles = StyleSheet.create({
  offlineMessage: { alignItems: 'flex-end', paddingBottom: 10 },
})

export default CompanySelector
