import { useLanguage, useTheme } from '@infominds/react-native-components'
import React, { ForwardedRef, forwardRef, memo, useState } from 'react'
import { Keyboard, StyleSheet, TextStyle } from 'react-native'

import { TextInputRef, ThemeColorExpanded } from '../../types'
import { numberUtils } from '../../utils/numberUtils'
import PressableIcon from '../Infominds/PressableIcon'
import { BaseTextInput, BaseTextInputProps } from './baseTextInput/BaseTextInput'
import { BaseTextInputProvider, BaseTextInputProviderProps } from './baseTextInput/contexts/BaseTextInputContext'

export type NumericInputProps = Omit<BaseTextInputProps, 'type'> &
  BaseTextInputProviderProps & {
    title?: string
    titleFontWeight?: TextStyle['fontWeight']
    details?: string
    disableFastInputs?: boolean
    decimals?: number
    size?: 'small' | 'normal'
    max?: number
    min?: number
    controlled?: boolean
    onPlus?: () => void
    onMinus?: () => void
  }

const NumberInput = memo(
  forwardRef(function NumberInput(
    {
      title,
      titleFontWeight,
      details,
      editable = true,
      error,
      loading,
      disableFocus,
      disableFastInputs,
      size,
      max,
      min,
      decimals,
      controlled = false,
      ...textInputProps
    }: NumericInputProps,
    ref: ForwardedRef<TextInputRef>
  ) {
    const { onPlus, onMinus, onChangeText } = textInputProps
    const { language } = useLanguage()
    const { theme } = useTheme<ThemeColorExpanded>()
    const instance = new Intl.NumberFormat(language, {
      maximumFractionDigits: decimals,
    })

    const [value, setValue] = useState(instance.format(parseFloat(textInputProps.value ?? '0')))

    const disableMinus = (min !== undefined && !!value && parseInt(value, 10) <= min) || !editable ? true : false
    const disableMax = (max !== undefined && !!value && parseInt(value, 10) >= max) || !editable ? true : false
    const opacityMinus = disableMinus ? 0.6 : 1
    const opacityMax = disableMax ? 0.6 : 1
    const marginHorizontal = size === 'small' ? 0 : 3

    const handlePress = (pressType: 'add' | 'subtract') => {
      let newValue = numberUtils.convertLocaleStringToNumber(value, language) ?? 0

      if (pressType === 'add') {
        newValue += 1
      } else {
        newValue -= 1
      }

      const val = checkLimits(newValue)

      setValue(instance.format(val))
      onChangeText?.(val.toString())
    }

    const handleOnBlur = () => {
      if (!value) return

      const number = checkLimits(numberUtils.convertLocaleStringToNumber(value, language) ?? 0)

      setValue(instance.format(number))
      onChangeText?.(number.toString())
    }

    const checkLimits = (newValue: number) => {
      let val = max !== undefined && newValue > max ? max : newValue
      val = min !== undefined && val < min ? min : val

      return val
    }

    return (
      <BaseTextInputProvider editable={editable} error={error} loading={loading} disableFocus={disableFocus}>
        <BaseTextInput
          ref={ref}
          textAlign="right"
          {...textInputProps}
          value={controlled ? textInputProps.value : value}
          onChangeText={controlled ? textInputProps.onChangeText : setValue}
          onBlur={handleOnBlur}
          onSubmitEditing={Keyboard.dismiss}
          type={decimals ? 'decimal' : 'number'}
          style={textInputProps.style}>
          {title && <BaseTextInput.Title title={title} details={details} fontWeight={titleFontWeight} />}
          {!disableFastInputs && (
            <BaseTextInput.LeftIcon
              borderColor={theme.general.error}
              disableBorder={textInputProps.disableBorderRadius}
              style={[{ backgroundColor: theme.general.error, opacity: opacityMinus }, styles.iconContainer]}>
              <PressableIcon
                disabled={disableMinus}
                icon="minus"
                size={18}
                color="white"
                iconStyle={{ marginHorizontal }}
                pressableStyle={styles.pressable}
                onPress={() => {
                  onMinus?.()
                  handlePress('subtract')
                }}
              />
            </BaseTextInput.LeftIcon>
          )}
          {!disableFastInputs && (
            <BaseTextInput.RightIcon
              borderColor={theme.general.info}
              disableBorder={textInputProps.disableBorderRadius}
              style={[{ backgroundColor: theme.general.info, opacity: opacityMax }, styles.iconContainer]}>
              <PressableIcon
                disabled={disableMax}
                icon="plus"
                size={18}
                color="white"
                iconStyle={{ marginHorizontal }}
                pressableStyle={styles.pressable}
                onPress={() => {
                  onPlus?.()
                  handlePress('add')
                }}
              />
            </BaseTextInput.RightIcon>
          )}
        </BaseTextInput>
      </BaseTextInputProvider>
    )
  })
)

export default NumberInput

const styles = StyleSheet.create({
  iconContainer: { paddingHorizontal: 0 },
  pressable: { borderRadius: 0, flex: 1 },
})
