import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { IM } from '@infominds/react-native-components'
import React, { PropsWithChildren } from 'react'
import { StyleSheet } from 'react-native'

import useIsOnline from '../../../dataProvider/hooks/useIsOnline'
import DataManagementIconGroup, { DataManagementIconGroupProps } from '../DataManagementIconGroup'
import HeaderWithPressable from './HeaderWithPressable'

export interface HeaderWithIconsProps extends DataManagementIconGroupProps {
  title: string
  icon?: IconProp
  iconSize?: number
  iconColor?: string
  description?: string
  enabledOffline?: boolean
  onPress?: () => void
}

export default function HeaderWithIcons({
  title,
  icon,
  iconSize,
  iconColor,
  description,
  enabledOffline,
  onPress,
  disabled,
  children,
  ...props
}: PropsWithChildren<HeaderWithIconsProps>) {
  const isOnline = useIsOnline()

  return (
    <IM.View style={styles.container}>
      <HeaderWithPressable title={title} icon={icon} iconSize={iconSize} iconColor={iconColor} description={description} onPress={onPress} />
      <DataManagementIconGroup disabled={disabled || (!enabledOffline && !isOnline)} {...props} />
      {children}
    </IM.View>
  )
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    flex: 1,
    height: '100%',
  },
})
