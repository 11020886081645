import { ModalController } from '@infominds/react-native-components'
import React, { createRef } from 'react'

import { ModalCommonStackParamList } from '../../navigation/navigators/common/ModalCommonNavigator'
import QualityCheckFilterScreen from '../../screens/common/QualityCheckFilterScreen'
import { ModalScreenRef } from '../../types'
import BaseServiceModal from '../BaseServiceModal'

interface Props {
  controller: ModalController<ModalCommonStackParamList['QualityCheckFilters']>
}

export default function QualityCheckFilterModal({ controller }: Props) {
  const ref = createRef<ModalScreenRef>()

  return (
    <BaseServiceModal controller={controller} childRef={ref}>
      <QualityCheckFilterScreen
        ref={ref}
        controller={controller}
        route={{
          key: '',
          name: 'QualityCheckFilters',
        }}
      />
    </BaseServiceModal>
  )
}
