import { NavigationProp, useNavigation } from '@react-navigation/native'
import React, { useRef } from 'react'
import { Animated } from 'react-native'

import { Ticket } from '../../apis/types/apiResponseTypes'
import useMap from '../../components/map/hooks/useMap'
import { TicketStackParamList } from '../../navigation/navigators/bottomTabs/TicketBottomTabNavigator'
import { LoadingType } from '../../types'
import TicketList from './TicketList'

type Props = {
  tickets: (string | Ticket)[]
  loading: LoadingType
  buttonAnimationValue: Animated.Value
  showSmallDeviceCards?: boolean
  reloadTickets: () => void
  onCreate?: () => void
}

export default function TicketListMap({ ...others }: Props) {
  const { selectedItem, moveMapToItem, setSelectedItem } = useMap<Ticket>()
  const navigation = useNavigation<NavigationProp<TicketStackParamList>>()
  const lastSelectedRef = useRef<Ticket | null>(null)

  function handleTicketPressed(ticket: Ticket) {
    lastSelectedRef.current = ticket
    if (selectedItem?.id === ticket.id) {
      navigation.navigate('TicketDetailCommonStack', { screen: 'TicketDetail', params: { ticketId: ticket.id, ticketCode: ticket.code } })
      return
    }

    moveMapToItem(ticket)
    setSelectedItem(ticket)
  }

  return <TicketList onTicketPress={handleTicketPressed} {...others} selectedTicketId={selectedItem?.id} type="normal" view="map" />
}
