import { Dispatch, SetStateAction, useCallback, useEffect, useRef, useState } from 'react'

import { InfiniteLoadingType } from '../../../types'
import { abortUtils } from '../../../utils/abortController'
import appUtils from '../../../utils/appUtils'
import { utils } from '../../../utils/utils'

type Options<T> = {
  id: string
  chuckSize: number
  onLoadedCallback?: (items: T[], reload: boolean) => void
  onErrorCallback?: (reload: boolean) => void
}

export interface SkipTake {
  skip?: number
  take?: number
}

interface Result<TRequest, TResponse> {
  item: TResponse[]
  loading: InfiniteLoadingType
  allDataLoaded: boolean
  loadItem: (request: TRequest) => void
  loadMore: () => void
  setItem: Dispatch<SetStateAction<TResponse[]>>
}

/**
 * Hook manages data for dynamically growing list.
 * Load can be used to load first time or to reload.
 * LoadMore can be used f.ex. onListEndReached
 * @param getData function to get Data
 * @param options loader options
 * @returns {items, loadItems, loading, loadMore, setItems, allDataLoaded}
 */
export default function useInfiniteLoader<TRequest extends SkipTake | undefined, TResponse>(
  getData: (request: TRequest, abortController: AbortController) => Promise<TResponse[]>,
  options: Options<TResponse>
): Result<TRequest, TResponse> {
  const { chuckSize, onLoadedCallback, onErrorCallback } = options

  const hash = useRef(utils.generateUuid())
  const allDataLoaded = useRef(false)
  const request = useRef<TRequest | null>(null)

  const [item, setItem] = useState<TResponse[]>([])
  const [loading, setLoading] = useState<InfiniteLoadingType>('init')
  const [loadCounter, setLoadCounter] = useState(0)
  const [endOfListCounter, setEndOfListCounter] = useState(0)

  useEffect(() => {
    return () => {
      options && options.id && abortUtils.abort(options.id, hash.current)
    }
  }, [])

  useEffect(() => {
    loader(loading === 'reloading')
  }, [loadCounter])

  useEffect(() => {
    loader()
  }, [endOfListCounter])

  const loader = useCallback(
    (reload?: boolean) => {
      if ((!reload && allDataLoaded.current) || !request.current) return null

      let controller: AbortController | undefined

      if (options && options.id) {
        abortUtils.abort(options.id, hash.current)
        controller = abortUtils.add(options.id, hash.current)
      }
      const skip = reload ? 0 : item.length

      getData({ ...request.current, skip, take: chuckSize }, controller ?? new AbortController())
        .then(result => {
          allDataLoaded.current = result.length < chuckSize
          setItem(prev => {
            if (reload) return result ?? []
            prev.push(...result)
            if (onLoadedCallback) onLoadedCallback(prev, !!reload)
            return [...prev]
          })

          setLoading(false)
        })
        .catch(err => {
          if (appUtils.isAbortError(err)) {
            console.debug('Aborted useInfiniteLoader', getData.name, options?.id ?? '-', request.current)
            return
          }

          onErrorCallback?.(!!reload)
          console.error('Failed loader() on useInfiniteLoader', err)

          setLoading('catched')
        })
        .finally(() => {
          options && options.id && abortUtils.remove(options.id)
        })

      return
    },
    [options, endOfListCounter, allDataLoaded, request, item, chuckSize, hash]
  )

  const load = useCallback(
    (requestData: TRequest) => {
      setItem([])
      setLoading('reloading')

      request.current = requestData
      setLoadCounter(prev => prev + 1)
    },
    [request]
  )

  const loadMore = useCallback(() => {
    if (allDataLoaded.current || loading) return
    setLoading('loadMore')

    setEndOfListCounter(prev => prev + 1)
  }, [allDataLoaded, loading])

  return { item, loadItem: load, loading, loadMore, setItem, allDataLoaded: allDataLoaded.current }
}
