import { IM, SpacingProps, useEvent, useLanguage, useModalController } from '@infominds/react-native-components'
import { NavigationProp, useNavigation } from '@react-navigation/native'
import React, { memo, useEffect } from 'react'
import { Platform, StyleSheet } from 'react-native'

import api from '../../apis/apiCalls'
import { SMNote } from '../../apis/types/apiResponseTypes'
import CardLeftTitle from '../../components/card/left/CardLeftTitle'
import CardButton from '../../components/CardButton'
import Error from '../../components/Error'
import useControlledLoader from '../../components/Infominds/hooks/useControlledLoader'
import NoteComponent from '../../components/NoteComponent'
import SkeletonText from '../../components/skeleton/SkeletonText'
import { EDIT_NOTE_DATA_EVENT_KEY } from '../../constants/EmitterKeys'
import { REQUEST_SM_NOTES } from '../../constants/Keys'
import useLayout, { ForceLayoutType } from '../../hooks/useLayout'
import TicketDetailNoteEditOrCreationModal from '../../modals/common/NoteEditOrCreationModal'
import { TicketDetailCommonStackParamList } from '../../navigation/navigators/common/TicketDetailCommonNavigator'
import { NoteEditOrCreateParams, SMNoteType } from '../../types'

interface Props {
  type: SMNoteType
  id: string
  code: string
  description?: string
  spacing?: SpacingProps
  onlyRead?: boolean
  forceLayout: ForceLayoutType | undefined
}

const NotesCard = memo(function NotesCard({ type, id, code, description, spacing, onlyRead, forceLayout }: Props) {
  const { i18n } = useLanguage()
  const { isSmallDevice } = useLayout(false, forceLayout)
  const controller = useModalController<NoteEditOrCreateParams>()
  const navigation = useNavigation<NavigationProp<TicketDetailCommonStackParamList>>()
  const { item: notes, loadItem, loading } = useControlledLoader(api.getSMNotes, { id: REQUEST_SM_NOTES })

  useEvent({ key: EDIT_NOTE_DATA_EVENT_KEY + type }, () => refresh())

  useEffect(() => {
    refresh()
  }, [id])

  const refresh = () => loadItem({ type, fkId: id })

  const handleAdd = () => {
    const data: NoteEditOrCreateParams = { id, type }
    if (isSmallDevice && (Platform.OS === 'android' || Platform.OS === 'ios')) {
      navigation.navigate('TicketDetailModalCommonStack', { screen: 'NoteEditOrCreate', params: data })
    } else {
      controller.show(data)
    }
  }

  const handleEdit = (note: SMNote) => {
    const data: NoteEditOrCreateParams = { id, note, type }
    if (isSmallDevice && (Platform.OS === 'android' || Platform.OS === 'ios')) {
      navigation.navigate('TicketDetailModalCommonStack', { screen: 'NoteEditOrCreate', params: data })
    } else {
      controller.show(data)
    }
  }

  return (
    <>
      <IM.Card noContentSpacing head={<CardLeftTitle text={i18n.t('NOTES')} />} spacing={spacing}>
        {loading === false ? (
          <>
            {notes?.length === 0 ? (
              <IM.View style={styles.container}>
                <IM.Text secondary>{i18n.t('NO_NOTES')}</IM.Text>
              </IM.View>
            ) : (
              <>
                {notes?.map((note, index) => (
                  <NoteComponent
                    key={note.id}
                    note={note}
                    index={index}
                    type={type}
                    isLast={index === notes.length - 1}
                    onPress={handleEdit}
                    code={code}
                    description={description}
                    onlyRead={onlyRead}
                    forceLayout={forceLayout}
                  />
                ))}
              </>
            )}
            {!onlyRead && (
              // eslint-disable-next-line react-native/no-inline-styles
              <IM.View style={{ margin: 12, marginTop: 6 }}>
                <CardButton onPress={handleAdd} disableSeparator />
              </IM.View>
            )}
          </>
        ) : (
          <IM.View style={styles.container}>
            {loading === 'catched' ? (
              <Error />
            ) : (
              <>
                <SkeletonText width="50%" />
                <SkeletonText width="80%" spacing="top" />
                <SkeletonText width="100%" spacing="top" />
              </>
            )}
          </IM.View>
        )}
      </IM.Card>
      <TicketDetailNoteEditOrCreationModal controller={controller} />
    </>
  )
})

export default NotesCard

const styles = StyleSheet.create({
  container: {
    margin: 12,
  },
})
