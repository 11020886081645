import { useLanguage } from '@infominds/react-native-components'
import { useNavigation } from '@react-navigation/native'
import React, { createRef, ForwardedRef, forwardRef, useImperativeHandle } from 'react'
import { Platform } from 'react-native'

import { Screen } from '../../components/screen/Screen'
import useFilter from '../../hooks/useFilter'
import useLayout from '../../hooks/useLayout'
import { ModalCommonStackParamList, ModalCommonStackScreenProps } from '../../navigation/navigators/common/ModalCommonNavigator'
import { ModalScreenProps, ModalScreenRef } from '../../types'
import FilterView, { FilterViewRef } from '../../views/common/FilterView'

const FilterScreen = (
  { controller }: Omit<ModalCommonStackScreenProps<'Filter'>, 'navigation'> & ModalScreenProps<ModalCommonStackParamList['Filter']>,
  ref: ForwardedRef<ModalScreenRef>
) => {
  useImperativeHandle(ref, () => ({
    handleGoBack: () => handleGoBack(),
  }))

  const { i18n } = useLanguage()
  const navigation = useNavigation()
  const { isSmallDevice } = useLayout()
  const { filters, groups, orders, setFilters, setGroups, setOrders } = useFilter()

  const viewRef = createRef<FilterViewRef>()

  const handleGoBack = () => {
    if (viewRef.current) {
      setFilters(viewRef.current.getState().filters)
      setGroups(viewRef.current.getState().groups)
      setOrders(viewRef.current.getState().orders)
    }

    if (isSmallDevice && (Platform.OS === 'android' || Platform.OS === 'ios')) {
      navigation.goBack()
    } else {
      controller?.close()
    }
  }

  const isModal = controller !== undefined

  return (
    <Screen transparent={isModal}>
      <Screen.Header goBack={handleGoBack} backHandlerCallback={handleGoBack} isModal={isModal} title={i18n.t('FILTERS')} />
      <Screen.Content>
        <FilterView ref={viewRef} filters={filters} groups={groups} orders={orders} />
      </Screen.Content>
    </Screen>
  )
}

export default forwardRef(FilterScreen)
