import { CompositeScreenProps, NavigatorScreenParams } from '@react-navigation/native'
import { createNativeStackNavigator, NativeStackScreenProps } from '@react-navigation/native-stack'
import React from 'react'
import { useSetRecoilState } from 'recoil'

import { SparePartsFilterProvider } from '../../../contexts/SparePartsFilterContext'
import SparePartsScreen from '../../../screens/spareParts/SparePartsScreen'
import { sparePartsScreenFilterEnableAtom } from '../../../utils/stateManager'
import { screenOptions } from '../../Navigator'
import { AppBottomTabScreenProps, BottomTabParamList } from '../../types'
import { ModalCommonStackNames, ModalCommonStackNavigator, ModalCommonStackParamList } from '../common/ModalCommonNavigator'

export type SparePartsStackParamList = {
  SpareParts: undefined
  SparePartsModalCommonStack: NavigatorScreenParams<ModalCommonStackParamList>
}

export const SparePartsStackNames: {
  path?: string
  screens: Record<keyof SparePartsStackParamList, string | typeof ModalCommonStackNames>
} = {
  path: 'spareparts',
  screens: {
    SpareParts: 'list',
    SparePartsModalCommonStack: ModalCommonStackNames,
  },
}

export type SparePartsStackScreenProps<T extends keyof SparePartsStackParamList> = CompositeScreenProps<
  NativeStackScreenProps<SparePartsStackParamList, T>,
  AppBottomTabScreenProps<keyof BottomTabParamList>
>

const SparePartsStack = createNativeStackNavigator<SparePartsStackParamList>()
export function SparePartsStackStackNavigator() {
  const enableFilter = useSetRecoilState(sparePartsScreenFilterEnableAtom)

  return (
    <SparePartsFilterProvider storageKeyUniqueId="SparePartsStack" disableCompatibilityFilter onEnableFilter={enableFilter}>
      <SparePartsStack.Navigator screenOptions={screenOptions}>
        <SparePartsStack.Screen name="SpareParts" component={SparePartsScreen} />
        <SparePartsStack.Screen name="SparePartsModalCommonStack" component={ModalCommonStackNavigator} />
      </SparePartsStack.Navigator>
    </SparePartsFilterProvider>
  )
}
