import { IM, IMLayout, SpacingProps, useDimensions, useTheme } from '@infominds/react-native-components'
import React from 'react'
import { StyleSheet } from 'react-native'

import { SerialNumberState, ThemeColorExpanded } from '../types'
import ticketUtils from '../utils/TicketUtils'

interface Props {
  state: SerialNumberState
  spacing?: SpacingProps
}

export default function MonitoringState({ state, spacing }: Props) {
  const { isSmallDevice } = useDimensions()
  const { theme } = useTheme<ThemeColorExpanded>()

  const color = ticketUtils.getSNState(state, theme)

  return (
    <IM.View style={styles.container} spacing={spacing}>
      <IM.Icon icon={['fal', 'display-chart-up']} spacing="right" color={theme.text.default} />
      <IM.View
        style={[
          styles.indicator,
          {
            backgroundColor: color,
            marginRight: 2 * IMLayout.horizontalMargin,
          },
        ]}
        spacing={isSmallDevice ? 'none' : 'right'}
      />
    </IM.View>
  )
}

const styles = StyleSheet.create({
  container: { flexDirection: 'row', alignItems: 'center' },
  indicator: { width: 15, height: 15, borderRadius: IMLayout.iconRadius },
})
