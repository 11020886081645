import { NavigatorScreenParams } from '@react-navigation/native'
import { createNativeStackNavigator, NativeStackScreenProps } from '@react-navigation/native-stack'

import { BottomTabParamList } from '../types'

export type RootStackScreenProps<T extends keyof RootStackParamList> = NativeStackScreenProps<RootStackParamList, T>

export type RootStackParamList = {
  BottomTab: NavigatorScreenParams<BottomTabParamList>
  Login: undefined
  More: undefined
  EditNavigation: undefined
  NotFound: undefined
  Scanner: { id: string | undefined }
}

export const RootStack = createNativeStackNavigator<RootStackParamList>()
