import { IM, IMLayout, useDimensions, useOrientation } from '@infominds/react-native-components'
import React, { ReactNode } from 'react'
import { StyleProp, StyleSheet, ViewStyle } from 'react-native'

import CONSTANTS from '../../../../constants/Constants'
import useIsOnline from '../../../../dataProvider/hooks/useIsOnline'
import useKeyPress from '../../../../hooks/useKeyPress'
import { DividerType } from '../../../../types'
import Divider from '../../../Divider'
import HeaderSearchIcon from '../../HeaderSearchIcon'
import useSearch from '../../hooks/useSearch'
import { DetailHeaderType } from '../../types'
import DefaultSearchDetailHeader, { DefaultSearchDetailHeaderProps } from '../default/DefaultSearchDetailHeader'

export type SearchDetailHeaderProps = (Default | Custom) & {
  containerStyle?: StyleProp<ViewStyle>
  dividers?: DividerType[]
  iconPosition?: DividerType
  enabledOffline?: boolean
}

type Default = DefaultSearchDetailHeaderProps & {
  children?: never
}

type Custom = {
  children: ReactNode
}

export default function SearchDetailHeaderContainer({
  children,
  containerStyle,
  dividers,
  iconPosition = 'left',
  enabledOffline,
  ...props
}: SearchDetailHeaderProps) {
  const { isSmallDevice } = useDimensions()
  const { landscape } = useOrientation()
  const { tabletOpen, setTabletOpen } = useSearch()
  const isOnline = useIsOnline()

  useKeyPress(
    {
      key: ['F2'],
      cb: () => {
        !isSmallDevice && setTabletOpen(!tabletOpen)
      },
    },
    [isSmallDevice, tabletOpen]
  )

  const renderItem = () => children ?? <DefaultSearchDetailHeader focus={isSmallDevice ? undefined : tabletOpen} {...props} />

  const icon = () => <HeaderSearchIcon onPress={() => setTabletOpen(!tabletOpen)} disabled={!enabledOffline && !isOnline} />

  return (
    <>
      {!isSmallDevice ? (
        <IM.View style={styles.container}>
          {!isSmallDevice && dividers && dividers.find(divider => divider === 'left') && <Divider />}
          {iconPosition === 'left' && icon()}
          {tabletOpen && (
            <IM.View
              style={[
                {
                  maxWidth: landscape ? CONSTANTS.landscapeTabletMaxSearchBar : CONSTANTS.portraitTabletMaxSearchBar,
                },
                styles.spacing,
                containerStyle,
              ]}>
              {renderItem()}
            </IM.View>
          )}
          {iconPosition === 'right' && icon()}
          {!isSmallDevice && dividers && dividers.find(divider => divider === 'right') && <Divider />}
        </IM.View>
      ) : (
        <IM.View style={styles.sdSpacing}>{renderItem()}</IM.View>
      )}
    </>
  )
}

SearchDetailHeaderContainer.type = DetailHeaderType.SEARCH

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    height: '100%',
  },
  spacing: {
    marginHorizontal: 6,
  },
  sdSpacing: {
    marginHorizontal: IMLayout.horizontalMargin,
    flex: 1,
  },
})
