import React, { ForwardedRef, forwardRef, memo } from 'react'
import { Platform, TextStyle } from 'react-native'

import { TextInputRef } from '../../types'
import { BaseTextInput, BaseTextInputProps } from './baseTextInput/BaseTextInput'
import BaseTextInputSpinningIcon from './baseTextInput/BaseTextInputSpinningIcon'
import { BaseTextInputProvider, BaseTextInputProviderProps } from './baseTextInput/contexts/BaseTextInputContext'
import { MultilineBaseTextInput } from './baseTextInput/MultilineBaseTextInput'

export type TextInputProps = BaseTextInputProps &
  BaseTextInputProviderProps & {
    title?: string
    titleFontWeight?: TextStyle['fontWeight']
    details?: string
    detailsNumberOfLines?: number
  }

const TextInput = memo(
  forwardRef(function TextInput(
    { title, titleFontWeight, details, editable, error, loading, disableFocus, detailsNumberOfLines, ...textInputProps }: TextInputProps,
    ref: ForwardedRef<TextInputRef>
  ) {
    return (
      <BaseTextInputProvider editable={editable} error={error} loading={loading} disableFocus={disableFocus}>
        {Platform.OS === 'web' && textInputProps.multiline ? (
          <MultilineBaseTextInput ref={ref} {...textInputProps}>
            {title && (
              <MultilineBaseTextInput.Title
                title={title}
                details={details}
                detailsNumberOfLines={detailsNumberOfLines}
                fontWeight={titleFontWeight}
              />
            )}
          </MultilineBaseTextInput>
        ) : (
          <BaseTextInput ref={ref} {...textInputProps}>
            {title && (
              <BaseTextInput.Title title={title} details={details} detailsNumberOfLines={detailsNumberOfLines} fontWeight={titleFontWeight} />
            )}
            {loading && (
              <BaseTextInput.RightIcon disableBorder={textInputProps.disableBorderRadius}>
                {loading && <BaseTextInputSpinningIcon loading={loading} />}
              </BaseTextInput.RightIcon>
            )}
            {typeof error !== 'boolean' && error?.value && <BaseTextInput.Error message={error.message} />}
          </BaseTextInput>
        )}
      </BaseTextInputProvider>
    )
  })
)

export default TextInput
