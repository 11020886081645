import { CompositeScreenProps, NavigatorScreenParams } from '@react-navigation/native'
import { createNativeStackNavigator, NativeStackScreenProps } from '@react-navigation/native-stack'
import React from 'react'

import { FilterProvider } from '../../../contexts/FilterContext'
import HistoryScreen from '../../../screens/history/HistoryScreen'
import { screenOptions } from '../../Navigator'
import { AppBottomTabScreenProps, BottomTabParamList } from '../../types'
import { ActivityCommonStackNames, ActivityCommonStackNavigator, ActivityCommonStackParamList } from '../common/ActivityCommonNavigator'
import { ModalCommonStackNames, ModalCommonStackNavigator, ModalCommonStackParamList } from '../common/ModalCommonNavigator'
import {
  TicketDetailCommonStackNames,
  TicketDetailCommonStackNavigator,
  TicketDetailCommonStackParamList,
} from '../common/TicketDetailCommonNavigator'

export type HistoryStackParamList = {
  History: undefined
  HistoryActivityCommonStack: NavigatorScreenParams<ActivityCommonStackParamList>
  HistoryModalCommonStack: NavigatorScreenParams<ModalCommonStackParamList>
  HistoryTicketDetailCommonStack: NavigatorScreenParams<TicketDetailCommonStackParamList>
}

export const HistoryStackNames: {
  path?: string
  screens: Record<
    keyof HistoryStackParamList,
    string | typeof ActivityCommonStackNames | typeof ModalCommonStackNames | typeof TicketDetailCommonStackNames
  >
} = {
  path: 'history',
  screens: {
    History: 'list',
    HistoryActivityCommonStack: ActivityCommonStackNames,
    HistoryModalCommonStack: ModalCommonStackNames,
    HistoryTicketDetailCommonStack: TicketDetailCommonStackNames,
  },
}

export type HistoryStackScreenProps<T extends keyof HistoryStackParamList> = CompositeScreenProps<
  NativeStackScreenProps<HistoryStackParamList, T>,
  AppBottomTabScreenProps<keyof BottomTabParamList>
>

const HistoryStack = createNativeStackNavigator<HistoryStackParamList>()
export function HistoryStackNavigator() {
  return (
    <FilterProvider disableOrderByTown disableOrderByPlan disableOrderByPriority disableFilterByPlanDate storageKeyUniqueId="HistoryStack">
      <HistoryStack.Navigator screenOptions={screenOptions}>
        <HistoryStack.Screen name="History" component={HistoryScreen} />
        <HistoryStack.Screen name="HistoryActivityCommonStack" component={ActivityCommonStackNavigator} />
        <HistoryStack.Screen name="HistoryModalCommonStack" component={ModalCommonStackNavigator} />
        <HistoryStack.Screen name="HistoryTicketDetailCommonStack" component={TicketDetailCommonStackNavigator} />
      </HistoryStack.Navigator>
    </FilterProvider>
  )
}
