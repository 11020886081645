import { createNativeStackNavigator } from '@react-navigation/native-stack'
import React from 'react'

import SynchronizationScreen from '../../../screens/synchronization/SynchronizationScreen'
import { screenOptions } from '../../Navigator'

export type SynchronizationStackParamList = {
  Synchronization: undefined
}

export const SynchronizationStackNames: {
  path?: string
  screens: Record<keyof SynchronizationStackParamList, string>
} = {
  screens: {
    Synchronization: 'sync',
  },
}

const SynchronizationStack = createNativeStackNavigator<SynchronizationStackParamList>()
export function SynchronizationStackNavigator() {
  return (
    <SynchronizationStack.Navigator screenOptions={{ ...screenOptions, gestureEnabled: false }}>
      <SynchronizationStack.Screen name="Synchronization" component={SynchronizationScreen} />
    </SynchronizationStack.Navigator>
  )
}
