import { useEvent } from '@infominds/react-native-components'
import { useCallback, useEffect } from 'react'

import { getApi } from '../apis/apiCalls'
import useRequest from '../components/Infominds/hooks/useRequest'
import { REFRESH_SERIAL_NUMBER_VIEW_EVENT_KEY, REFRESH_TICKET_ARTICLES_EVENT_KEY } from '../constants/EmitterKeys'
import { useDataProvider } from '../dataProvider/hooks/useDataProvider'
import { UploadStatus } from '../types'
import { TicketSerialNumberCreateState } from '../views/ticket/TicketSerialNumberCreateView'

type Props = {
  multiple: boolean
  customerId: string
  ticketId: string
  customerShippingAddressId: string | undefined
  state: TicketSerialNumberCreateState
  uploadStatus: UploadStatus
  onDone: (times: number) => void
  onUploadStatusChange: (newStatus: UploadStatus) => void
}

export default function useCreateSerialNumber({
  multiple,
  customerId,
  ticketId,
  customerShippingAddressId,
  state,
  uploadStatus,
  onDone,
  onUploadStatusChange,
}: Props) {
  const { client } = useDataProvider()

  const { request: create, loading: loading } = useRequest(getApi(client).createSerialNumber)
  const { request: createMultiple, loading: loadingMultiple } = useRequest(getApi(client).addTicketArticle)

  const { emit: emitTicketArticle } = useEvent<boolean>({ key: REFRESH_TICKET_ARTICLES_EVENT_KEY })
  const { emit } = useEvent({ key: REFRESH_SERIAL_NUMBER_VIEW_EVENT_KEY })

  useEffect(() => {
    if (uploadStatus !== 'uploading') return

    if (multiple) {
      if (loadingMultiple === 'catched') return onUploadStatusChange('waiting')

      if (loadingMultiple === false) {
        emitTicketArticle(true)
        onUploadStatusChange('done')
        onDone(multiple ? 2 : 1)
      }
    } else {
      if (loading === 'catched') return onUploadStatusChange('waiting')

      if (loading === false) {
        emit()
        onUploadStatusChange('done')
        onDone(multiple ? 2 : 1)
      }
    }
  }, [multiple, uploadStatus, loading, loadingMultiple])

  const handleRequest = useCallback(() => {
    if (state.articleId === undefined) return

    if (multiple) {
      onUploadStatusChange('uploading')
      createMultiple({ ticketId, articleId: state.articleId, serialNumberQuantityToCreate: state.quantity })
    } else {
      if (state.number === undefined) return

      onUploadStatusChange('uploading')
      create({
        active: true,
        articleId: state.articleId,
        number: state.number,
        note: state.note,
        numberManufactor: state.numberManufactor,
        customerId,
        customerShippingAddressId,
      })
    }
  }, [state, multiple, customerId, ticketId, customerShippingAddressId])

  return { upload: handleRequest }
}
