import { IM, SpacingProps, useLanguage } from '@infominds/react-native-components'
import React, { memo } from 'react'

import CardLeftTitle from '../../components/card/left/CardLeftTitle'
import TextInput from '../../components/input/TextInput'
import EmployeeSelector from '../../components/selectors/EmployeeSelector'
import useNextActivity from '../../hooks/useNextActivity'

type Props = {
  spacing?: SpacingProps
}

const ActivityCloseNextCard = memo(function ActivityCloseNextCard({ spacing }: Props) {
  const { i18n } = useLanguage()
  const { employeeId, setEmployeeId, value, setValue } = useNextActivity()

  return (
    <IM.Card spacing={spacing} head={<CardLeftTitle text={i18n.t('NOTE_NEXT_ACTIVITY')} />}>
      <EmployeeSelector
        id={employeeId}
        mandatory
        type="followUp"
        onChange={val => setEmployeeId(val?.id)}
        onDefaultFound={setEmployeeId}
        spacing="bottom"
      />
      <TextInput
        title={i18n.t('TECHNICAL_DESCRIPTION') + ' *'}
        value={value}
        onChangeText={val => setValue(val === '' ? undefined : val)}
        spacing="top"
        multiline
        numberOfLines={10}
      />
    </IM.Card>
  )
})

export default ActivityCloseNextCard
