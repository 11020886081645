import { IMLayout, useEvent, useLanguage } from '@infominds/react-native-components'
import React, { useEffect, useMemo } from 'react'
import { SectionListRenderItemInfo } from 'react-native'

import api from '../../apis/apiCalls'
import { SerialNumber } from '../../apis/types/apiResponseTypes'
import SerialNumberCard from '../../cards/common/SerialnumberCard'
import useInfiniteLoader from '../../components/Infominds/hooks/useInfiniteLoader'
import useSearch from '../../components/screen/hooks/useSearch'
import SectionList from '../../components/SectionList'
import SkeletonText from '../../components/skeleton/SkeletonText'
import CONSTANTS from '../../constants/Constants'
import { REFRESH_SERIAL_NUMBER_VIEW_EVENT_KEY } from '../../constants/EmitterKeys'
import { REQUEST_TICKET_SERIAL_NUMBER } from '../../constants/Keys'
import { ListSection } from '../../types'

interface Props {
  customerId: string
  ticketId: string
  shippingAddressId: string | undefined
  destinationFilter: boolean
  onChange: (serialNumberId: string, articleId: string) => void
}

export default function TicketAddSerialNumberView({ customerId, ticketId, destinationFilter, shippingAddressId, onChange }: Props) {
  const { i18n } = useLanguage()
  const { search } = useSearch()

  const {
    item: serials,
    loadItem,
    allDataLoaded,
    loadMore,
    loading,
  } = useInfiniteLoader(api.getSerialNumber, {
    chuckSize: CONSTANTS.defaultChuckSize,
    id: REQUEST_TICKET_SERIAL_NUMBER,
  })

  useEvent({ key: REFRESH_SERIAL_NUMBER_VIEW_EVENT_KEY }, () => refresh(destinationFilter, shippingAddressId, search))

  useEffect(() => {
    refresh(destinationFilter, shippingAddressId)
  }, [])

  useEffect(() => {
    refresh(destinationFilter, shippingAddressId, search)
  }, [search, destinationFilter, shippingAddressId])

  const refresh = (destFilter: boolean, destId: string | undefined, searchText?: string) =>
    loadItem({ customerId, shippingAddressId: destFilter ? destId : undefined, searchText, devicesOnly: true, ticketIdToIgnore: ticketId })

  const render = ({ item }: SectionListRenderItemInfo<SerialNumber, ListSection<SerialNumber>>) => {
    return <SerialNumberCard item={item} onPress={() => handlePress(item.id, item.article.id)} spacing={['top', 'horizontal']} />
  }

  const handlePress = (id: string, articleId: string) => {
    onChange(id, articleId)
  }

  const sections: ListSection<SerialNumber>[] = useMemo(() => {
    const displayData: ListSection<SerialNumber>[] = []

    if (serials?.length && loading !== 'reloading' && loading !== 'aborted') {
      displayData.push({
        data: serials,
      })
    }

    return displayData
  }, [serials, loading])

  return (
    <SectionList
      sections={sections}
      loading={loading}
      renderItem={render}
      noDataMessage={i18n.t('NO_SN_FOUND')}
      skeletonElements={CONSTANTS.skeletonCards}
      skeletonComponent={<SkeletonText height={20} width="100%" spacing="top" />}
      skeletonTopSpacing
      onRefresh={() => refresh(destinationFilter, shippingAddressId)}
      onLoadMore={loadMore}
      allDataLoaded={allDataLoaded}
      contentContainerStyle={{ margin: IMLayout.verticalMargin, paddingBottom: 3 * IMLayout.verticalMargin }}
    />
  )
}
