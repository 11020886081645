import { CompositeScreenProps, NavigatorScreenParams } from '@react-navigation/native'
import { createNativeStackNavigator, NativeStackScreenProps } from '@react-navigation/native-stack'
import React from 'react'

import { FilterProvider } from '../../../contexts/FilterContext'
import PlanningScreen from '../../../screens/planning/PlanningScreen'
import { screenOptions } from '../../Navigator'
import { AppBottomTabScreenProps, BottomTabParamList } from '../../types'
import { ActivityCommonStackNames, ActivityCommonStackNavigator, ActivityCommonStackParamList } from '../common/ActivityCommonNavigator'
import { ModalCommonStackNames, ModalCommonStackNavigator, ModalCommonStackParamList } from '../common/ModalCommonNavigator'
import {
  TicketDetailCommonStackNames,
  TicketDetailCommonStackNavigator,
  TicketDetailCommonStackParamList,
} from '../common/TicketDetailCommonNavigator'

export type PlanningStackParamList = {
  Planning: undefined
  PlanningActivityCommonStack: NavigatorScreenParams<ActivityCommonStackParamList>
  PlanningTicketDetailCommonStack: NavigatorScreenParams<TicketDetailCommonStackParamList>
  PlanningModalCommonStack: NavigatorScreenParams<ModalCommonStackParamList>
}

export const PlanningStackNames: {
  path?: string
  screens: Record<
    keyof PlanningStackParamList,
    string | typeof ActivityCommonStackNames | typeof ModalCommonStackNames | typeof TicketDetailCommonStackNames
  >
} = {
  path: 'planning',
  screens: {
    Planning: 'list',
    PlanningActivityCommonStack: ActivityCommonStackNames,
    PlanningTicketDetailCommonStack: TicketDetailCommonStackNames,
    PlanningModalCommonStack: ModalCommonStackNames,
  },
}

export type PlanningStackScreenProps<T extends keyof PlanningStackParamList> = CompositeScreenProps<
  NativeStackScreenProps<PlanningStackParamList, T>,
  AppBottomTabScreenProps<keyof BottomTabParamList>
>

const PlanningStack = createNativeStackNavigator<PlanningStackParamList>()
export function PlanningStackNavigator() {
  return (
    <FilterProvider disableOrderByPriority storageKeyUniqueId="PlanningStack">
      <PlanningStack.Navigator screenOptions={screenOptions}>
        <PlanningStack.Screen name="Planning" component={PlanningScreen} />
        <PlanningStack.Screen name="PlanningActivityCommonStack" component={ActivityCommonStackNavigator} />
        <PlanningStack.Screen name="PlanningModalCommonStack" component={ModalCommonStackNavigator} />
        <PlanningStack.Screen name="PlanningTicketDetailCommonStack" component={TicketDetailCommonStackNavigator} />
      </PlanningStack.Navigator>
    </FilterProvider>
  )
}
