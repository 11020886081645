import { IM, IMLayout, PressableIconProps, useTheme } from '@infominds/react-native-components'
import React from 'react'

import { ThemeColorExpanded } from '../../types'
import useScreen from './hooks/useScreen'
import { DetailHeaderType } from './types'

interface Props {
  type?: DetailHeaderType
  changesView?: boolean
  highlight?: boolean
}

const HeaderIcon = ({
  type,
  highlight,
  size = 19,
  ...props
}: Pick<PressableIconProps, 'size' | 'icon' | 'disabled' | 'badge' | 'onPress'> & Props) => {
  const { theme } = useTheme<ThemeColorExpanded>()
  const { detail, setDetailOpen } = useScreen()

  return (
    <IM.View
      style={
        detail.open &&
        detail.type === type && {
          backgroundColor: theme.header.detail.background,
          borderRadius: IMLayout.iconRadius,
        }
      }>
      <IM.PressableIcon
        color={highlight ? theme.general.info : theme.header.main.text.primary}
        onPress={() => (type ? setDetailOpen(type) : undefined)}
        hitSlop={{ top: 10, bottom: 8 }}
        size={size}
        disabledColor={theme.text.detail}
        {...props}
      />
    </IM.View>
  )
}

export default HeaderIcon
