import { useAlert, useLanguage } from '@infominds/react-native-components'
import { useAuthentication } from '@infominds/react-native-license'
import React, { createContext, ReactNode, useEffect, useMemo, useState } from 'react'
import { useRecoilValue } from 'recoil'

import api from '../apis/apiCalls'
import type { UserSettings } from '../apis/types/apiResponseTypes'
import useControlledLoader from '../components/Infominds/hooks/useControlledLoader'
import { REQUEST_MENU_ITEMS, REQUEST_USER_SETTINGS } from '../constants/Keys'
import { LoadingType, MenuItems } from '../types'
import { revalidateSettingsAtom } from '../utils/stateManager'

interface UserSettingsContextType {
  userSettings: Omit<UserSettings, 'id' | 'menuItems'> | undefined
  menuItems: MenuItems | undefined
  loading: LoadingType
  loadSettings: () => void
}

export const UserSettingsContext = createContext<UserSettingsContextType | undefined>(undefined)

type Props = { children: ReactNode | (({ userSettingsReady }: { userSettingsReady: boolean }) => ReactNode) }

export const UserSettingsProvider = ({ children }: Props) => {
  const { i18n } = useLanguage()
  const { isLoggedIn, logout } = useAuthentication()
  const alert = useAlert()

  const {
    item: settings,
    loadItem: loadSettings,
    loading,
    setItem: setSettings,
  } = useControlledLoader(api.getUserSettings, {
    id: REQUEST_USER_SETTINGS,
    onResult: result => {
      if (result) return
      setError(true)
    },
    onErrorCallback: () => setError(true),
  })
  const {
    item: menu,
    loadItem: loadMenu,
    loading: loadingMenu,
  } = useControlledLoader(api.getMenuItems, {
    id: REQUEST_MENU_ITEMS,
    onResult: result => {
      if (result) return
      setError(true)
    },
    onErrorCallback: () => setError(true),
  })

  const [error, setError] = useState(false)
  const [userSettings, setUserSettings] = useState<Omit<UserSettings, 'id'> | undefined>(undefined)
  const [menuItems, setMenuItems] = useState<MenuItems | undefined>(undefined)
  const revalidate = useRecoilValue(revalidateSettingsAtom)

  useEffect(() => {
    error && handleFailedToLoad()
  }, [error])

  useEffect(() => {
    if (isLoggedIn) {
      reload()
    }
  }, [isLoggedIn, revalidate])

  useEffect(() => {
    if (loading === false && settings !== null) {
      setUserSettings(settings)
    }
  }, [settings, loading])

  useEffect(() => {
    if (loadingMenu === false && menu) {
      setMenuItems(menu)
    }
  }, [menu, loadingMenu])

  const reload = () => {
    setError(false)
    setUserSettings(undefined)
    setMenuItems(undefined)
    setSettings(undefined)
    loadSettings()
    loadMenu()
  }

  function handleFailedToLoad() {
    alert.alert(i18n.t('ERROR'), i18n.t('FAILED_TO_LOAD_USER_SETTINGS'), [
      {
        style: 'destructive',
        onPress: () => {
          logout().catch(console.error)
        },
        text: i18n.t('LOGOUT'),
      },
      {
        style: 'cancel',
        onPress: reload,
        text: i18n.t('RETRY'),
      },
    ])
  }

  const contextValue = useMemo(
    () => ({
      userSettings,
      menuItems,
      loading,
      loadSettings: reload,
    }),
    [loading, userSettings, menuItems]
  )

  return (
    <UserSettingsContext.Provider value={contextValue}>
      {typeof children === 'function'
        ? children({
            userSettingsReady: userSettings !== undefined && menuItems !== undefined,
          })
        : children}
    </UserSettingsContext.Provider>
  )
}
