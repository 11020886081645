import { IM, useEvent, useLanguage, useTheme } from '@infominds/react-native-components'
import cloneDeep from 'lodash/cloneDeep'
import React, { ForwardedRef, forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react'
import { StyleSheet } from 'react-native'

import { getApi } from '../../apis/apiCalls'
import { PostPurchaseOrderRequest } from '../../apis/types/apiRequestTypes'
import useRequest from '../../components/Infominds/hooks/useRequest'
import PressableTextIcon from '../../components/Infominds/PressableTextIcon'
import TextInput from '../../components/input/TextInput'
import ScrollViewForm from '../../components/ScrollViewForm'
import ArticleSelector from '../../components/selectors/ArticleSelector'
import SparePartQuantityInput from '../../components/SparePartQuantityInput'
import { EDIT_ACTIVITY_PURCHASE_ORDER_EVENT_KEY } from '../../constants/EmitterKeys'
import { useDataProvider } from '../../dataProvider/hooks/useDataProvider'
import useForm from '../../hooks/useForm'
import { EditOrCreateViewProps, EditOrCreateViewRef, PurchaseOrderMovement, ThemeColorExpanded, UploadStatus } from '../../types'

type Props = { ticketId: string } & EditOrCreateViewProps

const ActivityPurchaseOrderCreateView = ({ ticketId, onUploadStatus }: Props, ref: ForwardedRef<EditOrCreateViewRef>) => {
  useImperativeHandle(ref, () => ({
    handleUpload: upload,
  }))

  const { client } = useDataProvider()

  const { request: create, loading: loadingCreate } = useRequest(getApi(client).createOrder)

  const initialData = useRef<PurchaseOrderMovement[]>([{ articleid: '', quantity: 0 }])

  const { error } = useForm()
  const { i18n } = useLanguage()
  const { theme } = useTheme<ThemeColorExpanded>()

  const [state, setState] = useState(initialData.current)
  const [waitingUpload, setWaitingUpload] = useState<UploadStatus>('done')

  const { emit } = useEvent({ key: EDIT_ACTIVITY_PURCHASE_ORDER_EVENT_KEY })

  useEffect(() => {
    let uploadStatus: UploadStatus = 'done'
    let equal = error

    if (!error) {
      state.forEach(elem => {
        if (!equal) {
          equal = elem.articleid === '' || elem.quantity === 0
        }
      })
    }

    if (!equal) {
      uploadStatus = 'waiting'
    }

    updateUploadStatus(uploadStatus)
  }, [state, error])

  useEffect(() => {
    if (loadingCreate === 'catched') {
      updateUploadStatus('waiting')

      return
    }

    if (waitingUpload !== 'done' && loadingCreate === false) {
      updateUploadStatus('done')
      emit()
    }
  }, [loadingCreate])

  const updateUploadStatus = (newStatus: UploadStatus) => {
    setWaitingUpload(newStatus)
    onUploadStatus(newStatus)
  }

  const upload = () => {
    if (state === undefined) return

    updateUploadStatus('uploading')

    const data: PostPurchaseOrderRequest = { ticketId, movements: state }

    create(data)
  }

  const handleChangeText = (newVal: Partial<PurchaseOrderMovement>, index: number) => {
    if (waitingUpload === 'uploading') return

    const clone = cloneDeep(state)
    clone[index] = { ...clone[index], ...newVal }
    setState(clone)
  }

  return (
    <ScrollViewForm>
      {state.map((elem, index) => (
        <IM.View style={styles.articleGroup} key={index}>
          <ArticleSelector
            onChange={val => handleChangeText({ articleid: val ? val.id : '' }, index)}
            isMandatory
            requestExtraId={index}
            spacing="vertical"
          />
          <SparePartQuantityInput
            value={elem.quantity.toString()}
            title={i18n.t('QUANTITY') + ' *'}
            containerStyle={styles.quantityInput}
            onChangeText={val => {
              let value = parseInt(val === '' ? '0' : val, 10)

              if (value > 999999999999999) value = 999999999999999

              handleChangeText({ quantity: value }, index)
            }}
            disableFixedWidth
            spacing="bottom"
            controlled
          />
          <TextInput title={i18n.t('NOTE')} value={elem.note} onChangeText={val => handleChangeText({ note: val }, index)} spacing="bottom" />
          {state.length !== 1 && state.length - 1 === index && (
            <PressableTextIcon
              onPress={() => {
                setState(prev => {
                  prev.pop()
                  return [...prev]
                })
              }}
              icon={['fal', 'minus']}
              color={theme.general.error}>
              {i18n.t('REMOVE_LAST_ARTICLE')}
            </PressableTextIcon>
          )}
          {state.length - 1 === index && (
            <PressableTextIcon
              onPress={() => {
                setState(prev => [...prev, { articleid: '', quantity: 0 }])
              }}
              icon={['fal', 'plus']}
              color={theme.general.info}>
              {i18n.t('ADD_ARTICLE')}
            </PressableTextIcon>
          )}
        </IM.View>
      ))}
    </ScrollViewForm>
  )
}

export default forwardRef(ActivityPurchaseOrderCreateView)

const styles = StyleSheet.create({
  articleGroup: { marginBottom: 12 },
  quantityInput: {
    paddingLeft: 0,
  },
})
