import { IM, IMLayout, useLanguage, useTheme } from '@infominds/react-native-components'
import React, { Children, ForwardedRef, memo, PropsWithChildren, ReactElement, useImperativeHandle, useMemo, useRef } from 'react'
import { NativeSyntheticEvent, StyleSheet, TextInputFocusEventData } from 'react-native'
import TextareaAutosize from 'react-textarea-autosize'

import { TextInputRef, ThemeColorExpanded } from '../../../types'
import appUtils from '../../../utils/appUtils'
import Pressable from '../../Infominds/Pressable'
import Text from '../../Text'
import { BaseTextInputProps } from './BaseTextInput'
import BaseTextInputTitle from './BaseTextInputTitle'
import useBaseTextInput from './hooks/useBaseTextInput'

const MultilineBaseTextInput = memo(
  React.forwardRef(
    (
      { children, spacing, type, disableBorderRadius = [], unity, value, numberOfLines = 3, ...props }: PropsWithChildren<BaseTextInputProps>,
      ref: ForwardedRef<TextInputRef>
    ) => {
      const { onPress, onBlur } = props

      const innerRef = useRef<HTMLTextAreaElement>(null)

      useImperativeHandle(ref, () => ({
        focus: () => innerRef.current?.focus(),
        blur: () => innerRef.current?.blur(),
      }))

      const { i18n, language } = useLanguage()
      const { theme } = useTheme<ThemeColorExpanded>()
      const { backgroundColor, borderColor, loading, editable, onClickEffect } = useBaseTextInput()

      const components = useMemo(() => {
        const childArray = Children.toArray(children)
        return {
          title: childArray.find(q => (q as ReactElement).type === BaseTextInputTitle),
        }
      }, [children])

      return (
        <IM.View spacing={spacing}>
          {components.title}

          {/* @ts-ignore: okk */}
          <TextareaAutosize
            {...props}
            {...appUtils.getTypeProps(type, language)}
            ref={innerRef}
            value={value}
            minRows={1}
            maxRows={props.multiline ? numberOfLines : 1}
            placeholder={loading ? i18n.t('LOADING_PLACEHOLDER') : undefined}
            style={{
              ...styles.input,
              ...styles.border,
              ...appUtils.handleBorderStyle(disableBorderRadius, styles.disableTopBorder, styles.disableBottomBorder),
              ...{
                borderColor,
                backgroundColor,
                color: theme.text.default,
                resize: 'none',
                width: '100%',
              },
            }}
            onChange={ev => props.onChangeText?.(ev.target.value)}
            onFocus={() => onClickEffect(true)}
            onBlur={e => {
              onBlur?.(e as unknown as NativeSyntheticEvent<TextInputFocusEventData>)
              onClickEffect(false)
            }}
            disabled={onPress !== undefined ? true : loading === false && !editable}
            onClick={e => e.stopPropagation()}
          />
          {unity && (
            <Pressable
              style={[
                styles.unity,
                {
                  backgroundColor,
                  borderColor,
                },
              ]}>
              <Text secondary>{unity}</Text>
            </Pressable>
          )}
        </IM.View>
      )
    }
  )
)

const MultilineBaseTextInputNamespace = Object.assign(MultilineBaseTextInput, {
  Title: BaseTextInputTitle,
})

export { MultilineBaseTextInputNamespace as MultilineBaseTextInput }

const styles = StyleSheet.create({
  border: {
    borderRadius: IMLayout.borderRadius,
  },
  disableBottomBorder: {
    borderBottomRightRadius: 0,
    borderBottomLeftRadius: 0,
  },
  disableTopBorder: {
    borderTopRightRadius: 0,
    borderTopLeftRadius: 0,
  },
  input: {
    borderWidth: 1,
    padding: 12,
  },
  unity: {
    borderRadius: 0,
    flex: 1,
    paddingRight: 10,
    paddingLeft: 0,
    alignItems: 'center',
    justifyContent: 'center',
    borderWidth: 1,
    marginLeft: -1,
    borderLeftWidth: 0,
  },
})
