import { useLanguage } from '@infominds/react-native-components'
import React from 'react'
import { useRecoilValue } from 'recoil'

import { SearchProvider } from '../../components/screen/contexts/SearchContext'
import FilterDetailHeaderContainer from '../../components/screen/headers/containers/FilterDetailHeaderContainer'
import SearchDetailHeaderContainer from '../../components/screen/headers/containers/SearchDetailHeaderContainer'
import HeaderWithIconsSync from '../../components/screen/headers/HeaderWithIconsSync'
import QualityFilterDetailHeader from '../../components/screen/headers/QualityFilterDetailHeader'
import { Screen } from '../../components/screen/Screen'
import QualityFilterContext from '../../contexts/QualityFilterProvider'
import { QualityStackScreenProps } from '../../navigation/navigators/bottomTabs/QualityBottomTabNavigator'
import { qualityFilterEnableAtom } from '../../utils/stateManager'
import QualityView from '../../views/quality/QualityView'

export default function QualityScreen({ navigation }: QualityStackScreenProps<'Quality'>) {
  const { i18n } = useLanguage()
  const enable = useRecoilValue(qualityFilterEnableAtom)

  const handleSDNavigation = () => {
    navigation.navigate('QualityModalCommonStack', { screen: 'QualityFilter' })
  }

  return (
    <SearchProvider>
      <QualityFilterContext.Consumer>
        {context => {
          let activeFilters = false
          context?.filters.forEach(filter =>
            filter.elements.forEach(el => {
              if (el.active) activeFilters = true
            })
          )
          const activeGroups = context?.groups.filter(el => el.active)
          const activeOrder = context?.orders.filter(el => el.active)

          return (
            <Screen>
              <Screen.Header>
                <HeaderWithIconsSync
                  title={i18n.t('TAB_QUALITY')}
                  enabledOffline
                  tabletSearchComponent={<SearchDetailHeaderContainer enabledOffline enableQR iconPosition="right" />}
                  tabletFilterComponent={
                    <FilterDetailHeaderContainer dividers={['right']}>
                      <QualityFilterDetailHeader enabled={enable} onSDNavigation={handleSDNavigation} />
                    </FilterDetailHeaderContainer>
                  }
                  highlightFilter={activeFilters || activeGroups?.length !== 0 || activeOrder?.length !== 0}
                />
              </Screen.Header>
              <Screen.DetailHeader>
                <SearchDetailHeaderContainer enableQR />
                <FilterDetailHeaderContainer>
                  <QualityFilterDetailHeader enabled={enable} onSDNavigation={handleSDNavigation} />
                </FilterDetailHeaderContainer>
              </Screen.DetailHeader>
              <Screen.Content>
                <QualityView />
              </Screen.Content>
            </Screen>
          )
        }}
      </QualityFilterContext.Consumer>
    </SearchProvider>
  )
}
