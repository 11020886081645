import React from 'react'

import { CloseActivityProvider } from '../../contexts/CloseActivityContext'
import { CloseActivityIdProvider } from '../../contexts/CloseActivityIdContext'
import { ActivityStackScreenProps } from '../../navigation/navigators/common/ActivityCommonNavigator'
import ActivityCloseWrapperView from '../../views/activity/ActivityCloseWrapperView'

export default function ActivityCloseScreen({ route }: ActivityStackScreenProps<'ActivityClose'>) {
  return (
    <CloseActivityProvider>
      <CloseActivityIdProvider baseActivityId={route.params.activityId}>
        <ActivityCloseWrapperView route={route} />
      </CloseActivityIdProvider>
    </CloseActivityProvider>
  )
}
