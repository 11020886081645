import { IM, IMLayout, IMStyle, ModalController, StringUtils, useLanguage, useModalController, useTheme } from '@infominds/react-native-components'
import React, { useEffect, useRef, useState } from 'react'
import { StyleSheet } from 'react-native'

import { getApi } from '../../apis/apiCalls'
import useControlledLoader from '../../components/Infominds/hooks/useControlledLoader'
import Pressable from '../../components/Infominds/Pressable'
import BaseTextInputSpinningIcon from '../../components/input/baseTextInput/BaseTextInputSpinningIcon'
import TextInput from '../../components/input/TextInput'
import { dropdownStyle } from '../../components/settings/LanguageSelector'
import { REQUEST_GENERATE_PASSWORD } from '../../constants/Keys'
import { useDataProvider } from '../../dataProvider/hooks/useDataProvider'
import useVault from '../../hooks/useVault'
import { PasswordComplexity, ThemeColorExpanded } from '../../types'
import AvoidingViewModal from '../AvoidingViewModal'

export interface GeneratePasswordModalData {
  length: number
  complexity: PasswordComplexity
}

interface Props {
  controller: ModalController<GeneratePasswordModalData>
  onAccept: (password: string) => void
}

export default function GeneratePasswordModal({ controller, onAccept }: Props) {
  const { i18n } = useLanguage()
  const { sessionId } = useVault()
  const { theme } = useTheme<ThemeColorExpanded>()
  const { client } = useDataProvider()
  const vaultModalController = useModalController()

  const complexityValues: { label: string; value: PasswordComplexity }[] = [
    { label: i18n.t('SIMPLE'), value: 'Simple' },
    { label: i18n.t('MEDIUM'), value: 'Medium' },
    { label: i18n.t('ADVANCED'), value: 'Advanced' },
  ]

  const [error, setError] = useState(false)
  const [errorApi, setErrorApi] = useState(false)
  const [errorLength, setErrorLength] = useState(false)
  const [length, setLength] = useState<string | undefined>(undefined)
  const [complexity, setComplexity] = useState<PasswordComplexity>(controller.data?.complexity ?? 'Simple')

  const defaultLength = useRef<string | undefined>()

  const {
    item: generated,
    loadItem: generatePassword,
    loading: loading,
  } = useControlledLoader(getApi(client).generatePassword, {
    id: REQUEST_GENERATE_PASSWORD,
    onLockResult: result => {
      if (result.vaultState === 'SessionClosed') {
        vaultModalController.show()
        return 'vault-locked'
      } else {
        return 'vault-unlocked'
      }
    },
  })

  useEffect(() => {
    if (controller.isShown && length === undefined) {
      defaultLength.current = controller.data?.length.toString()
      setLength(controller.data?.length.toString())
    }
  }, [controller])

  const onGenerate = (id?: string) => {
    if (!length) return setError(true)

    setError(false)
    setErrorApi(false)
    setErrorLength(false)

    const isNum = /^\d+$/.test(length)

    const value = parseInt(length, 10)
    const insideLimit = value > 0 && value < 15

    if (!insideLimit) return setErrorLength(true)
    if (!isNum) return setError(true)

    return generatePassword({ length, passwordComplexity: complexity, VaultSessionId: id ?? sessionId })
  }

  useEffect(() => {
    if (loading !== false) return

    if (!generated?.data) {
      return setErrorApi(true)
    }

    onAccept(generated?.data)
    handleClose()
  }, [loading])

  const handleClose = (resetLength = false) => {
    controller.close()

    if (resetLength) setLength(defaultLength.current)
    setError(false)
    setErrorApi(false)
    setErrorLength(false)
  }

  return (
    <AvoidingViewModal controller={controller} title={i18n.t('PASSWORD_GENERATION')}>
      <IM.View spacing="bottom">
        <IM.View style={styles.complexity}>
          <IM.Text style={{ fontWeight: IMStyle.typography.fontWeightMedium }}>{i18n.t('COMPLEXITY')}</IM.Text>
        </IM.View>
        <IM.Dropdown
          data={complexityValues}
          value={complexity}
          onChange={val => setComplexity(val.value)}
          containerStyle={dropdownStyle.noShadow}
          style={styles.dropdown}
          selectedTextProps={{ numberOfLines: 1 }}
        />
      </IM.View>
      <IM.View spacing="bottom" style={styles.behind}>
        <IM.Text secondary style={{ fontSize: IMStyle.typography.fontSizeRegular - 2 }}>
          {i18n.t('COMPLEXITY_DESCRIPTION')}
        </IM.Text>
      </IM.View>
      <IM.View style={styles.behind}>
        <TextInput
          value={length}
          onChangeText={val => {
            setError(false)
            setLength(val)
          }}
          type="number"
          title={i18n.t('LENGTH')}
          error={error || errorLength}
          maxLength={2}
        />
      </IM.View>
      {error && (
        <IM.View spacing="top">
          <IM.Text style={{ color: theme.general.error }}>{StringUtils.stringValueReplacer(i18n.t('PASSWORD_ERROR'), 1, 14)}</IM.Text>
        </IM.View>
      )}
      {errorLength && (
        <IM.View spacing="top">
          <IM.Text style={{ color: theme.general.error }}>{StringUtils.stringValueReplacer(i18n.t('PASSWORD_LENGTH_ERROR'), 1, 14)}</IM.Text>
        </IM.View>
      )}
      {errorApi && (
        <IM.View spacing="top">
          <IM.Text style={{ color: theme.general.error }}>{i18n.t('FAILED_PASSWORD_GENERATION')}</IM.Text>
        </IM.View>
      )}
      <IM.View style={styles.buttonsContainer} spacing="top">
        <Pressable spacing="right" onPress={() => handleClose(true)}>
          <IM.Text style={[styles.text, { color: theme.text.default }]}>{i18n.t('CANCEL').toUpperCase()}</IM.Text>
        </Pressable>
        <Pressable style={styles.okButton} onPress={() => onGenerate()} disabled={false}>
          {loading === false || loading === 'init' ? (
            <IM.Text style={[styles.text, { color: theme.text.default }]}>{i18n.t('OK').toUpperCase()}</IM.Text>
          ) : (
            <BaseTextInputSpinningIcon loading style={styles.icon} size={16} />
          )}
        </Pressable>
      </IM.View>
    </AvoidingViewModal>
  )
}

const styles = StyleSheet.create({
  behind: {
    zIndex: -1,
  },
  buttonsContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  complexity: { marginTop: 2 * IMLayout.horizontalMargin, marginBottom: 4 },
  icon: { paddingHorizontal: 2 },
  okButton: {
    paddingHorizontal: 14,
  },
  text: {
    textAlign: 'center',
  },
  dropdown: { width: '100%' },
})
