import { IM, IMLayout } from '@infominds/react-native-components'
import React, { memo, PropsWithChildren } from 'react'
import { StyleProp, StyleSheet, ViewStyle } from 'react-native'

import { Border } from '../../../types'
import appUtils from '../../../utils/appUtils'
import useBaseTextInput from './hooks/useBaseTextInput'

interface Props {
  borderColor?: string

  disableBorder?: Border | Border[]
  style?: StyleProp<ViewStyle>
}

const BaseTextInputLeftIcon = memo(function BaseTextInputLeftIcon({ children, disableBorder, borderColor, style }: PropsWithChildren<Props>) {
  const { backgroundColor, borderColor: contextBorderColor } = useBaseTextInput()

  return (
    <IM.View
      style={[
        styles.icon,
        styles.leftIcon,
        styles.container,
        { backgroundColor: backgroundColor, borderColor: borderColor ?? contextBorderColor },
        disableBorder && appUtils.handleBorderStyle(disableBorder, { borderTopLeftRadius: 0 }, { borderBottomLeftRadius: 0 }),
        style,
      ]}>
      {children}
    </IM.View>
  )
})

export default BaseTextInputLeftIcon

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    zIndex: 10,
  },
  icon: {
    borderWidth: 1,
    alignItems: 'center',
    justifyContent: 'center',
    paddingLeft: 4,
    paddingRight: 2,
  },
  leftIcon: {
    borderRightWidth: 0,
    marginRight: -2,
    borderTopLeftRadius: IMLayout.borderRadius,
    borderBottomLeftRadius: IMLayout.borderRadius,
  },
})
