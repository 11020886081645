import { IM, useDimensions } from '@infominds/react-native-components'
import React, { useMemo } from 'react'
import { Platform, StyleSheet } from 'react-native'

import useIsOnline from '../../../dataProvider/hooks/useIsOnline'
import PressableIcon from '../../Infominds/PressableIcon'
import DataManagementIconGroup, { DataManagementIconGroupProps } from '../DataManagementIconGroup'
import HeaderTicketChangeViewIcon, { TicketHeaderIconType } from '../HeaderTicketChangeViewIcon'
import useScreen from '../hooks/useScreen'
import { CALENDAR_ICON, LIST_ICON, MAP_ICON, TicketScreenViewType } from '../types'
import SyncIcon from './SyncIcon'

interface Props {
  enabledOffline?: boolean
  layoutIcon?: {
    color: string
    onPress: () => void
  }
}

const ServiceHeader = ({ disabled = false, enabledOffline, layoutIcon, ...props }: Props & Omit<DataManagementIconGroupProps, 'extraIcon'>) => {
  const { ticketView } = useScreen()
  const { isSmallDevice } = useDimensions()
  const isOnline = useIsOnline()

  const headerIcons = useMemo<TicketHeaderIconType[]>(() => {
    if (Platform.OS === 'web' && isSmallDevice) {
      return [
        { type: TicketScreenViewType.LIST, ...CALENDAR_ICON },
        { type: TicketScreenViewType.CALENDAR, ...LIST_ICON },
      ]
    }
    if (isOnline) {
      return [
        { type: TicketScreenViewType.LIST, ...MAP_ICON },
        { type: TicketScreenViewType.LOCATION, ...CALENDAR_ICON },
        { type: TicketScreenViewType.CALENDAR, ...LIST_ICON },
      ]
    }

    return [
      { type: TicketScreenViewType.LIST, ...CALENDAR_ICON },
      { type: TicketScreenViewType.CALENDAR, ...LIST_ICON },
    ]
  }, [isOnline, isSmallDevice])

  return (
    <IM.View style={styles.container}>
      <IM.View style={styles.leftContainer}>
        <DataManagementIconGroup disabled={disabled || (!enabledOffline && !isOnline)} {...props} alignment="left" />
      </IM.View>
      <IM.View style={styles.rightContainer}>
        {ticketView === TicketScreenViewType.LIST && layoutIcon && (
          <PressableIcon
            color={layoutIcon.color}
            icon={['fal', 'table-layout']}
            size={18}
            onPress={layoutIcon.onPress}
            hitSlop={{ top: 10, bottom: 8 }}
          />
        )}
        <HeaderTicketChangeViewIcon types={headerIcons} />
        <SyncIcon />
      </IM.View>
    </IM.View>
  )
}

const styles = StyleSheet.create({
  container: { flex: 1, flexDirection: 'row' },
  rightContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  leftContainer: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
})

export default ServiceHeader
