import { useLanguage } from '@infominds/react-native-components'
import React, { createRef } from 'react'
import { Keyboard } from 'react-native'

import { SearchProvider } from '../../components/screen/contexts/SearchContext'
import { Screen } from '../../components/screen/Screen'
import useEditOrCreateScreenBackManager from '../../hooks/useEditOrCreateScreenBackManager'
import { TicketDetailCommonStackScreenProps } from '../../navigation/navigators/common/TicketDetailCommonNavigator'
import { ModalScreenProps, TicketReportParams, TicketReportViewRef } from '../../types'
import TicketReportView from '../../views/ticket/TicketReportView'

const TicketReportScreen = ({
  route,
  controller,
}: Omit<TicketDetailCommonStackScreenProps<'TicketReport'>, 'navigation'> & ModalScreenProps<TicketReportParams>) => {
  const { i18n } = useLanguage()
  const ticketReportViewRef = createRef<TicketReportViewRef>()

  const { status, setStatus, handleGoBack } = useEditOrCreateScreenBackManager({
    title: i18n.t('UNSAVED_CHANGES_TITLE'),
    description: i18n.t('DISCARD_UNSAVED_CHANGES'),
    controller,
  })

  const isModal = controller !== undefined

  return (
    <SearchProvider>
      <Screen transparent={isModal}>
        <Screen.Header
          goBack={handleGoBack}
          backHandlerCallback={handleGoBack}
          isModal={isModal}
          title={i18n.t('SEND_REPORT')}
          onRightIconPress={() => {
            Keyboard.dismiss()
            ticketReportViewRef.current?.sendReport()
          }}
          rightIcon={['fal', 'check']}
          disable={status === 'done' || status === 'mandatoryMissing'}
          loading={status === 'uploading'}
        />
        <Screen.Content>
          <TicketReportView
            ref={ticketReportViewRef}
            ticketId={route.params.ticketId}
            status={status}
            onDone={handleGoBack}
            onUploadStatus={setStatus}
          />
        </Screen.Content>
      </Screen>
    </SearchProvider>
  )
}

export default TicketReportScreen
