import { IM, useDimensions } from '@infominds/react-native-components'
import React, { ReactNode } from 'react'
import { StyleSheet } from 'react-native'

import HeaderFilterIcon from './HeaderFilterIcon'
import HeaderSearchIcon from './HeaderSearchIcon'

export interface DataManagementIconGroupProps {
  alignment?: 'left' | 'right'
  tabletSearchComponent?: ReactNode
  hideSearch?: boolean
  hideFilter?: boolean
  tabletFilterComponent?: ReactNode
  highlightFilter?: boolean
  extraIcon?: ReactNode
  disabled?: boolean
}

export default function DataManagementIconGroup({
  alignment = 'right',
  hideSearch,
  hideFilter,
  tabletSearchComponent,
  tabletFilterComponent,
  highlightFilter,
  extraIcon,
  disabled,
}: DataManagementIconGroupProps) {
  const { isSmallDevice } = useDimensions()

  return (
    <IM.View style={styles.container}>
      {isSmallDevice ? (
        <>
          {alignment === 'right' ? (
            <>
              {extraIcon}
              {!hideFilter && <HeaderFilterIcon highlight={highlightFilter} disabled={disabled} />}
              {!hideSearch && <HeaderSearchIcon disabled={disabled} />}
            </>
          ) : (
            <>
              {!hideSearch && <HeaderSearchIcon disabled={disabled} />}
              {extraIcon}
              {!hideFilter && <HeaderFilterIcon highlight={highlightFilter} disabled={disabled} />}
            </>
          )}
        </>
      ) : (
        <>
          {alignment === 'right' ? (
            <>
              {!hideFilter && tabletFilterComponent}
              {extraIcon}
              {!hideSearch && tabletSearchComponent}
            </>
          ) : (
            <>
              {!hideSearch && tabletSearchComponent}
              {!hideFilter && tabletFilterComponent}
              {extraIcon}
            </>
          )}
        </>
      )}
    </IM.View>
  )
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    height: '100%',
    alignItems: 'center',
  },
})
