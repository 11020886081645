import { IM, IMStyle, SpacingProps, useLanguage } from '@infominds/react-native-components'
import React, { memo, useEffect } from 'react'

import api from '../../apis/apiCalls'
import CardLeftTitle from '../../components/card/left/CardLeftTitle'
import Error from '../../components/Error'
import useControlledLoader from '../../components/Infominds/hooks/useControlledLoader'
import SkeletonText from '../../components/skeleton/SkeletonText'
import Text from '../../components/Text'
import TextWithHeader from '../../components/TextWithHeader'
import { REQUEST_TICKET } from '../../constants/Keys'

type Props = {
  ticketId: string
  spacing?: SpacingProps
}

const ActivityTicketInfoCard = memo(function ActivityTicketInfoCard({ ticketId, spacing }: Props) {
  const { i18n } = useLanguage()
  const { item: tickets, loadItem: loadTicket, loading } = useControlledLoader(api.getTicket, { id: REQUEST_TICKET })

  useEffect(() => {
    loadTicket({ id: ticketId })
  }, [])

  const ticket = tickets?.at(0)

  return (
    <IM.Card spacing={spacing} head={<CardLeftTitle text={i18n.t('TICKET')} />}>
      {loading === false ? (
        <>
          <Text style={{ fontWeight: IMStyle.typography.fontWeightBold }}>{ticket?.code}</Text>
          <IM.View spacing="top">
            <Text>{ticket?.description}</Text>
          </IM.View>
          {ticket?.customerDescription && <TextWithHeader header={i18n.t('CUSTOMER_DESCRIPTION')} text={ticket?.customerDescription} spacing="top" />}
        </>
      ) : (
        <IM.View>
          {loading === 'catched' ? (
            <Error />
          ) : (
            <>
              <SkeletonText width="50%" />
              <SkeletonText width="80%" spacing="top" />
              <SkeletonText width="100%" spacing="top" />
            </>
          )}
        </IM.View>
      )}
    </IM.Card>
  )
})

export default ActivityTicketInfoCard
