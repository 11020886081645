import { CompositeScreenProps, NavigatorScreenParams } from '@react-navigation/native'
import { createNativeStackNavigator, NativeStackScreenProps } from '@react-navigation/native-stack'
import React from 'react'

import { FilterProvider } from '../../../contexts/FilterContext'
import TicketActivityCreateScreen from '../../../screens/tickets/TicketActivityCreateScreen'
import TicketAddSerialNumberScreen from '../../../screens/tickets/TicketAddSerialNumberScreen'
import TicketArticleCounterScreen from '../../../screens/tickets/TicketArticleCounterScreen'
import TicketArticleScreen from '../../../screens/tickets/TicketArticleScreen'
import TicketDetailScreen from '../../../screens/tickets/TicketDetailScreen'
import TicketEditPriorityScreen from '../../../screens/tickets/TicketEditPriorityScreen'
import TicketReportScreen from '../../../screens/tickets/TicketReportScreen'
import TicketSerialNumberCreationScreen from '../../../screens/tickets/TicketSerialNumberCreationScreen'
import { TicketAddSNParams, TicketArticleCounterParams, TicketEditPriorityParams, TicketReportParams } from '../../../types'
import { screenOptions } from '../../Navigator'
import { TicketStackScreenProps } from '../bottomTabs/TicketBottomTabNavigator'
import { AccessDataCommonStackNames, AccessDataCommonStackNavigator, AccessDataCommonStackParamList } from './AccessDataCommonNavigator'
import { ActivityCommonStackNames, ActivityCommonStackNavigator, ActivityCommonStackParamList } from './ActivityCommonNavigator'
import { CustomerCommonStackNames, CustomerCommonStackNavigator, CustomerCommonStackParamList } from './CustomerCommonNavigators'
import { InfoboxCommonStackNames, InfoboxCommonStackNavigator, InfoboxCommonStackParamList } from './InfoboxCommonNavigators'
import { ModalCommonStackNames, ModalCommonStackNavigator, ModalCommonStackParamList } from './ModalCommonNavigator'

export type TicketDetailCommonStackParamList = {
  TicketDetail: { ticketId: string; ticketCode: string }
  TicketArticle: { ticketId: string; activityId: string | undefined; articleId: string; serialNumber?: string; serialNumberManufactor?: string }
  TicketArticleCounter: TicketArticleCounterParams
  TicketSerialNumberCreation: { customerId: string; ticketId: string; shippingAddressId: string | undefined }
  TicketCustomerCommonStack: NavigatorScreenParams<CustomerCommonStackParamList>
  TicketAccessDataCommonStack: NavigatorScreenParams<AccessDataCommonStackParamList>
  TicketInfoboxCommonStack: NavigatorScreenParams<InfoboxCommonStackParamList>
  TicketReport: TicketReportParams
  TicketAddSerialNumber: TicketAddSNParams
  TicketEditPriority: TicketEditPriorityParams
  TicketActivityCreation: { ticketId: string }
  TicketDetailModalCommonStack: NavigatorScreenParams<ModalCommonStackParamList>
  TicketActivityCommonStack: NavigatorScreenParams<ActivityCommonStackParamList>
  TicketDetailPastTicket: { ticketId: string; ticketCode: string }
}

export const TicketDetailCommonStackNames: {
  path: string
  screens: Record<
    keyof TicketDetailCommonStackParamList,
    | string
    | typeof CustomerCommonStackNames
    | typeof AccessDataCommonStackNames
    | typeof InfoboxCommonStackNames
    | typeof ActivityCommonStackNames
    | typeof ModalCommonStackNames
  >
} = {
  path: 'ticket-detail',
  screens: {
    TicketDetail: 'detail',
    TicketArticle: 'article',
    TicketArticleCounter: 'article-counter',
    TicketSerialNumberCreation: 'serial-number-creation',
    TicketAddSerialNumber: 'add-serial-number',
    TicketCustomerCommonStack: CustomerCommonStackNames,
    TicketAccessDataCommonStack: AccessDataCommonStackNames,
    TicketInfoboxCommonStack: InfoboxCommonStackNames,
    TicketReport: 'report',
    TicketEditPriority: 'edit-priority',
    TicketActivityCreation: 'activity-creation',
    TicketDetailModalCommonStack: ModalCommonStackNames,
    TicketActivityCommonStack: ActivityCommonStackNames,
    TicketDetailPastTicket: 'past-ticket',
  },
}

export type TicketDetailCommonStackScreenProps<T extends keyof TicketDetailCommonStackParamList> = CompositeScreenProps<
  NativeStackScreenProps<TicketDetailCommonStackParamList, T>,
  TicketStackScreenProps<'TicketDetailCommonStack'>
>

const TicketDetailCommonStack = createNativeStackNavigator<TicketDetailCommonStackParamList>()
export function TicketDetailCommonStackNavigator() {
  return (
    <FilterProvider disableOrderByTown disableFilterByPlanDate storageKeyUniqueId="TicketDetailCommonStack">
      <TicketDetailCommonStack.Navigator screenOptions={screenOptions}>
        <TicketDetailCommonStack.Screen name="TicketDetail" component={TicketDetailScreen} />
        <TicketDetailCommonStack.Screen name="TicketArticle" component={TicketArticleScreen} />
        <TicketDetailCommonStack.Screen name="TicketArticleCounter" component={TicketArticleCounterScreen} />
        <TicketDetailCommonStack.Screen name="TicketSerialNumberCreation" component={TicketSerialNumberCreationScreen} />
        <TicketDetailCommonStack.Screen name="TicketCustomerCommonStack" component={CustomerCommonStackNavigator} />
        <TicketDetailCommonStack.Screen name="TicketAccessDataCommonStack" component={AccessDataCommonStackNavigator} />
        <TicketDetailCommonStack.Screen name="TicketInfoboxCommonStack" component={InfoboxCommonStackNavigator} />
        <TicketDetailCommonStack.Screen name="TicketReport" component={TicketReportScreen} />
        <TicketDetailCommonStack.Screen name="TicketAddSerialNumber" component={TicketAddSerialNumberScreen} />
        <TicketDetailCommonStack.Screen name="TicketEditPriority" component={TicketEditPriorityScreen} />
        <TicketDetailCommonStack.Screen name="TicketActivityCreation" component={TicketActivityCreateScreen} />
        <TicketDetailCommonStack.Screen name="TicketDetailModalCommonStack" component={ModalCommonStackNavigator} />
        <TicketDetailCommonStack.Screen name="TicketActivityCommonStack" component={ActivityCommonStackNavigator} />
        <TicketDetailCommonStack.Screen name="TicketDetailPastTicket" component={TicketDetailScreen} />
      </TicketDetailCommonStack.Navigator>
    </FilterProvider>
  )
}
