import { CardButton, CardProps, IM, IMLayout, IMStyle, useAlert, useLanguage, useTheme } from '@infominds/react-native-components'
import React, { memo, useMemo } from 'react'
import { StyleSheet } from 'react-native'

import type { Destination } from '../../apis/types/apiResponseTypes'
import CardLeftTitle from '../../components/card/left/CardLeftTitle'
import ChangeButton from '../../components/ChangeButton'
import Error from '../../components/Error'
import TextWithIcon from '../../components/Infominds/TextWithIcon'
import SkeletonText from '../../components/skeleton/SkeletonText'
import { InfiniteLoadingType, ThemeColorExpanded } from '../../types'
import appUtils from '../../utils/appUtils'
import customerUtils from '../../utils/customerUtils'
import { utils } from '../../utils/utils'

type Props = CardProps & {
  destination: Destination | undefined
  disableIcon?: boolean
  leftText?: string
  enableChange?: boolean
  loading?: InfiniteLoadingType
  hideButtons?: boolean
  onChange?: () => void
}

const CustomerDestinationCard = memo(function CustomerDestinationCard({
  destination,
  disableIcon,
  leftText,
  selected,
  enableChange,
  hideButtons,
  loading = false,
  onChange,
  ...other
}: Props) {
  const alert = useAlert()
  const { theme, colorScheme } = useTheme<ThemeColorExpanded>()
  const { i18n } = useLanguage()

  const inactive = destination?.inactive
  const phone = destination?.phone?.at(0)
  const buttons: CardButton[] = []

  const handlePhonePress = () => {
    phone && appUtils.openPhone(phone.number, alert, i18n)
  }

  const address = useMemo(() => customerUtils.formatAddress(destination, 'street'), [destination])
  const location = useMemo(() => customerUtils.formatAddress(destination, 'location'), [destination])

  const handleRoutePress = () => {
    if (destination?.mapLat && destination.mapLong) {
      utils.openMaps(destination.mapLat, destination.mapLong, alert, i18n.t('OPEN_MAP_LINK_ERROR'))
    } else {
      utils.openMapsWithQuery(address + ' ' + location, alert, i18n.t('OPEN_MAP_LINK_ERROR'))
    }
  }

  if (phone) {
    buttons.push({
      icon: ['fal', 'phone'],
      ...appUtils.getCardButtonColor(!inactive, theme),
      overrideDisabled: true,
      onPress: inactive ? undefined : handlePhonePress,
    })
  }

  if ((address !== '' && location !== '') || (destination?.mapLat && destination.mapLong)) {
    buttons.push({
      icon: ['fal', 'route'],
      ...appUtils.getCardButtonColor(!inactive, theme),
      overrideDisabled: true,
      onPress: inactive ? undefined : handleRoutePress,
    })
  }

  const opacity = inactive ? appUtils.getOpacity(colorScheme) : 1

  return (
    <IM.Card
      head={<CardLeftTitle color={selected ? theme.general.info : theme.card.accent.active} text={leftText} />}
      buttons={{
        buttons: hideButtons || loading !== false ? [] : buttons,
      }}
      {...other}
      style={[other.style, { opacity }]}>
      {loading === false || loading === 'init' ? (
        <IM.View style={styles.content}>
          {destination ? (
            <>
              <TextWithIcon
                secondary={inactive}
                icon={disableIcon ? undefined : ['fal', 'location-dot']}
                iconSize={20}
                style={[IMLayout.flex.f1, { fontWeight: IMStyle.typography.fontWeightBold, fontSize: IMStyle.typography.fontSizeMedium }]}>
                {destination.description}
              </TextWithIcon>
              {address !== '' && (
                <IM.View style={IMLayout.flex.f1} spacing="top">
                  <IM.Text secondary={inactive}>{customerUtils.formatAddress(destination, 'street')}</IM.Text>
                  <IM.Text secondary={inactive}>{customerUtils.formatAddress(destination, 'location')}</IM.Text>
                </IM.View>
              )}
              {phone && (
                <IM.View style={IMLayout.flex.f1} spacing="top">
                  <IM.Text secondary={inactive}>{phone.number}</IM.Text>
                </IM.View>
              )}
              {destination.openingHours && (
                <IM.View style={IMLayout.flex.f1} spacing="top">
                  <IM.Text>{destination.openingHours}</IM.Text>
                </IM.View>
              )}
            </>
          ) : (
            <IM.Text secondary>{i18n.t('NO_DESTINATION_SELECTED')}</IM.Text>
          )}
          {enableChange && <ChangeButton onPress={() => onChange?.()} />}
        </IM.View>
      ) : (
        <>
          {loading === 'catched' ? (
            <>
              <Error />
              {enableChange && <ChangeButton onPress={() => onChange?.()} />}
            </>
          ) : (
            <>
              <SkeletonText width="70%" />
              <SkeletonText spacing="top" width="100%" />
            </>
          )}
        </>
      )}
    </IM.Card>
  )
})

export default CustomerDestinationCard

const styles = StyleSheet.create({
  content: { flex: 1, justifyContent: 'space-between' },
})
