import { IM, useLanguage } from '@infominds/react-native-components'
import { useAuthentication } from '@infominds/react-native-license'
import { useIsFocused } from '@react-navigation/native'
import React, { useEffect, useState } from 'react'
import { useRecoilValue } from 'recoil'

import OnlineView from '../../components/offline/OnlineView'
import { PlannerProvider } from '../../components/planner/PlannerContext'
import PlannerHeader from '../../components/planner/PlannerHeader'
import ScreenContext from '../../components/screen/contexts/ScreenContext'
import { SearchProvider } from '../../components/screen/contexts/SearchContext'
import HeaderIcon from '../../components/screen/HeaderIcon'
import FilterDetailHeaderContainer from '../../components/screen/headers/containers/FilterDetailHeaderContainer'
import SearchDetailHeaderContainer from '../../components/screen/headers/containers/SearchDetailHeaderContainer'
import FilterDetailHeader from '../../components/screen/headers/FilterDetailHeader'
import HeaderWithIconsSync from '../../components/screen/headers/HeaderWithIconsSync'
import { Screen } from '../../components/screen/Screen'
import { PlanningScreenViewType } from '../../components/screen/types'
import FilterContext from '../../contexts/FilterContext'
import useIsOnline from '../../dataProvider/hooks/useIsOnline'
import useUserSettings from '../../hooks/useUserSettings'
import { PlanningStackScreenProps } from '../../navigation/navigators/bottomTabs/PlanningBottomTabNavigator'
import { activityPlanningFilterEnableAtom, plannerViewAtom } from '../../utils/stateManager'
import PlannerView from '../../views/planning/PlannerView'
import PlanningView from '../../views/planning/PlanningView'

export type PlanningMultipleAllocation = {
  enabled: boolean
  ids: string[]
}

export default function PlanningScreen({ navigation }: PlanningStackScreenProps<'Planning'>) {
  const isOnline = useIsOnline()
  const { i18n } = useLanguage()
  const isFocused = useIsFocused()
  const { sessionKey } = useAuthentication()
  const { userSettings } = useUserSettings()

  const enabled = useRecoilValue(activityPlanningFilterEnableAtom)
  const plannerView = useRecoilValue(plannerViewAtom(sessionKey))

  const [multiAllocation, setMultiAllocation] = useState<PlanningMultipleAllocation>({ enabled: false, ids: [] })

  useEffect(() => {
    setMultiAllocation({ enabled: false, ids: [] })
  }, [isFocused])

  const handleSDNavigation = () => {
    navigation.navigate('PlanningModalCommonStack', { screen: 'Filter' })
  }

  return (
    <SearchProvider>
      <FilterContext.Consumer>
        {context => {
          let activeFilters = false
          context?.filters.forEach(filter =>
            filter.elements.forEach(el => {
              if (el.active) activeFilters = true
            })
          )
          const activeGroups = context?.groups.filter(el => el.active)
          const activeOrder = context?.orders.filter(el => el.active)

          return (
            <Screen>
              <Screen.Header>
                <ScreenContext.Consumer>
                  {screenContext => {
                    return (
                      <HeaderWithIconsSync
                        title={i18n.t('TAB_PLANNING')}
                        tabletSearchComponent={
                          screenContext?.planningView === PlanningScreenViewType.LIST ? (
                            <SearchDetailHeaderContainer enabledOffline enableQR iconPosition="right" />
                          ) : (
                            <PlannerHeader />
                          )
                        }
                        tabletFilterComponent={
                          screenContext?.planningView === PlanningScreenViewType.LIST ? (
                            <FilterDetailHeaderContainer dividers={['right']}>
                              <FilterDetailHeader enabled={enabled} onSDNavigation={handleSDNavigation} />
                            </FilterDetailHeaderContainer>
                          ) : undefined
                        }
                        highlightFilter={activeFilters || activeGroups?.length !== 0 || activeOrder?.length !== 0}
                        enabledOffline={userSettings?.offlineSynchronizationOfThePlanningList}
                        extraIcon={
                          <HeaderIcon
                            icon={['fal', 'square-check']}
                            highlight={multiAllocation.enabled}
                            badge={<IM.NotificationBadge value={multiAllocation.enabled ? multiAllocation.ids.length : undefined} />}
                            onPress={() => setMultiAllocation(p => (p.enabled ? { enabled: false, ids: [] } : { enabled: true, ids: [] }))}
                            disabled={!isOnline}
                          />
                        }
                      />
                    )
                  }}
                </ScreenContext.Consumer>
              </Screen.Header>
              <Screen.DetailHeader>
                <SearchDetailHeaderContainer enableQR />
                <FilterDetailHeaderContainer>
                  <FilterDetailHeader enabled={enabled} onSDNavigation={handleSDNavigation} />
                </FilterDetailHeaderContainer>
              </Screen.DetailHeader>
              <Screen.Content>
                <ScreenContext.Consumer>
                  {screenContext => {
                    return (
                      <OnlineView showOfflineIndicator showInOffline={userSettings?.offlineSynchronizationOfThePlanningList}>
                        {screenContext?.planningView === PlanningScreenViewType.LIST ? (
                          <PlanningView
                            multipleAllocation={isOnline ? multiAllocation : { enabled: false, ids: [] }}
                            onSelected={id =>
                              setMultiAllocation(p => ({ ...p, ids: p.ids.includes(id) ? p.ids.filter(i => i !== id) : [...p.ids, id] }))
                            }
                          />
                        ) : (
                          <PlannerProvider resourceLabel={i18n.t('EMPLOYEE')} plannerView={plannerView}>
                            <PlannerView />
                          </PlannerProvider>
                        )}
                      </OnlineView>
                    )
                  }}
                </ScreenContext.Consumer>
              </Screen.Content>
            </Screen>
          )
        }}
      </FilterContext.Consumer>
    </SearchProvider>
  )
}
