import { IM, SpacingProps } from '@infominds/react-native-components'
import React, { memo, PropsWithChildren } from 'react'

import { InfiniteLoadingType } from '../types'
import SkeletonCard from './skeleton/SkeletonCard'

type Props = {
  loading?: InfiniteLoadingType
  spacingSkeleton?: SpacingProps
  allDataLoaded?: boolean
}

function FlashListFooter({ loading, spacingSkeleton, allDataLoaded, children }: PropsWithChildren<Props>) {
  return (
    <IM.View spacing="all">
      {loading === 'loadMore' && !allDataLoaded && (
        <IM.View spacing={spacingSkeleton ?? ['top', 'horizontal']}>
          <SkeletonCard />
        </IM.View>
      )}
      {allDataLoaded !== false && loading === false && children}
    </IM.View>
  )
}

export default memo(FlashListFooter)
