import { IMLayout, useAlert, useDimensions, useEvent, useLanguage } from '@infominds/react-native-components'
import { useAuthentication } from '@infominds/react-native-license'
import { NavigationProp } from '@react-navigation/native'
import React, { useEffect, useMemo } from 'react'
import { SectionListRenderItemInfo, StyleSheet } from 'react-native'
import { useRecoilState } from 'recoil'

import api from '../../apis/apiCalls'
import { FolderWithCount } from '../../apis/types/apiResponseTypes'
import InfoboxFolder from '../../cards/infobox/InfoboxFolder'
import useControlledLoader from '../../components/Infominds/hooks/useControlledLoader'
import useMasterDetail from '../../components/MasterDetail/hooks/useMasterDetail'
import useSearch from '../../components/screen/hooks/useSearch'
import SectionList from '../../components/SectionList'
import CONSTANTS from '../../constants/Constants'
import { INFOBOX_FOLDER_EVENT_KEY } from '../../constants/EmitterKeys'
import { REQUEST_INFOBOX_FILES, REQUEST_INFOBOX_FOLDER } from '../../constants/Keys'
import { InfoboxCommonStackParamList } from '../../navigation/navigators/common/InfoboxCommonNavigators'
import { InfoboxMediaParams, InfoboxType, ListSection } from '../../types'
import appUtils from '../../utils/appUtils'
import { infoboxAssetToSyncAtom, selectedFolderIdAtom } from '../../utils/stateManager'

interface Props {
  id: string
  type: InfoboxType
}

export default function InfoboxFolderView({ id, type }: Props) {
  const { sessionKey } = useAuthentication()
  const {
    item: folders,
    loadItem: loadFolders,
    setItem: resetFolders,
    loading: loadingFolders,
  } = useControlledLoader(api.getInfoboxFoldersWithFileCount, { id: REQUEST_INFOBOX_FOLDER })
  const {
    item: files,
    loadItem: loadFiles,
    loading: loadingFiles,
  } = useControlledLoader(api.getInfoboxFiles, {
    id: REQUEST_INFOBOX_FILES,
  })
  const alert = useAlert()
  const { search } = useSearch()
  const { i18n } = useLanguage()
  const { isSmallDevice } = useDimensions()

  const [selectedId, setSelectedId] = useRecoilState(selectedFolderIdAtom(sessionKey))
  const navigation = useMasterDetail<NavigationProp<InfoboxCommonStackParamList>>()

  const [assets, setAssets] = useRecoilState(infoboxAssetToSyncAtom)

  useEvent({ key: INFOBOX_FOLDER_EVENT_KEY }, () => refresh())

  useEffect(() => {
    handleRefresh()
  }, [id, type])

  useEffect(() => {
    if (!isSmallDevice && selectedId && folders && files) {
      const folder = folders?.find(elem => elem.id === selectedId)
      folder && handlePress(folder)
    }
  }, [folders, files])

  const handleRefresh = () => {
    if (assets > 0) {
      appUtils.infoboxAlert(alert, i18n, () => {
        setAssets(0)
        refresh()
      })
    } else {
      refresh()
    }
  }

  const refresh = () => {
    if (isSmallDevice || folders === undefined) {
      resetFolders(undefined)
      loadFolders({ infoboxTyp: type, fkId: id })
    }

    if (!isSmallDevice) {
      navigation.setDetailParams(undefined)
      loadFolders({ infoboxTyp: type, fkId: id })
      loadFiles({ infoboxTyp: type, id: id })
    }
  }

  const handlePress = (item: FolderWithCount) => {
    if (assets > 0) {
      appUtils.infoboxAlert(alert, i18n, () => {
        setAssets(0)
        press(item)
      })
    } else {
      press(item)
    }
  }

  const press = (item: FolderWithCount) => {
    if (!isSmallDevice && files === undefined) return

    setSelectedId(item.id)
    const data: InfoboxMediaParams = {
      id,
      type,
      folderNumber: item.number,
      folderRight: item.right,
      description: item.description,
      masterDetailFiles: files?.filter(elem => elem.folderNumber === item.number),
    }

    navigation.navigate('InfoboxMedia', data)
  }

  const renderItem = ({ item }: SectionListRenderItemInfo<FolderWithCount, ListSection<FolderWithCount>>) => (
    <InfoboxFolder
      folder={item}
      selected={!isSmallDevice && selectedId === item.id}
      onPress={() => handlePress(item)}
      spacing={['bottom', 'horizontal']}
      description={appUtils.formatFileNumber(i18n, item.files)}
    />
  )

  const data: ListSection<FolderWithCount>[] = useMemo(() => {
    const displayData: ListSection<FolderWithCount>[] = []

    if (folders && ((isSmallDevice && loadingFolders === false) || (!isSmallDevice && loadingFiles === false))) {
      const filteredData = appUtils.filter(folders, search, ['description'])

      if (filteredData?.length) {
        displayData.push({
          data: filteredData,
        })
      }
    }

    return displayData
  }, [folders, loadingFolders, search, loadingFiles])

  return (
    <SectionList
      loading={isSmallDevice ? loadingFolders : loadingFiles === false && loadingFolders === false ? false : 'reloading'}
      noDataIcon={['fal', 'file-slash']}
      noDataMessage={i18n.t('NO_FOLDER_FOUND')}
      sections={data}
      onRefresh={handleRefresh}
      renderItem={renderItem}
      skeletonElements={CONSTANTS.accessDataSkeletonCards}
      contentContainerStyle={stylesList.list}
    />
  )
}

const stylesList = StyleSheet.create({
  list: { paddingVertical: IMLayout.horizontalMargin * 2 },
})
