import { useLanguage } from '@infominds/react-native-components'
import React, { createRef, ForwardedRef, forwardRef, useImperativeHandle } from 'react'
import { Keyboard } from 'react-native'

import { Screen } from '../../components/screen/Screen'
import { FormProvider } from '../../contexts/FormContext'
import useEditOrCreateScreenBackManager from '../../hooks/useEditOrCreateScreenBackManager'
import { ActivityStackScreenProps } from '../../navigation/navigators/common/ActivityCommonNavigator'
import { ActivityAllocationParams, EditOrCreateViewRef, ModalScreenProps, ModalScreenRef } from '../../types'
import ActivityAllocationView from '../../views/activity/ActivityAllocationView'

const ActivityAllocationScreen = (
  { route, controller }: Pick<ActivityStackScreenProps<'ActivityAllocation'>, 'route'> & ModalScreenProps<ActivityAllocationParams>,
  ref: ForwardedRef<ModalScreenRef>
) => {
  useImperativeHandle(ref, () => ({
    handleGoBack: handleGoBack,
  }))

  const editOrCreateViewRef = createRef<EditOrCreateViewRef>()

  const { i18n } = useLanguage()
  const { status, setStatus, handleGoBack } = useEditOrCreateScreenBackManager({
    title: i18n.t('UNSAVED_CHANGES_TITLE'),
    description: i18n.t('DISCARD_UNSAVED_CHANGES'),
    controller,
  })

  const common = {
    ref: editOrCreateViewRef,
    onUploadStatus: setStatus,
  }

  const isModal = controller !== undefined

  return (
    <Screen transparent={isModal}>
      <Screen.Header
        goBack={handleGoBack}
        backHandlerCallback={handleGoBack}
        isModal={isModal}
        onRightIconPress={() => {
          Keyboard.dismiss()
          editOrCreateViewRef.current?.handleUpload()
        }}
        rightIcon={['fal', 'check']}
        disable={status === 'done' || status === 'mandatoryMissing'}
        loading={status === 'uploading'}
        title={i18n.t('ACTIVITY_ALLOCATION')}
      />
      <Screen.Content>
        <FormProvider>
          <ActivityAllocationView
            activityIds={route.params.activityIds}
            activityEmployeeId={route.params.employeeId}
            ticketId={route.params.ticketId}
            ticketCode={route.params.ticketCode}
            origin={route.params.origin}
            {...common}
          />
        </FormProvider>
      </Screen.Content>
    </Screen>
  )
}

export default forwardRef(ActivityAllocationScreen)
