import { useDimensions, useLanguage, useModalController } from '@infominds/react-native-components'
import React, { useMemo } from 'react'
import { Platform, StyleProp, ViewStyle } from 'react-native'

import { FilterType, GroupType, OrderType } from '../../../contexts/FilterContext'
import useFilter from '../../../hooks/useFilter'
import FilterModal from '../../../modals/common/FilterModal'
import { ModalCommonStackParamList } from '../../../navigation/navigators/common/ModalCommonNavigator'
import ticketUtils from '../../../utils/TicketUtils'
import CommonFilterDetailHeader from './CommonFilterDetailHeader'

interface Props {
  style?: StyleProp<ViewStyle>
  enabled: boolean
  onSDNavigation: () => void
}

export default function FilterDetailHeader({ style, enabled, onSDNavigation }: Props) {
  const { filters, groups, orders, changeFilterStatus } = useFilter()
  const { language } = useLanguage()
  const { isSmallDevice } = useDimensions()
  const controller = useModalController<ModalCommonStackParamList['Filter']>()

  const activeFilters = useMemo(() => ticketUtils.activeFilters(filters), [filters, language])
  const activeGroups = useMemo(() => groups.filter(el => el.active), [groups, language])
  const activeOrders = useMemo(() => orders.filter(el => el.active), [orders, language])

  return (
    <>
      <CommonFilterDetailHeader
        activeFilters={activeFilters}
        activeGroups={activeGroups}
        activeOrders={activeOrders}
        enabled={enabled}
        changeFilterStatus={(id, emitId) => {
          changeFilterStatus(id as OrderType | FilterType | GroupType, emitId)
        }}
        onNavigate={() => (isSmallDevice && (Platform.OS === 'android' || Platform.OS === 'ios') ? onSDNavigation() : controller.show(undefined))}
        style={style}
      />
      <FilterModal controller={controller} />
    </>
  )
}
