import { IM } from '@infominds/react-native-components'
import React from 'react'
import { Platform, StatusBar, StyleSheet } from 'react-native'
import { useSafeAreaInsets } from 'react-native-safe-area-context'

import { InfoboxStackScreenProps } from '../../navigation/navigators/common/InfoboxCommonNavigators'
import InfoboxCameraView from '../../views/infobox/InfoboxCameraView'

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: 'black',
  },
})

const InfoboxCameraScreen = ({}: InfoboxStackScreenProps<'InfoboxNoBottomTabCamera'>) => {
  const { top, bottom } = useSafeAreaInsets()

  return (
    <IM.View style={styles.container}>
      <IM.View
        style={[
          {
            marginTop: Platform.OS === 'ios' ? undefined : top,
            marginBottom: bottom,
          },
          styles.container,
        ]}>
        <StatusBar backgroundColor="#000000" barStyle="dark-content" translucent />
        <InfoboxCameraView />
      </IM.View>
    </IM.View>
  )
}

export default InfoboxCameraScreen
