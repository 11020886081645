import { IM, IMLayout, SpacingProps, useDimensions } from '@infominds/react-native-components'
import { useAuthentication } from '@infominds/react-native-license'
import { NavigationProp, useNavigation } from '@react-navigation/native'
import React from 'react'
import { StyleProp, StyleSheet, ViewStyle } from 'react-native'

import { QualityList } from '../../apis/types/apiResponseTypes'
import BlobImage from '../../components/BlobImage'
import Separator from '../../components/Infominds/Separator'
import Text from '../../components/Text'
import useIsOnline from '../../dataProvider/hooks/useIsOnline'
import { QualityStackParamList } from '../../navigation/navigators/bottomTabs/QualityBottomTabNavigator'
import DetailInfo from '../common/DetailInfo'

interface Props {
  quality: QualityList
  spacing?: SpacingProps
  style?: StyleProp<ViewStyle>
}

export default function QualityCard({ quality, spacing, style }: Props) {
  const { isSmallDevice } = useDimensions()
  const { url } = useAuthentication()
  const navigation = useNavigation<NavigationProp<QualityStackParamList>>()
  const isOnline = useIsOnline()

  const handlePress = () => {
    navigation.navigate('QualityModalCommonStack', {
      screen: 'QualityCheck',
      params: {
        serialNumber: quality.serialnumber,
        serialNumberId: quality.id,
        activityId: quality.activityId,
        serialNumberManufacturer: quality.serialnumberManufactor,
      },
    })
  }

  if (!url) return <></>

  return (
    <IM.Card spacing={spacing} noContentSpacing style={style} onPress={handlePress}>
      <IM.View style={styles.container}>
        {isSmallDevice ? (
          <>
            <IM.View style={IMLayout.flex.row}>
              <IM.View style={IMLayout.flex.f1}>
                <Text>{quality.ticketCode}</Text>
              </IM.View>
              {quality.graficId && isOnline && (
                <IM.View style={styles.imageSD}>
                  <BlobImage
                    uri={`${url}/api/common/grafic?graficId=${quality.graficId}`}
                    width={46}
                    height={46}
                    style={{ borderRadius: IMLayout.borderRadius }}
                  />
                </IM.View>
              )}
            </IM.View>
            <Separator spacing="none" style={styles.horizontalSeparator} />
            <DetailInfo object={quality} />
          </>
        ) : (
          <>
            <IM.View style={[IMLayout.flex.row]}>
              <Text style={styles.text}>{quality.ticketCode}</Text>
              <Separator direction="vertical" spacing="none" style={styles.verticalSeparator} />
              <DetailInfo object={quality} style={IMLayout.flex.f1} />
              {quality.graficId && isOnline && (
                <>
                  <Separator direction="vertical" spacing="none" style={styles.verticalSeparator} />
                  <IM.View style={styles.imageLD}>
                    <BlobImage
                      uri={`${url}/api/common/grafic?graficId=${quality.graficId}`}
                      width={70}
                      height={70}
                      style={{ borderRadius: IMLayout.borderRadius }}
                    />
                  </IM.View>
                </>
              )}
            </IM.View>
          </>
        )}
      </IM.View>
    </IM.Card>
  )
}

const styles = StyleSheet.create({
  container: {
    margin: 10,
    justifyContent: 'center',
  },
  verticalSeparator: {
    marginHorizontal: 8,
  },
  horizontalSeparator: { marginVertical: 6 },
  imageSD: { alignItems: 'flex-end' },
  imageLD: { alignItems: 'center', justifyContent: 'center' },
  text: {
    width: 130,
  },
})
