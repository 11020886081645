import { CompositeScreenProps, NavigatorScreenParams } from '@react-navigation/native'
import { createNativeStackNavigator, NativeStackScreenProps } from '@react-navigation/native-stack'
import React from 'react'

import { QualityFilterProvider } from '../../../contexts/QualityFilterProvider'
import QualityScreen from '../../../screens/quality/QualityScreen'
import { screenOptions } from '../../Navigator'
import { AppBottomTabScreenProps, BottomTabParamList } from '../../types'
import { ModalCommonStackNames, ModalCommonStackNavigator, ModalCommonStackParamList } from '../common/ModalCommonNavigator'

export type QualityStackParamList = {
  Quality: undefined
  QualityModalCommonStack: NavigatorScreenParams<ModalCommonStackParamList>
}

export const QualityStackNames: {
  path?: string
  screens: Record<keyof QualityStackParamList, string | typeof ModalCommonStackNames>
} = {
  path: 'quality',
  screens: {
    Quality: 'list',
    QualityModalCommonStack: ModalCommonStackNames,
  },
}

export type QualityStackScreenProps<T extends keyof QualityStackParamList> = CompositeScreenProps<
  NativeStackScreenProps<QualityStackParamList, T>,
  AppBottomTabScreenProps<keyof BottomTabParamList>
>

const QualityStack = createNativeStackNavigator<QualityStackParamList>()
export function QualityStackNavigator() {
  return (
    <QualityFilterProvider storageKeyUniqueId="QualityStack">
      <QualityStack.Navigator screenOptions={screenOptions}>
        <QualityStack.Screen name="Quality" component={QualityScreen} />
        <QualityStack.Screen name="QualityModalCommonStack" component={ModalCommonStackNavigator} />
      </QualityStack.Navigator>
    </QualityFilterProvider>
  )
}
