import { AssetOrigin } from '@infominds/react-native-media'
import { CompositeScreenProps } from '@react-navigation/native'
import { createNativeStackNavigator, NativeStackScreenProps } from '@react-navigation/native-stack'
import React from 'react'

import InfoboxAssetInfoScreen from '../../../screens/infobox/InfoboxAssetInfoScreen'
import InfoboxAssetScreen from '../../../screens/infobox/InfoboxAssetScreen'
import InfoboxCameraScreen from '../../../screens/infobox/InfoboxCameraScreen'
import InfoboxCameraSettingsScreen from '../../../screens/infobox/InfoboxCameraSettingsScreen'
import InfoboxFolderScreen from '../../../screens/infobox/InfoboxFolderScreen'
import InfoboxMediaScreen from '../../../screens/infobox/InfoboxMediaScreen'
import { InfoboxFoldersParams, InfoboxMediaParams, InfoboxType } from '../../../types'
import { screenOptions } from '../../Navigator'
import { ActivityStackScreenProps } from './ActivityCommonNavigator'
import { CustomerStackScreenProps } from './CustomerCommonNavigators'
import { TicketDetailCommonStackScreenProps } from './TicketDetailCommonNavigator'

export type InfoboxCommonStackParamList = {
  InfoboxFolders: InfoboxFoldersParams
  InfoboxMedia: InfoboxMediaParams
  InfoboxNoBottomTabCamera: undefined
  InfoboxNoBottomTabCameraSettings: undefined
  InfoboxNoBottomTabAsset: { id: string; infoboxType: InfoboxType; origin: AssetOrigin | undefined }
  InfoboxNoBottomTabAssetInfo: { id: string; infoboxType: InfoboxType; origin: AssetOrigin | undefined }
}

export const InfoboxCommonStackNames: { path?: string; screens: Record<keyof InfoboxCommonStackParamList, string> } = {
  screens: {
    InfoboxFolders: 'infobox',
    InfoboxMedia: 'assets',
    InfoboxNoBottomTabCamera: 'camera',
    InfoboxNoBottomTabCameraSettings: 'camera-settings',
    InfoboxNoBottomTabAsset: 'asset',
    InfoboxNoBottomTabAssetInfo: 'info',
  },
}

export type InfoboxStackScreenProps<T extends keyof InfoboxCommonStackParamList> = CompositeScreenProps<
  NativeStackScreenProps<InfoboxCommonStackParamList, T>,
  | TicketDetailCommonStackScreenProps<'TicketInfoboxCommonStack'>
  | CustomerStackScreenProps<'CustomerDetailInfoboxCommonStack'>
  | ActivityStackScreenProps<'ActivityInfoboxCommonStack'>
>

const InfoboxCommonStack = createNativeStackNavigator<InfoboxCommonStackParamList>()
export function InfoboxCommonStackNavigator() {
  return (
    <InfoboxCommonStack.Navigator screenOptions={screenOptions}>
      <InfoboxCommonStack.Screen name="InfoboxFolders" component={InfoboxFolderScreen} />
      <InfoboxCommonStack.Screen name="InfoboxMedia" component={InfoboxMediaScreen} />
      <InfoboxCommonStack.Screen
        name="InfoboxNoBottomTabCameraSettings"
        component={InfoboxCameraSettingsScreen}
        options={{
          animation: 'slide_from_bottom',
          presentation: 'formSheet',
          navigationBarColor: '#000000',
        }}
      />
      <InfoboxCommonStack.Screen
        name="InfoboxNoBottomTabCamera"
        component={InfoboxCameraScreen}
        options={{
          presentation: 'fullScreenModal',
          navigationBarColor: '#000000',
        }}
      />
      <InfoboxCommonStack.Screen
        name="InfoboxNoBottomTabAsset"
        component={InfoboxAssetScreen}
        options={{
          animation: 'slide_from_bottom',
          presentation: 'fullScreenModal',
          navigationBarColor: '#000000',
        }}
      />
      <InfoboxCommonStack.Screen
        name="InfoboxNoBottomTabAssetInfo"
        component={InfoboxAssetInfoScreen}
        options={{
          animation: 'slide_from_bottom',
          presentation: 'fullScreenModal',
          navigationBarColor: '#000000',
        }}
      />
    </InfoboxCommonStack.Navigator>
  )
}
