import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { IM, useTheme } from '@infominds/react-native-components'
import React, { memo, useMemo } from 'react'
import { StyleSheet } from 'react-native'

import { Contract } from '../apis/types/apiResponseTypes'
import { ThemeColorExpanded } from '../types'
import ticketUtils from '../utils/TicketUtils'
import LoaderBar from './LoaderBar'

interface Props {
  contract: Contract
  icon?: IconProp
}

const ContractLoader = memo(function ContractLoader({ contract, icon }: Props) {
  const { theme } = useTheme<ThemeColorExpanded>()

  const status = useMemo(() => ticketUtils.getContractStatus(contract, theme), [contract, theme])

  return (
    <IM.View style={styles.container}>
      {icon && <IM.Icon icon={icon} spacing="right" color={theme.text.default} />}
      <LoaderBar color={status.color} width={status.width} />
    </IM.View>
  )
})

export default ContractLoader

const styles = StyleSheet.create({
  container: { flexDirection: 'row', alignItems: 'center' },
})
