import {
  EventItem,
  HighlightDates,
  MomentConfig,
  OnChangeProps,
  PackedEvent,
  ThemeProperties,
  TimelineCalendar,
  TimelineCalendarHandle,
} from '@howljs/calendar-kit'
import { IM, IMLayout, IMStyle, useEvent, useLanguage, useTheme } from '@infominds/react-native-components'
import { useAuthentication } from '@infominds/react-native-license'
import { NavigationProp, useNavigation } from '@react-navigation/native'
import dayjs from 'dayjs'
import moment from 'moment'
import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { StyleSheet, useWindowDimensions } from 'react-native'
import { SharedValue } from 'react-native-reanimated'
import { useRecoilValue } from 'recoil'

import api from '../../../apis/apiCalls'
import CalenderListCard from '../../../cards/ticketList/CalenderListCard'
import useControlledLoader from '../../../components/Infominds/hooks/useControlledLoader'
import Pressable from '../../../components/Infominds/Pressable'
import PressableIcon from '../../../components/Infominds/PressableIcon'
import { EDIT_ACTIVITY_PLANNING_TIME_EVENT_KEY } from '../../../constants/EmitterKeys'
import { REQUEST_ACTIVITY_PLANNING_TIME } from '../../../constants/Keys'
import useUserSettings from '../../../hooks/useUserSettings'
import { TicketStackParamList } from '../../../navigation/navigators/bottomTabs/TicketBottomTabNavigator'
import { ThemeColorExpanded } from '../../../types'
import calendarUtils from '../../../utils/calendarUtils'
import { calendarViewAtom } from '../../../utils/stateManager'
import { utils } from '../../../utils/utils'

const unavailableHours = {
  //Sunday
  '0': [{ start: 0, end: 24 }],
  //Monday
  '1': [
    { start: 0, end: 8 },
    { start: 17, end: 24 },
  ],
  //Tuesday
  '2': [
    { start: 0, end: 8 },
    { start: 17, end: 24 },
  ],
  //Wednesday
  '3': [
    { start: 0, end: 8 },
    { start: 17, end: 24 },
  ],
  //Thursday
  '4': [
    { start: 0, end: 8 },
    { start: 17, end: 24 },
  ],
  //Friday
  '5': [
    { start: 0, end: 8 },
    { start: 17, end: 24 },
  ],
  //Saturday
  '6': [{ start: 0, end: 24 }],
  //specific date
  /* '2022-12-01': [
    { start: 0, end: 7.5 },
    { start: 12, end: 13.5 },
    { start: 17, end: 24 },
  ], */
}

MomentConfig.updateLocale('de', {
  weekdaysShort: 'So_Mo_Di_Mi_Do_Fr_Sa'.split('_'),
})
MomentConfig.updateLocale('it', {
  weekdaysShort: 'Dom_Lun_Mar_Mer_Gio_Ven_Sab'.split('_'),
})

const holidays = ['2023-08-15'] // -> Should get holiday based on locale

const CalendarView = () => {
  const { i18n, language } = useLanguage()
  const { colorScheme, theme } = useTheme<ThemeColorExpanded>()
  const { width } = useWindowDimensions()
  const { sessionKey } = useAuthentication()
  const { userSettings } = useUserSettings()

  const calendarRef = useRef<TimelineCalendarHandle>(null)
  const navigation = useNavigation<NavigationProp<TicketStackParamList>>()

  const [date, setDate] = useState(new Date())
  const [formattedDate, setFormattedDate] = useState(formatDate(new Date()))
  const [fetchDate, setFetchDate] = useState(dayjs(new Date()))
  const [key, setKey] = useState(utils.generateUuid())
  const viewMode = useRecoilValue(calendarViewAtom(sessionKey))

  const { item: times, loadItem: getTimes, loading } = useControlledLoader(api.getPlanningTime, { id: REQUEST_ACTIVITY_PLANNING_TIME })
  useEvent({ key: EDIT_ACTIVITY_PLANNING_TIME_EVENT_KEY }, () => refresh())

  const background = colorScheme === 'dark' ? theme.background.default : '#FFFFFF'

  useEffect(() => {
    const dateMonth = dayjs(date).month()
    const dateYear = dayjs(date).year()
    const fetchedMonth = fetchDate.month()
    const fetchedYear = fetchDate.year()

    if (dateMonth !== fetchedMonth || dateYear !== fetchedYear) {
      setFetchDate(dayjs(date))
    }
  }, [date, fetchDate])

  useEffect(() => {
    const id = setTimeout(() => {
      setKey(utils.generateUuid())
    }, 500)

    return () => {
      clearTimeout(id)
    }
  }, [width])

  useEffect(() => {
    refresh()
  }, [fetchDate, userSettings])

  const refresh = () => {
    const start = fetchDate.startOf('month').subtract(1, 'month').utc(true).toISOString()
    const end = fetchDate.endOf('month').add(1, 'month').utc(true).toISOString()

    getTimes({
      dateFrom: start,
      dateUntil: end,
      employeeId: userSettings?.employeeId,
    })
  }

  function formatDate(dateToFormat: Date) {
    return `${moment(dateToFormat).locale(language).format('MMMM')} ${dateToFormat.getFullYear()}`
  }

  function onCalendarScroll(onChangeProps: OnChangeProps) {
    setDate(new Date(onChangeProps.date))
    setFormattedDate(formatDate(new Date(onChangeProps.date)))
  }

  function jumpToToday() {
    const optionalProps = {
      hourScroll: true,
      animatedHour: true,
      animatedDate: true,
    }

    calendarRef.current?.goToDate(optionalProps)
  }

  const events: EventItem[] = useMemo(() => {
    if (loading !== false) return []

    const tmp: EventItem[] = []

    times?.forEach(time => {
      const startDateTime = calendarUtils.convertToDateTimeString(time.date, time.from, 3 * 60 * 60)
      const endDateTime = calendarUtils.convertToDateTimeString(time.date, time.until, 5 * 60 * 60)

      tmp.push({
        id: time.id,
        title: time.ticketCode,
        start: startDateTime.toISOString(),
        end: endDateTime.toISOString(),
        color: background,
        containerStyle: { opacity: 0.8, borderColor: time.priorityColorHex, borderWidth: 1 },
      })
    })

    return tmp
  }, [times, loading])

  function handleTicketPressed(id: string, code: string) {
    navigation.navigate('TicketDetailCommonStack', { screen: 'TicketDetail', params: { ticketId: id, ticketCode: code } })
  }

  const renderEventContent = useCallback(
    (event: PackedEvent, _timeIntervalHeight: SharedValue<number>) => {
      const time = times?.find(timeFound => timeFound.id === event.id)
      if (!event || !time) return <></>
      return (
        <CalenderListCard
          codes={[time.ticketCode, time.activityCode]}
          description={time.ticketDescription}
          employeeId={time.employeeId}
          employee={time.employee}
          onPress={() => handleTicketPressed(time.ticketId, time.ticketCode)}
        />
      )
    },
    [times]
  )

  const highlightDates: HighlightDates = useMemo(
    () =>
      holidays.reduce((accumulator, value) => {
        return {
          ...accumulator,
          [value]: {
            dayNameColor: IMStyle.palette.red,
            dayNumberColor: IMStyle.palette.white,
            dayNumberBackgroundColor: IMStyle.palette.red,
          },
        }
      }, {}),
    [holidays]
  )

  const calendarTheme: ThemeProperties = {
    nowIndicatorColor: theme.general.info,
    loadingBarColor: theme.general.info,
    unavailableBackgroundColor: colorScheme === 'dark' ? '#282828' : '#F1F1F1',
    backgroundColor: background,
    cellBorderColor: colorScheme === 'dark' ? '#464646' : '#E2E2E2',

    todayName: { color: theme.general.info },
    todayNumber: { color: IMStyle.palette.white },
    todayNumberContainer: { backgroundColor: theme.general.info },

    dayName: { color: theme.text.default },
    dayNumber: { color: theme.text.default },
    dayNumberContainer: { backgroundColor: theme.background.default },

    saturdayName: { color: theme.text.detail },
    saturdayNumber: { color: theme.text.detail },
    saturdayNumberContainer: { backgroundColor: theme.background.default },

    sundayName: { color: theme.text.detail },
    sundayNumber: { color: theme.text.detail },
    sundayNumberContainer: { backgroundColor: theme.background.default },

    hourText: { color: theme.text.default },
  }

  return (
    <IM.View style={[IMLayout.flex.f1, { backgroundColor: theme.header.detail.background }]}>
      <IM.View
        style={[
          styles.header,
          {
            backgroundColor: theme.card.background,
          },
        ]}>
        <PressableIcon
          icon={['fal', 'chevron-left']}
          size={20}
          onPress={() => calendarRef.current?.goToPrevPage()}
          style={[IMLayout.flex.f1, styles.leftIcon]}
        />
        <IM.Text style={{ fontSize: IMStyle.typography.fontSizeMedium }}>{formattedDate}</IM.Text>
        <PressableIcon
          icon={['fal', 'chevron-right']}
          size={20}
          onPress={() => calendarRef.current?.goToNextPage()}
          style={[IMLayout.flex.f1, styles.rightIcon]}
        />
      </IM.View>
      <TimelineCalendar
        key={key}
        ref={calendarRef}
        viewMode={viewMode}
        events={events}
        scrollToNow
        showNowIndicator
        allowPinchToZoom
        initialTimeIntervalHeight={60}
        minTimeIntervalHeight={30}
        maxTimeIntervalHeight={110}
        isLoading={false}
        theme={calendarTheme}
        unavailableHours={unavailableHours}
        holidays={holidays}
        locale={language}
        highlightDates={highlightDates}
        renderEventContent={renderEventContent}
        onChange={onCalendarScroll}
      />
      <Pressable
        style={[IMLayout.shadow, styles.todayButton, { backgroundColor: theme.background.default }]}
        containerStyle={styles.todayButtonContainer}
        onPress={() => jumpToToday()}>
        <IM.Text>{i18n.t('CALENDAR_TODAY')}</IM.Text>
      </Pressable>
    </IM.View>
  )
}

export default memo(CalendarView)

const styles = StyleSheet.create({
  header: { alignItems: 'center', justifyContent: 'space-around', paddingTop: 6, paddingHorizontal: 6, flexDirection: 'row', zIndex: 100 },
  todayButton: { padding: 15, paddingHorizontal: 20, borderRadius: 50 },
  todayButtonContainer: { position: 'absolute', right: 20, bottom: 20 },
  leftIcon: { alignItems: 'flex-start' },
  rightIcon: { alignItems: 'flex-end' },
})
