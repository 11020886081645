import { useDimensions } from '@infominds/react-native-components'
import React from 'react'

import useKeyPress from '../../hooks/useKeyPress'
import HeaderIcon from './HeaderIcon'
import useScreen from './hooks/useScreen'
import useSearch from './hooks/useSearch'
import { DetailHeaderType } from './types'

export type SearchIconProps = {
  disabled?: boolean
  onPress?: VoidFunction
}

export default function HeaderSearchIcon({ disabled, onPress }: SearchIconProps) {
  const { search, tabletOpen } = useSearch()
  const { isSmallDevice } = useDimensions()
  const { detail, setDetailOpen } = useScreen()

  useKeyPress(
    {
      key: ['F2'],
      cb: () => {
        isSmallDevice && setDetailOpen(DetailHeaderType.SEARCH)
      },
    },
    [isSmallDevice, setDetailOpen]
  )

  return (
    <HeaderIcon
      icon={['fal', 'magnifying-glass']}
      type={DetailHeaderType.SEARCH}
      highlight={detail.type === DetailHeaderType.SEARCH && (detail.open || tabletOpen) ? false : search !== ''}
      size={17}
      disabled={disabled}
      onPress={() => (onPress ? onPress() : setDetailOpen(DetailHeaderType.SEARCH))}
    />
  )
}
