import { IM, IMLayout, useDimensions, useTheme } from '@infominds/react-native-components'
import React, { Children, isValidElement, memo, PropsWithChildren } from 'react'
import { StyleSheet } from 'react-native'

import { ThemeColorExpanded } from '../../../types'
import { SCREEN_CONSTANTS, ScreenHeaderShadows } from '../constants/constants'
import useScreen from '../hooks/useScreen'

interface Props {
  minHeight?: number
}

const DetailHeaderContainer = memo(function DetailHeaderContainer({
  children,
  minHeight = SCREEN_CONSTANTS.headerDetailMinBarHeight,
}: PropsWithChildren<Props>) {
  const { theme } = useTheme<ThemeColorExpanded>()
  const { isSmallDevice } = useDimensions()
  const { detail, height, setDetailHeight } = useScreen()

  return (
    <>
      {isSmallDevice && (
        <IM.View style={[styles.container, { top: height.header, backgroundColor: theme.header.detail.background }, ScreenHeaderShadows]}>
          <IM.View style={[styles.view, { minHeight: minHeight }]} onLayout={ev => setDetailHeight(ev.nativeEvent.layout.height)}>
            {Children.map(children, child => {
              if (isValidElement(child) && typeof child.type !== 'string') {
                //@ts-ignore to understand how to tell ts the presence of type.type
                if (child.type.type === detail.type) return child
              }

              return null
            })}
          </IM.View>
        </IM.View>
      )}
    </>
  )
})

export default DetailHeaderContainer

const styles = StyleSheet.create({
  container: {
    position: 'absolute',
    width: '100%',
    bottom: 0,
    borderTopLeftRadius: SCREEN_CONSTANTS.headerRadius,
    borderTopRightRadius: SCREEN_CONSTANTS.headerRadius,
  },
  view: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingHorizontal: IMLayout.horizontalMargin,
  },
})
