import { useLanguage } from '@infominds/react-native-components'
import React from 'react'
import { Animated } from 'react-native'

import HeaderWithSync from '../../components/screen/headers/HeaderWithSync'
import { Screen } from '../../components/screen/Screen'
import { TicketDetailCommonStackScreenProps } from '../../navigation/navigators/common/TicketDetailCommonNavigator'
import TicketDetailView from '../../views/ticket/TicketDetailView'

const buttonAnimationValue = new Animated.Value(0)

export default function TicketDetailScreen({
  route,
}: TicketDetailCommonStackScreenProps<'TicketDetail'> | TicketDetailCommonStackScreenProps<'TicketDetailPastTicket'>) {
  const { i18n } = useLanguage()

  const isPast = route.name === 'TicketDetailPastTicket'

  return (
    <Screen>
      <Screen.Header goBack>
        <HeaderWithSync title={isPast ? i18n.t('PAST_TICKET') : route.params.ticketCode} description={isPast ? route.params.ticketCode : undefined} />
      </Screen.Header>

      <Screen.Content>
        <TicketDetailView
          animatedValue={buttonAnimationValue}
          ticketId={route.params.ticketId}
          ticketCode={route.params.ticketCode}
          isPast={isPast}
        />
      </Screen.Content>
    </Screen>
  )
}
