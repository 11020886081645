import { ModalController } from '@infominds/react-native-components'
import React, { createRef } from 'react'

import { TicketDetailCommonStackParamList } from '../../navigation/navigators/common/TicketDetailCommonNavigator'
import TicketSerialNumberCreationScreen from '../../screens/tickets/TicketSerialNumberCreationScreen'
import { ModalScreenRef } from '../../types'
import BaseServiceModal from '../BaseServiceModal'

interface Props {
  controller: ModalController<TicketDetailCommonStackParamList['TicketSerialNumberCreation']>
}

export default function TicketSerialNumberCreationModal({ controller }: Props) {
  const ref = createRef<ModalScreenRef>()

  return (
    <BaseServiceModal controller={controller} childRef={ref} backdropOpacity={0}>
      {controller.data && (
        <TicketSerialNumberCreationScreen
          ref={ref}
          route={{
            key: '',
            name: 'TicketSerialNumberCreation',
            params: {
              customerId: controller.data.customerId,
              shippingAddressId: controller.data.shippingAddressId,
              ticketId: controller.data.ticketId,
            },
          }}
          controller={controller}
        />
      )}
    </BaseServiceModal>
  )
}
