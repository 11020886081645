import { ModalController } from '@infominds/react-native-components'
import { NavigationProp, NavigationState, useNavigation } from '@react-navigation/native'
import React, { createRef, useState } from 'react'

import { getApi } from '../apis/apiCalls'
import { PasswordInputRef } from '../components/input/PasswordInput'
import { useDataProvider } from '../dataProvider/hooks/useDataProvider'
import useVault from '../hooks/useVault'
import { utils } from '../utils/utils'
import VaultModalBase from './VaultModalBase'

export type VaultModalBaseProps = {
  loading: boolean
  error: boolean
  controller: ModalController
  password: string
  pswInputRef: React.RefObject<PasswordInputRef>
  setPassword: (password: string) => void
  onClose: () => void
  checkPassword: () => void
}

interface Props {
  controller: ModalController
  onAccept?: (id: string) => void
}

export default function VaultModal({ controller, onAccept }: Props) {
  const { setSessionId } = useVault()
  const { client } = useDataProvider()
  const navigation = useNavigation()

  const [password, setPassword] = useState('')
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)

  const pswInputRef = createRef<PasswordInputRef>()

  const checkPassword = () => {
    pswInputRef.current?.hide()
    setError(false)
    setLoading(true)

    const id = utils.generateUuid()

    getApi(client)
      .getVaultAccess({ SessionId: id, Masterpassword: password })
      .then(response => {
        if (response) {
          setSessionId(id)
          onAccept?.(id)
          controller.close()
          setError(false)
          setPassword('')
        }
      })
      .catch(err => {
        console.error('Failed validating vault password:', err)
        setError(true)
      })
      .finally(() => setLoading(false))
  }

  const onClose = () => {
    onCancelPressNavigation(navigation)
    controller.close()
  }

  return (
    <VaultModalBase
      error={error}
      loading={loading}
      controller={controller}
      onClose={onClose}
      pswInputRef={pswInputRef}
      password={password}
      setPassword={setPassword}
      checkPassword={checkPassword}
    />
  )
}

const onCancelPressNavigation = (
  navigation: Omit<NavigationProp<ReactNavigation.RootParamList>, 'getState'> & {
    getState(): NavigationState | undefined
  }
) => {
  const parentState = navigation.getParent()?.getState()

  if (parentState?.routes.find(r => r.state?.stale === true) === undefined) {
    // @ts-ignore: ok
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    navigation.getParent().popToTop()
  } else {
    navigation.getParent()?.reset({
      index: 0,
      routes: [
        {
          name: 'PasswordsStack',
          params: {
            screen: 'PasswordCustomersList',
          },
        },
      ],
    })
  }
}
