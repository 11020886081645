import { useLanguage } from '@infominds/react-native-components'
import React, { createRef, useEffect, useState } from 'react'
import { useRecoilValue } from 'recoil'

import { SearchProvider } from '../../components/screen/contexts/SearchContext'
import ShoppingCartHeader from '../../components/screen/headers/ShoppingCartHeader'
import { Screen } from '../../components/screen/Screen'
import { useKeyboardVisible } from '../../hooks/useKeyboardVisible'
import usePopulateShoppingCart from '../../hooks/usePopulateShoppingCart'
import { ActivityStackScreenProps } from '../../navigation/navigators/common/ActivityCommonNavigator'
import { SparePartShoppingCartRef } from '../../types'
import { shoppingCartAtom, shoppingCartRemovalAtom } from '../../utils/stateManager'
import ticketUtils from '../../utils/TicketUtils'
import ActivitySparePartsShoppingCartView from '../../views/activity/ActivitySparePartsShoppingCartView'

export default function ActivitySparePartsShoppingCartScreen({ route, navigation }: ActivityStackScreenProps<'ActivitySparePartsShoppingCart'>) {
  const { i18n } = useLanguage()
  const parts = useRecoilValue(shoppingCartAtom)
  const removalParts = useRecoilValue(shoppingCartRemovalAtom)
  const [loading, setLoading] = useState(false)
  const viewRef = createRef<SparePartShoppingCartRef>()
  const keyboardVisible = useKeyboardVisible()

  usePopulateShoppingCart({ activityId: route.params.activityId, ticketId: route.params.ticketId })

  useEffect(() => {
    loading && viewRef.current?.upload()
  }, [loading])

  const resultParts = route.params.direction === 'Installation' ? parts : removalParts

  if (!resultParts) return <></>

  const editedShoppingList = ticketUtils.isShoppingCartEdited(resultParts)

  return (
    <SearchProvider>
      <Screen>
        <Screen.Header goBack={() => navigation.goBack()} backHandlerCallback={() => navigation.goBack()} goBackLoading={loading}>
          <ShoppingCartHeader
            title={i18n.t('SHOPPING_CART')}
            description={route.params.activityCode}
            disabled={!editedShoppingList || loading || keyboardVisible}
            buttonDescription={editedShoppingList && keyboardVisible ? i18n.t('CLOSE_KEYBOARD') : undefined}
            onPress={() => setLoading(true)}
          />
        </Screen.Header>
        <Screen.Content>
          <ActivitySparePartsShoppingCartView
            ref={viewRef}
            parts={resultParts}
            activityId={route.params.activityId}
            direction={route.params.direction}
            onUploadDone={() => {
              navigation.pop(2)
              setTimeout(() => setLoading(false), 250)
            }}
          />
        </Screen.Content>
      </Screen>
    </SearchProvider>
  )
}
