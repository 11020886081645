import { useDimensions, useLanguage, useModalController } from '@infominds/react-native-components'
import React, { useMemo } from 'react'
import { Platform, StyleProp, ViewStyle } from 'react-native'

import { SparePartsFilterType, SparePartsOrderType } from '../../../contexts/SparePartsFilterContext'
import useSparePartsFilter from '../../../hooks/useSparePartsFilter'
import SparePartsFilterModal from '../../../modals/common/SparePartsFilterModal'
import { ModalCommonStackParamList } from '../../../navigation/navigators/common/ModalCommonNavigator'
import ticketUtils from '../../../utils/TicketUtils'
import CommonFilterDetailHeader from './CommonFilterDetailHeader'

interface Props {
  enabled: boolean
  style?: StyleProp<ViewStyle>
  onSDNavigation: () => void
}

export default function SparePartsFilterDetailHeader({ enabled, style, onSDNavigation }: Props) {
  const { language } = useLanguage()
  const { isSmallDevice } = useDimensions()
  const { filters, orders, changeFilterStatus } = useSparePartsFilter()

  const controller = useModalController<ModalCommonStackParamList['SparePartsFilter']>()

  const activeFilters = useMemo(() => ticketUtils.activeFilters(filters), [filters, language])
  const activeOrders = useMemo(() => orders.filter(el => el.active), [orders, language])

  return (
    <>
      <CommonFilterDetailHeader
        activeFilters={activeFilters}
        activeOrders={activeOrders}
        enabled={enabled}
        changeFilterStatus={(id, emitId) => {
          changeFilterStatus(id as SparePartsOrderType | SparePartsFilterType, emitId)
        }}
        onNavigate={() => (isSmallDevice && (Platform.OS === 'android' || Platform.OS === 'ios') ? onSDNavigation() : controller.show(undefined))}
        style={style}
      />
      <SparePartsFilterModal controller={controller} />
    </>
  )
}
